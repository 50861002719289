import React, { createContext, useContext, useEffect, ReactNode } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

interface CookieConsentContextType {
  showPreferences: () => void;
}

const CookieConsentContext = createContext<CookieConsentContextType | undefined>(undefined);

const useCookieConsent = (): CookieConsentContextType => {
	const context = useContext(CookieConsentContext);
	if (!context) {
		throw new Error("useCookieConsent must be used within a CookieConsentProvider");
	}
	return context;
};

interface CookieConsentProviderProps {
  children: ReactNode;
}

const CookieConsentProvider: React.FC<CookieConsentProviderProps> = ({ children }) => {
	useEffect(() => {
			
		// @ts-ignore
		CookieConsent.run({
			autoShow: true,
			disablePageInteraction: true,
			guiOptions: {
				// @ts-ignore
				disablePageInteraction: true,
				consentModal: {
					layout: "bar inline",
					position: "bottom",
					equalWeightButtons: false,
					flipButtons: false
				},
				preferencesModal: {
					layout: "box",
					position: "right",
					equalWeightButtons: false,
					flipButtons: false
				}
			},
			categories: {
				necessary: {
					readOnly: true,
					enabled: true
				},
				functionality: {
					enabled: true,
				},
				analytics: {
					enabled: true,
					readOnly: false,
					autoClear: {
						cookies: [{ name: /^(_ga|_gid)/ }],
					},
					services: {
						ga4: {
							label: "Google Analytics 4",
							onAccept: () => {
								//console.log("enabled ga4");
							},
							onReject: () => {
								//console.log("disabled ga4");
							},
						},
					},
				},
				marketing: {}
			},
			language: {
				default: "en",
				translations: {
					en: {
						"consentModal": {
							"title": "Your Privacy Matters to Us",
							"description": "We use cookies to enhance your browsing experience, serve personalised content, and analyse our traffic. By clicking 'Accept All Cookies', you consent to our use of cookies. You can manage your cookie preferences by clicking 'Manage Preferences'. For more information, please read our Cookie Policy.",
							"acceptAllBtn": "Accept All",
							"acceptNecessaryBtn": "Reject All",
							"showPreferencesBtn": "Manage Preferences",
							"footer": "<a href='/privacy' target='_blank'>Privacy Policy</a>\n<a href=\"#link\">Terms and Conditions</a>"
						},
						"preferencesModal": {
							"title": "Consent Preferences Centre",
							"acceptAllBtn": "Accept All",
							"acceptNecessaryBtn": "Reject All",
							"savePreferencesBtn": "Save Preferences",
							"closeIconLabel": "Close Modal",
							"serviceCounterLabel": "Service|Services",
							"sections": [
								{
									"title": "Cookie Usage",
									"description": "Here, you can customise your cookie settings to better suit your privacy preferences. You can enable or disable different types of cookies. Remember, essential cookies are necessary for the website to function properly and cannot be turned off. Your preferences will be saved and can be adjusted at any time."
								},
								{
									"title": "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
									"description": "Strictly Necessary Cookies are essential for the website to function correctly. These cookies enable core functionalities such as security, network management, and accessibility. Without these cookies, certain services you have requested, such as logging into your account or completing transactions, cannot be provided. These cookies do not store any personally identifiable information and cannot be disabled in our systems.",
									"linkedCategory": "necessary"
								},
								{
									"title": "Functionality Cookies",
									"description": "Functionality Cookies enable the website to provide enhanced features and personalisation. These cookies allow us to remember your preferences, such as your username, language, or the region you are in, and provide enhanced, more personal features. They may also be used to provide services you have requested, such as watching a video or commenting on a blog. These cookies do not track your browsing activity on other websites and do not store any personally identifiable information. Disabling these cookies may affect the functionality and personalisation of the website.",
									"linkedCategory": "functionality"
								},
								{
									"title": "Analytics Cookies",
									"description": "Analytics Cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. These cookies allow us to track and analyse data about website traffic and user behaviour, which helps us improve our site and provide a better user experience. We use Google Analytics to gather this information, which includes details such as the pages you visit, the time spent on each page, and any issues you encounter. This data is aggregated and does not personally identify you. You can choose to disable these cookies if you prefer not to share this information with us.",
									"linkedCategory": "analytics"
								},
								{
									"title": "More Information",
									"description": "For any queries regarding our cookie policy and your choices, please <a class=\"cc__link\" href='/?section=audience&nature=general'>contact us</a>."
								}
							]
						}
					}
				},
				autoDetect: "browser"
			},
		});			
	}, []);

	const showPreferences = () => {
		CookieConsent.showPreferences();
	};

	return (
		<CookieConsentContext.Provider value={{ showPreferences }}>
			{children}
		</CookieConsentContext.Provider>
	);
};

export default CookieConsentProvider;
export {
	useCookieConsent
};