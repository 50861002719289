import styled from "styled-components";
import { MainTitleCSS, SubTitleCSS } from "Styles";
import { device } from "Components/Reusable/Grid";


// Styled component for the title grid item
const TitleGridItem = styled.div`
    grid-column: 2 / span 10;
    grid-row: 2 / span 3;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${device.laptopM} {
        grid-column: 1 / span end;
    }
    @media ${device.tabletL} {
        align-items: flex-start;
    }
    @media ${device.tablet} {
        grid-row: 2 / span 6;
    }
    @media ${device.mobileL} {
        grid-row: 2 / span 6;
        grid-column: 1 / span 6;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 20px 0 0;
    }
`;

// Styled component for the title
const StyledTitle = styled.div`
    ${MainTitleCSS}
    text-align: center;
    box-sizing: border-box;
    .gradient {
        background: ${props => `-webkit-linear-gradient(0deg, ${props.theme.accentColor} 21.36%, ${props.theme.accentColor} 92.51%)`};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media ${device.mobileL} {
        text-align: left;
    }
`;


// Styled component for the subtitle grid item
const SubTitleGridItem = styled.div`
    ${SubTitleCSS}
    text-align: center;
    grid-column: 5 / span 4;
    grid-row: 4 / span 2;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(10px);
    @media ${device.laptopL} {
		grid-column: 4 / span 6;
	}
    @media ${device.tabletL} {
        grid-row: 4 / span 1;
        grid-column: 3 / span 8;
    }
    @media ${device.tabletM} {
        grid-column: 3 / span 8;
    }
    @media ${device.tablet} {
        grid-column: 3 / span 8;
        grid-row: 7 / span 3;
    }
    @media ${device.tabletS} {
        grid-column: 1 / span 6;
        grid-row: 8 / span 3;
    }
    @media ${device.mobileL} {
        text-align: left;
        align-items: center;
        grid-column: 1 / span 6;
        grid-row: 8 / span 3;
    }
    @media screen and (max-height: 800px) and (min-width: 800px) {
        //grid-column: 4 / span 6;
    }
`;

// Styled component for the register form grid item
const RegisterGridItem = styled.div<{ $mobile: boolean }>`
    grid-column: 5 / span 4;
    grid-row: 5 / span 1;
    z-index: 200;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media ${device.laptopM} {
        grid-column: 4 / span 6;
    }
    @media ${device.tablet} {
        grid-column: 2 / span 10;
        grid-row: 10 / span 2;
    }
    @media ${device.tabletS} {
        grid-column: 1 / span 6;
        grid-row: 11 / span 2;
    }
    @media ${device.mobileL} {
        grid-column: 1 / span 6;
        grid-row: 11 / span 2;
    }
`;

export {
	TitleGridItem,
	StyledTitle,
	SubTitleGridItem,
	RegisterGridItem
};