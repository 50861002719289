import * as THREE from "three";
import { cellToLatLng, gridDisk } from "h3-js";
import { MethodType } from "./ParticlesTexture";
import { Stage, Particles } from "./FBOParticles/types";

function truncateFloat(num: number, decimalPlaces: number) {
	const factor = Math.pow(10, decimalPlaces);
	return Math.trunc(num * factor) / factor;
}

function latLngToXYZ (lat: number, lng: number, r: number): [number, number, number] {
	/**
	 * Converts a lat lng coordinate to a 3D cartesian coordinate
	 * 
	 * @param lat the latitude of the coordinate
	 * @param lng the longitude of the coordinate
	 * @param r the radius of the sphere
	 * @returns a 3D cartesian coordinate
	 */
	const p = [r * Math.cos(lat) * Math.sin(lng), r * Math.sin(lat), r * Math.cos(lat) * Math.cos(lng)];
	return p.map(coord => truncateFloat(coord, 3)) as [number, number, number];
}

type Source = string;
type Cell = {
	cell: string;
	index: number;
	stride: number;
	source: Source;
}
type OtherCells = {particles: string[], source: string}[];
type OtherCellsMix = OtherCells | string[];
// ONLY WORKS ON >es2015
function* iterateWorld (worldHexs: string[], num_particles: number, other?: OtherCellsMix): Generator<Cell> {
	/**
	 * Iterates through all the cells in the world
	 * 
	 * @yields a cell
	 */
	const worldParticles = worldHexs.length;
	if (num_particles < (worldParticles + (other?.length || 0))) {
		throw new Error(`Number of particles (${num_particles}) cannot be less 
			than worldHexs (${worldParticles}) + other (${other?.length || 0})
		`);
	}

	// Prep other if it comes as string[]
	const prepOther: OtherCells = other && typeof other[0] === "string" ?
		[{ particles: other, source: "other" }] as OtherCells:
		other as OtherCells;

	const buildResponse = (cell: string, index: number, source: Source): Cell => ({
		cell,
		index,
		stride: (index * 4),
		source
	});
	// flatten the other array
	const flattenedOther = prepOther?.map(({particles, source}) => particles.map(cell => ({cell, source}))).flat() || [];
	if (prepOther) {
		for (let i = 0; i < flattenedOther.length; i++) {
			const p = flattenedOther[i];
			yield buildResponse(p.cell, i, p.source);
		}
	}
	for (let i = 0; i < worldParticles; i++) {
		yield buildResponse(worldHexs[i], i + (flattenedOther?.length || 0), "world");
	}
}

function getRandomDataSphere (worldHexs: string[], num_particles: number, r: number, other?: OtherCellsMix): Float32Array {
	/**
	 * Generates a random sphere of particles with radius r and num_particles
	 * 
	 * The particles are positioned randomly on the sphere by choosing a random 
	 * radius (within the sphere) and a random azimuthal and polar angle (lat and lng)
	 * 
	 * The data is stored in a Float32Array of size num_particles * 4 because that is the
	 * format that the shader expects the data to be in. Each pixel in the texture is a vec4
	 * with the first 3 components being the position of the particle and the last component
	 * being a value that is not used. [p1x, p1y, p1z, 1.0, p2x, p2y, p2z, 1.0, ...]
	 * 
	 * @param num_particles the number of particles to generate
	 * @param r the radius of the sphere
	 * @returns a Float32Array of size num_particles * 4
	 */
	// we need to create a vec4 since we're passing the positions to the fragment shader
	// data textures need to have 4 components, R, G, B, and A
	const data = new Float32Array(num_particles * 4);

	// we don't actually generate num_particles particles, we generate LOCATIONS.WORLD.length
	// which is the total number of particles that represent the world. THen we will pass 
	// the data to the shader in two diff Arrays one for position and another one for color
	for (const { stride } of iterateWorld(worldHexs, num_particles, other)) {
		// compute random positions on the sphere
		// convert the lat lng to a 3D cartesian coordinate
		const [p1, p2, p3] = latLngToXYZ(
			THREE.MathUtils.randFloatSpread(Math.PI), 
			THREE.MathUtils.randFloatSpread(2 * Math.PI), 
			Math.sqrt(Math.random()) * r
		);
		data.set([p1, p2, p3, 1.0], stride);
	}
	return data;
}

function getCollapsed (worldHexs: string[], num_particles: number): Float32Array {
	// we need to create a vec4 since we're passing the positions to the fragment shader
	// data textures need to have 4 components, R, G, B, and A
	const data = new Float32Array(num_particles * 4);
	for (const { stride } of iterateWorld(worldHexs, num_particles)) {
		data.set([0.0, 0.0, 0.0, 1.0], stride);
	}
	return data;
}

function getHexWorld (worldHexs: string[], num_particles: number, radius: number, other?: OtherCellsMix): Float32Array {
	/**
	 * Generates a H3 grid of cell with radius r and num_particles
	 * 
	 * Particles are position according to their H3 cell position. This function 
	 * generates a grid of H3 cells representing the land of earth.
	 * 
	 * The data is stored in a Float32Array of size num_particles * 4 because that is the
	 * format that the shader expects the data to be in. Each pixel in the texture is a vec4
	 * with the first 3 components being the position of the particle and the last component
	 * being a value that is not used. [p1x, p1y, p1z, 1.0, p2x, p2y, p2z, 1.0, ...]
	 * 
	 * @param num_particles the number of particles to generate
	 * @param r the radius of the sphere
	 * @param other an array of H3 cells to overlay on top of the world
	 * @returns a Float32Array of size num_particles * 4 
	 */
	// we need to create a vec4 since we're passing the positions to the fragment shader
	// data textures need to have 4 components, R, G, B, and A
	const data = new Float32Array(num_particles * 4);
	// prep the other array
	for (const { cell, stride } of iterateWorld(worldHexs, num_particles, other)) {
		// Get lat and lng for H3 cell and convert to radians
		const [lat, lng] = cellToLatLng(cell).map(coord => (coord * Math.PI) / 180);
		// Compute the 3D cartesian coordinate of the cell
		const [p1, p2, p3] = latLngToXYZ(lat, lng, radius);
		data.set([p1, p2, p3, 1.0], stride);
	}

	return data;
}

function getWorldToFall ({
	worldHexs,
	num_particles, 
	radius, 
	fallen = [],
	toFall = [],
	distanceFactor = 1.5,
} : {
	worldHexs: string[],
	num_particles: number, 
	radius: number, 
	fallen?: string[],
	toFall?: string[],
	distanceFactor?: number,
}): Float32Array {
	/**
	 * 
	 * Generates the whole world with the cells in the same manner as the getWorld function
	 * but the cells in the toFall array will be positioned at the top of the sphere so they can latr
	 * fall down to their position
	 */
	// we need to create a vec4 since we're passing the positions to the fragment shader
	// data textures need to have 4 components, R, G, B, and A
	const data = new Float32Array(num_particles * 4);
	const prepOther = [
		{ particles: fallen, source: "fallen" },
		{ particles: toFall, source: "toFall" },
	];
	for (const { cell, stride, source } of iterateWorld(worldHexs, num_particles, prepOther)) {
		// Get lat and lng for H3 cell and convert to radians
		const [lat, lng] = cellToLatLng(cell).map(coord => (coord * Math.PI) / 180);
		// Compute the 3D cartesian coordinate of the cell
		const r = source === "toFall" ? (Math.random() + 1) * distanceFactor * radius : radius;
		const [p1, p2, p3] = latLngToXYZ(lat, lng, r);
		data.set([p1, p2, p3, 1.0], stride);
	}

	return data;
}

function getColor (
	worldHexs: string[],
	num_particles: number,
	toHighlight: string[],
	highlightColor: number | string,
	baseColor: number | string = 0x000000,
	other?: OtherCellsMix,
	source?: Source 
) : Float32Array {
	/**
	 * Generates a color array for the particles in the globe, all particles in the 
	 * array toHighlight will be painted with the highlightColor and the rest will be
	 * painted with the baseColor. 
	 * 
	 * @param num_particles the number of particles to generate
	 * @param toHighlight an array of H3 cells to highlight
	 * @param highlightColor the color to paint the highlighted cells
	 * @param baseColor the color to paint the rest of the cells
	 * @param other an array of H3 cells to highlight with a different color
	 * @param source the source of the cells in the other array
	 * 		- "world": the cells to highlight will only be from the world batch
	 * 		- "other": the cells to highlight will only be from the other batch
	 * 		- undefined: the cells to highlight can be from both batches
	 * @returns a Float32Array of size num_particles * 4
	 * 
	 */
	// we need to create a vec4 since we're passing the positions to the fragment shader
	// data textures need to have 4 components, R, G, B, and A
	const colors = new Float32Array(num_particles * 4);
	const {r: rh, g: gh, b: bh} = new THREE.Color(highlightColor);
	const {r: rb, g: gb, b: bb} = new THREE.Color(baseColor);
	for (const { cell, stride, source: cellSource } of iterateWorld(worldHexs, num_particles, other)) {
		const high = (
			source === cellSource && toHighlight.includes(cell) ||
			source === undefined && toHighlight.includes(cell)
		);
		colors.set([high ? rh : rb, high ? gh : gb, high ? bh : bb, high ? 1.0 : 0.0], stride);
	}
	return colors;
}

type MultiColor = {
	color: number | string;
	cells: string[];
	highlight?: number,
	source?: Source,
};

function getMultiColor ({
	worldHexs,
	num_particles,
	multiColor,
	baseColor = 0x000000,
	other,
	paintOnlyOther = false,
} : {
	worldHexs: string[],
	num_particles: number,
	multiColor: MultiColor[],
	baseColor: number | string,
	other?: OtherCellsMix,
	paintOnlyOther?: boolean,
}) : Float32Array {
	/**
	 * Advanced coloring function which allows to paint different cells with different colors
	 * Provide the multiColor attribute with an array of objects with the following structure:
	 *  - color: the color to paint the cells
	 *  - cells: an array of H3 cells to paint with the color
	 * 
	 * If the same cell is in more than one object, the first colors will be used 
	 * 
	 * @param num_particles the number of particles to generate
	 * @param multiColor an array of objects with the color and cells attributes
	 * 		- color: the color to paint the cells
	 * 		- cells: an array of H3 cells to paint with the color
	 * @param baseColor the color to paint the rest of the cells
	 * @returns a Float32Array of size num_particles * 4
	 * 
	 */
	// we need to create a vec4 since we're passing the positions to the fragment shader
	// data textures need to have 4 components, R, G, B, and A
	const colors = new Float32Array(num_particles * 4);
	
	// we go though all the centers and paint them with the color 
	// any remaining cells get painted with the base color
	for (const { cell, stride, source } of iterateWorld(worldHexs, num_particles, other)) {
		const color = multiColor.find(mc => {
			const toColor = mc.source === source || mc.source === undefined || !paintOnlyOther;
			return mc.cells.includes(cell) && toColor;
		}) || {color: baseColor, highlight: 0.0};
		const { r, g, b } = new THREE.Color(color.color);
		colors.set([r, g, b, color.highlight], stride); // Set RGBA values at once
	}
	return colors;
}

function getKDisks (center: string, kRing: number): {[key: number]: string[]} {
	/**
	 * Returns a kRing disk around the center
	 * 
	 * @param center the H3 cell in the center of the disk
	 * @param kRing the number of rings around the center to include in the disk
	 * @param radius the radius of the sphere
	 * @returns an array of H3 cells
	 * 
	 */
	// iterate from kRing to 0
	const cells: {[key: number]: string[]} = {};
	const prev: string[] = [];
	for (let i = 0; i <= kRing; i++) {
		const disk = gridDisk(center, i);
		cells[i] = difference(disk, prev);
		prev.push(...disk);
	}
	return cells;
}

function getWorldWithRaisedAreas ({
	worldHexs,
	num_particles,
	radius,
	center,
	kRing=3,
	distanceFactor = 0.25,
} : {
	worldHexs: string[],
	num_particles: number,
	radius: number,
	center: string,
	kRing?: number,
	distanceFactor?: number,
}) : Float32Array {
	/**
	 * Generates a world with the cells in the center and the kRings around it raised
	 * 
	 * @param num_particles the number of particles to generate
	 * @param radius the radius of the sphere
	 * @param center the H3 cell in the center of the raised area
	 * @param kRings the number of rings around the center to raise
	 * @returns a Float32Array of size num_particles * 4
	 * 
	 */
	// we need to create a vec4 since we're passing the positions to the fragment shader
	// data textures need to have 4 components, R, G, B, and A
	const data = new Float32Array(num_particles * 4);
	const disks = Object.entries(getKDisks(center, kRing));
	const prepOther = [
		{ particles: [center], source: "center" },
		...disks.map(([k, cells], ) => ({ particles: cells, source: `kRing${k}` })).filter(p => p.source !== "kRing0")
	];
	for (const { cell, stride, source } of iterateWorld(worldHexs, num_particles, prepOther)) {
		// Get lat and lng for H3 cell and convert to radians
		const [lat, lng] = cellToLatLng(cell).map(coord => (coord * Math.PI) / 180);
		// Compute the 3D cartesian coordinate of the cell
		let r = radius;
		if (source === "center") {
			r = (distanceFactor + 1) * radius;
		} else if (source === "kRing1") {
			r = ((distanceFactor / 2) + 1) * radius;
		}  else if (source === "kRing2") {
			r = ((distanceFactor / 3) + 1) * radius;
		}  else if (source === "kRing3") {
			r = ((distanceFactor / 4) + 1) * radius;
		}
		const [p1, p2, p3] = latLngToXYZ(lat, lng, r);
		data.set([p1, p2, p3, 1.0], stride);
	}
	return data;
}

const intersect = (arr1: string[], arr2: string[]) => {
	/**
	 * Returns the intersection of two arrays
	 * 
	 * @param arr1 the first array
	 * @param arr2 the second array
	 * @returns the intersection of the two arrays
	 * 
	 */
	const setArr2 = new Set(arr2);
	return arr1.filter((x) => setArr2.has(x));
};

const difference = (arr1: string[], arr2: string[]) => {
	/**
	 * Returns the difference of two arrays
	 * 
	 * @param arr1 the first array
	 * @param arr2 the second array
	 * @returns the difference of the two arrays
	 * 
	 */
	const setArr2 = new Set(arr2);
	return arr1.filter((x) => !setArr2.has(x));
};

const locate =  (
	endScroll: number, 
	location: number[],
	particlesPosition: Particles,
	particlesColor: Particles,
	dollyTo = 5,
	chartStage = 0
): Stage  => {
	/**
	 * Generates a Location Object which tells the Canvas where on earth to look at
	 * or how to rotate the globe
	 */
	return {
		endScroll,
		focus: {
			type: "point" as MethodType,
			lat: location[0], 
			lng: location[1],
			dollyTo
		},
		particlesPosition,
		particlesColor,
		chartStage
	};
};

export { 
	getRandomDataSphere, 
	getHexWorld, 
	getWorldToFall,
	latLngToXYZ, 
	getColor, 
	getMultiColor, 
	intersect, 
	locate,
	difference,
	getWorldWithRaisedAreas,
	getCollapsed
};
