import styled from "styled-components";
import { Canvas } from "@react-three/fiber";

const StyledCanvas = styled(Canvas)<{ backgroundcolor: string, $maxScreenHeight: number }>`
	background-color: ${(props) => props.backgroundcolor};
	width: 100%;
	height: 100%;
	max-height: ${({ $maxScreenHeight }) => $maxScreenHeight }px;
	display: absolute;
	top: 50%;
	transform: translateY(-50%);
	overflow: hidden;
	display: block;
	z-index: 10;
	cursor: pointer;
	&:active {
		cursor: pointer;
	}
	&:hover {
		cursor: pointer;
	}
`;

export {
	StyledCanvas
};