import { Config } from "Constants";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { Utils } from "tema-ai-components";


type Margin = number;
type BreakPoint = {
	breakpoint: number,
	// margin: [top-bottom, left-right]
	margin: [Margin, Margin],
	gap: number,
	columns: number,
	rows: number,
	maxWidth?: number,
};
type ScreenType = "mobileS" | "mobileM" | "mobileL" | "tabletS" | "tabletM" | "tablet" | "tabletL" | "laptopS" | "laptop" | "laptopM" | "laptopL";
const breakPoints: Record<ScreenType, BreakPoint> = {
	mobileS: {
		breakpoint: 320,
		margin: [0, 20],
		gap: 10,
		columns: 6,
		rows: 12
	},
	mobileM: {
		breakpoint: 375,
		margin: [0, 20],
		gap: 10,
		columns: 6,
		rows: 12
	},
	mobileL: {
		breakpoint: 425,
		margin: [0, 20],
		gap: 10,
		columns: 6,
		rows: 12
	},
	tabletS: {
		breakpoint: 550,
		margin: [40, 20],
		gap: 10,
		columns: 6,
		rows: 12
	},
	tabletM: {
		breakpoint: 650,
		margin: [40, 20],
		gap: 15,
		columns: 12,
		rows: 12
	},
	tablet: {
		breakpoint: 768,
		margin: [40, 70],
		gap: 15,
		columns: 12,
		rows: 12
	},
	tabletL: {
		breakpoint: 900,
		margin: [64, 44],
		gap: 20,
		columns: 12,
		rows: 6
	},
	laptopS: {
		breakpoint: 1024,
		margin: [64, 44],
		gap: 20,
		columns: 12,
		rows: 6
	},
	laptop: {
		breakpoint: 1100,
		margin: [64, 44],
		gap: 20,
		columns: 12,
		rows: 6
	},
	laptopM: {
		breakpoint: 1200,
		margin: [64, 44],
		gap: 20,
		columns: 12,
		rows: 6
	},
	laptopL: {
		breakpoint: 1440,
		maxWidth: 1440,
		margin: [64, 44],
		gap: 20,
		columns: 12,
		rows: 6
	},
};
// extract the max width from the size object
const maxScreenBreakPointDevice = Object.keys(breakPoints).reduce(
	(a, b) => 
		breakPoints[a as ScreenType].breakpoint > breakPoints[b as ScreenType].breakpoint ? a : b) as ScreenType;
const maxScreenBreakPoint = breakPoints[maxScreenBreakPointDevice];

// create another object with the same keys as size 
// but with values as media query strings
const device = Object.fromEntries(Object.keys(breakPoints).map((dev: string) => {
	const query = `(max-width: ${breakPoints[dev as keyof typeof breakPoints].breakpoint}px)`;
	return [dev, query];
}));

const margin = Object.fromEntries(Object.keys(breakPoints).map((dev: string) => {
	const margin = breakPoints[dev as keyof typeof breakPoints].margin;
	return [dev, margin];
}));
const gap = Object.fromEntries(Object.keys(breakPoints).map((dev: string) => {
	const gap = breakPoints[dev as keyof typeof breakPoints].gap;
	return [dev, gap];
}));

const size = Object.fromEntries(Object.keys(breakPoints).map((dev: string) => {
	const size = breakPoints[dev as keyof typeof breakPoints].breakpoint;
	return [dev, size];
}));
const columns = Object.fromEntries(Object.keys(breakPoints).map((dev: string) => {
	const columns = breakPoints[dev as keyof typeof breakPoints].columns;
	return [dev, columns];
}));

// extract the latest object from the gap object
const defaultGap = breakPoints[Object.keys(breakPoints).pop() as keyof typeof breakPoints].gap;

const buildGridColumns = (device?: string) => {
	if (!device) device = "laptopL";
	const numCols = breakPoints[device as keyof typeof breakPoints].columns;
	return `repeat(${numCols}, minmax(0, 1fr))`;
};

const buildGridRows = (device?: string) => {
	if (!device) device = "laptopL";
	const numRows = breakPoints[device as keyof typeof breakPoints].rows;
	if (numRows === 1) return "minmax(0, 1fr)";
	return `repeat(${numRows}, minmax(0, 1fr))`;
};

const buildMedia = () => {
	return (
		[...Object.keys(breakPoints).map((dev: string) => {
			const point = breakPoints[dev as keyof typeof breakPoints];
			const gap = point.gap;
			const width = point.maxWidth ? `${point.maxWidth}px` : "100%";
			return `
@media ${device[dev as keyof typeof device]} {
	grid-template-columns: ${buildGridColumns(dev)};
	grid-template-rows: ${buildGridRows(dev)};
	column-gap: ${gap}px;
	row-gap: ${gap}px;
	width: 100%;
	max-width: ${width};
}`;
		})].reverse().join("\n")
	);
};

const buildMarginMedia = () => {
	return (
		[...Object.keys(breakPoints).map((dev: string) => {
			const point = breakPoints[dev as keyof typeof breakPoints];
			return `
@media ${device[dev as keyof typeof device]} {
	padding: ${point.margin.join("px ")}px;
}`;
		})].reverse().join("\n")
	);
};

const GridCSS = css`
	display: grid;
	grid-template-columns: ${buildGridColumns()};
	grid-template-rows: ${buildGridRows(undefined)};
	column-gap: ${defaultGap}px;
	row-gap: ${defaultGap}px;
	${buildMedia()}
`;

const SGrid = styled.div<{ $maxHeight: number }>`
	${GridCSS}
	width: 100%;
	// NEW
	height: 100%;
	max-width: ${maxScreenBreakPoint.maxWidth ? `${maxScreenBreakPoint.maxWidth}px` : "100%"};
	// NEW
	max-height: ${ ({ $maxHeight }) => $maxHeight }px;
	position: relative;
	background-color: transparent;
	flex-grow: 1;
	pointer-events: none;
`;

const Container = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	// NEW
	align-items: center;
	padding: ${maxScreenBreakPoint.margin.join("px ")}px;
	${buildMarginMedia()}
	pointer-events: none;
`;

const Grid = ({
	children, 
	style={},
	containerStyle={}
}: {
	children: React.ReactNode, 
	rows?: number, 
	style?: React.CSSProperties,
	containerStyle?: React.CSSProperties
}) => {
	const { config: { maxScreenHeight } } = Config;

	// Recursive function to clone children and inject pointer-events: auto style
	const injectPointerEvents = useCallback((children: React.ReactNode | React.ReactNode[]): React.ReactNode | React.ReactNode[] => {
		const updatePointerEvents = (child: React.ReactNode): React.ReactNode => {
			if (React.isValidElement(child)) {
				return React.cloneElement(child as React.ReactElement<{ key?: string | number, style?: React.CSSProperties, children: React.ReactNode }>, {
					key: child.key || Math.random().toString(36).substring(7),
					style: { ...child.props.style, pointerEvents: "auto" },
					children: injectPointerEvents(child.props.children) // Recursively update child nodes
				});
			}
			return child;
		};

		if (Array.isArray(children)) {
			return children.map(updatePointerEvents);
		}

		return updatePointerEvents(children);
	}, []);	

	
	return (
		<Container className="grid-container" style={containerStyle}>
			<SGrid style={style} $maxHeight={maxScreenHeight}>
				{injectPointerEvents(children)}
			</SGrid>
		</Container>
	);
};

function getScrollbarWidth() {
	// Create a temporary block element and append it to the document body
	const outer = document.createElement("div");
	outer.style.visibility = "hidden";
	outer.style.overflow = "scroll"; // Force scrollbar to appear
	document.body.appendChild(outer);
  
	// Create an inner div and append it to the outer div
	const inner = document.createElement("div");
	outer.appendChild(inner);
  
	// Calculate the scrollbar width
	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  
	// Remove the outer div from the document
	document.body.removeChild(outer);
  
	return scrollbarWidth;
}

const getSizes = () => {
	const { config } = Config;
	const { width: phoneWidth } = Utils.useWindowDimensions();
	const isPhone = useMemo(() => phoneWidth <= config.phoneBreak, [phoneWidth, config.phoneBreak]);

	const { width, height } = Utils.useWindowDimensions(
		isPhone ? config.mobileScreenDebouncer : config.screenDebouncer
	);
	const [scrollbarWidth, setScrollbarWidth] = useState(0);
	// get window innerWidth
	useEffect (() => {
		setScrollbarWidth(getScrollbarWidth() || 0);
	}, []);

	return {
		scrollbarWidth,
		width,
		height,
		realWidth: width - scrollbarWidth,
		isPhone
	};
};

const useGrid = () => {
	const {
		realWidth,
		height,
		isPhone
	} = getSizes();
	const { config: { maxScreenHeight } } = Config;
	//const { width: phoneWidth } = Utils.useWindowDimensions();
	
	//const isPhone = realWidth <= PHONE_WIDTH
	//const { width, height } = Utils.useWindowDimensions();
	//const [scrollbarWidth, setScrollbarWidth] = useState(0);
	//const realWidth = useMemo(() => width - scrollbarWidth, [width, scrollbarWidth]);
	// get window innerWidth
	//useEffect (() => {
	//	setScrollbarWidth(getScrollbarWidth() || 0);
	//}, []);

	// Find the device based on the width
	const device = (Object.keys(breakPoints).find((dev: string) => {
		return realWidth <= breakPoints[dev as keyof typeof breakPoints].breakpoint;
	}) || "laptopL") as ScreenType;  // default to desktopL if no device is found

	// Compute the margin 
	const point = breakPoints[device];
	let [marginY, marginX] = point.margin;
	let [paddingY, paddingX] = [0, 0];
	if (point.maxWidth && realWidth > point.maxWidth) {
		marginX = (realWidth - point.maxWidth) / 2;
		paddingX = marginX;
		let [, x] = maxScreenBreakPoint.margin;
		if (realWidth - x > point.maxWidth) x = 0;
		marginX = marginX + x;
	}
	if (height > maxScreenHeight) {
		marginY = (height - maxScreenHeight) / 2;
		paddingY = marginY;
		let [y, ] = maxScreenBreakPoint.margin;
		if (height - y > maxScreenHeight) y = 0;
		marginY = marginY + y;
	}
	const margin = [marginY, marginX];
	const padding = [paddingY, paddingX];

	// find gap between columns
	const gap = point.gap;
	const column = point.columns;
	const row = point.rows;
	const cellWidth = (realWidth - (margin[1] as number) * 2 - gap * (column - 1)) / column;
	const cellHeight = (height - (margin[0] as number) * 2 - gap * (row - 1)) / row;
	const getColStart = (col: number) => margin[1] as number + col * (cellWidth + gap);
	const getRowStart = (row: number) => margin[0] as number + row * (cellHeight + gap);
	const gridWidth = column * cellWidth + gap * (column - 1);
	return {
		width: realWidth,
		gridWidth,
		totalGridWidth: gridWidth + (margin[1] as number) * 2,
		device,
		columns: column,
		rows: row,
		gap,
		margin,
		marginX: margin[1],
		marginY: margin[0],
		padding,
		paddingX: padding[1],
		paddingY: padding[0],
		/** 
		 * Old property, should not be used.
		 * @deprecated Use cellWidth instead.
		 */
		colWidth: cellWidth,
		cellWidth,
		cellHeight,
		getColStart,
		getColEnd: (col: number) => getColStart(col) + cellWidth,
		getRowStart: getRowStart,
		getRowEnd: (row: number) => getRowStart(row) + cellHeight,
		getColSpace: (numCols: number, borderGap = false) => {
			const end = getColStart(numCols) + cellWidth;
			const start = getColStart(1);
			return borderGap ? end - start + 2 * gap : end - start;
		},
		isPhone
	};
};

export default Grid;
export {
	gap, 
	margin,
	columns,
	device,
	useGrid,
	size,
	GridCSS
};