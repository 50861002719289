import React from "react";
import AppRouter from "Routers/Router";
import { Amplify } from "aws-amplify";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import styled from "styled-components";
import { useTheme, TemaParticlesProvider, StoryContextProvider } from "Context";
import CookieConsentProvider from "./Components/Reusable/CookieConsent";
import FlipYourPhone from "Components/Reusable/FlipYourPhone";

import "./App.scss";

const { REACT_APP_POOL_ID, REACT_APP_CLIENT_ID } = process.env;

const awsconfig = {
	"aws_project_region": "us-east-1",
	"aws_cognito_region": "us-east-1",
	"aws_user_pools_id": REACT_APP_POOL_ID,
	"aws_user_pools_web_client_id": REACT_APP_CLIENT_ID,
	"aws_appsync_region": "us-east-1",
	"aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

Amplify.configure(awsconfig);

const StyledApp = styled.div`
	font-family: 'Inter', sans-serif;
	background-size: cover;
	background-position: center;
	margin: auto;
	background-color: #${props => props.theme.background};
`;

function App() {
	const { theme } = useTheme();
	return (
		<StyledThemeProvider theme={theme}>
			<StyledApp className="App">	
				<StoryContextProvider>
					<CookieConsentProvider>
						<TemaParticlesProvider>
							<FlipYourPhone>
								<AppRouter />
							</FlipYourPhone>
						</TemaParticlesProvider>		
					</CookieConsentProvider>
				</StoryContextProvider>
			</StyledApp>
		</StyledThemeProvider>
	);
}

export default App;
