import React from "react";
import styled from "styled-components";
import { Parallax } from "tema-ai-components";
import Grid from "Components/Reusable/Grid";
import { MISSION } from "Components/Pages/Main/copy";
import { SupraTitleCSS, SubTitleCSS } from "Styles";
import { LineBlock, MobileSection } from "../Utils";

// Text block for the mission section
const Text = styled.div`
	${ SupraTitleCSS }
	grid-column: 1 / span end;
	grid-row: 2 / span 4;
	display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const SubText = styled.div`
	grid-column: 1 / span end;
	grid-row: 5 / span 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	p {
		margin: 0;
	}
	.highlight {
			color: ${({ theme }) => theme.textColor};
		    margin: 0;
		    padding: 0;
	}
`;

const CardBlock = styled.div<{ $row: number }>`
	${ SubTitleCSS }
    grid-column: 1 / span end;
	grid-row: ${({ $row }) => $row} / span 2;
	position: relative;
	p {
		margin: 0;
	}
	.title {
		color: ${({ theme }) => theme.accentColor};
	}
	.subtitle {
		color: ${({ theme }) => theme.textColor};
		margin-bottom: 10px;
	}
`;

const Mission = ({start, end} : {start: number, end: number}) => {

	return (
		// @ts-ignore
		<Parallax.Layer className="mission__layer" sticky={{ start, end, height: "100%" }}>
			<MobileSection text="why tema ai"/>
			<Grid>
				<Text><div>{ MISSION.title }</div></Text>
				<SubText>
					<LineBlock text={ MISSION.subtitle}/>
				</SubText>
				<CardBlock $row={8}>
					{ MISSION.card1}
				</CardBlock>
				<CardBlock $row={10}>
					{ MISSION.card2}
				</CardBlock>
			</Grid>
		</Parallax.Layer>
	);
};

export default Mission;