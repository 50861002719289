import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import Header from "./Header";


const SideMenuS = styled.div`
	position: fixed;
	background-color: ${({ theme }) => theme.background};
	width: 225px;
	top: 0;
	left: 0;
	height: 100%;
	border-radius: 0 10px 10px 0;
	animation: width 0.5s;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	.icon {
		color: ${({ theme }) => theme.textColorLight};
		font-size: 1.15em;
		cursor: pointer;

	}
`;

const ItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 10px 20px;
	margin-top: 60px;
	width: 100%;
	// first section has no margin top
	.section:first-child {
		margin-top: 0;
	}
	.section  {
		margin-top: 25px;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
		.sectionName {
			width: 100%;
			color: ${({ theme }) => theme.textColorLight};
			margin: 0;
			padding: 0;
			cursor: default;
			user-select: none; /* Prevent selection */
					-webkit-user-select: none; /* For Safari */
					-moz-user-select: none; /* For Firefox */
					-ms-user-select: none; /* For Internet Explorer/Edge */
		}
		.itemCard {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: ${({ theme }) => theme.textColor};
			margin: 0;
			padding: 0;
			border-radius: 5px;
			margin-left: 10px;
			cursor: pointer;	
			padding: 5px 10px;
			width: calc(100% - 10px);
		}
		.itemCard.comingSoon {
			.name {
				opacity: 0.5;
			}
			cursor: not-allowed;
			.soonTag {
				font-size: 0.65em;
				opacity: 1;
			}
		}

		.itemCard.active:not(.comingSoon),
		.itemCard:hover:not(.comingSoon) {
			background-color: ${({ theme }) => theme.accentColor};
			.name {
				color: ${({ theme }) => theme.background};
			}
		}
	}
`;

type  MenuItems = {
	sectionName: string;
	items: Array<{ name: string, link: string, id: string, soon?: boolean }>
};

const menuItems = [
	{ 
		sectionName: "Data", 
		items: [
			{ name: "Commodities", link: "/data", id: "data", soon: true },
			{ name: "Regions", link: "/regions", id: "item2", soon: true },
			{ name: "Industries", link: "/industries", id: "item2", soon: true },
			{ name: "Documentation", link: "/item3", id: "item3", soon: true }
		]
	},
	{ 
		sectionName: "Connect", 
		items: [
			{ name: "Rest API", link: "/item2", id: "item2", soon: true  },
			{ name: "Delta Share", link: "/item1", id: "deltaShare" },
		]
	},
	{ 
		sectionName: "Account", 
		items: []
	},	
	{ 
		sectionName: "Support", 
		items: []
	}	
];

const Items = ({ items,  activeItem } : { items:  MenuItems[], activeItem?: string }) => {
	return (
		<ItemsContainer>
			{items.map((section, index) => (
				<div className="section" key={index}>
					<h3 className="sectionName">{section.sectionName}</h3>
					{section.items.map((item, index) => (
						<div 
							key={index} 
							className={classNames(
								"itemCard", 
								{
									active: item.id == activeItem,
									comingSoon: item.soon
								}
							)
							}
						>
							<span className="name">{item.name}</span> 
							{item.soon && <span className="soonTag">soon</span>}
						</div>
					))}
				</div>
			))}
		</ItemsContainer>
	);
};

const SideMenu = ({activeItem} : {activeItem: string}) => {
	return (
		<SideMenuS>
			<Header/>
			<Items items={menuItems} activeItem={activeItem}/>
		</SideMenuS>
	);
};

export default SideMenu;