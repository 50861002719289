import React, { useEffect } from "react";
import { useAuthenticator, View } from "@aws-amplify/ui-react";
import * as portals from "react-reverse-portal";
import { useNavigate, useLocation } from "react-router";
import { useParticles } from "Context";
import Page from "Components/Reusable/Page";
import { BasicFooter } from "Components/Reusable/Footer";
import Grid, { useGrid } from "Components/Reusable/Grid";
import { WebAuthenticator } from "Components/Reusable/Cognito";
import constants from "../Main/constants";
import Footer from "../Main/Blocks/Home/Footer"; // Import the new component

const Login = () => {
	const { route } = useAuthenticator((context) => [context.route]);
	const { isPhone } = useGrid();
	const location = useLocation();
	const navigate = useNavigate();

	const { particlesPortal, scrollTo } = useParticles();
	useEffect(() => scrollTo(0), [scrollTo]);

	const from = location.state?.from?.pathname || "/";

	useEffect(() => {
		if (route === "authenticated") {
			navigate(from, { replace: true });
		}
	}, [route, navigate, from]);
	const style = {
		height: "100%", width: "100%", //position: "absolute"
	};
	return (
		<Page 
			hideNavBarAfter={Infinity} 
			sectionName="login"
			navBarItems={[]}
			homeLink={`/?${constants.SEARCH_PARAM_SECTION}=home`}
			ctaLink={`/?${constants.SEARCH_PARAM_SECTION}=audience&${constants.SEARCH_PARAM_NATURE}=general`}
		>
			<div style={{ 
				top: 0, 
				width: "100%", 
				height: "100%", 
				position: "fixed", 
				margin: 0, 
				padding: 0, 
				zIndex: 0, 
			}}>
				<portals.OutPortal node={particlesPortal} />
			</div>
			<Grid>
				<View className="auth-wrapper" style={style}>
					<WebAuthenticator/>
				</View>
				<BasicFooter right/>
				<Footer showSocial={!isPhone} showCornerButton={false}/>
			</Grid>
		</Page>
	);
};

export default Login;