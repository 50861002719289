import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import styled from "styled-components";

const Container = styled.div`
    font-family: ${({ theme }) => theme.textFont};  
    letter-spacing: 0.5px;
    background-color: #F6F5F7;
    color: #000220;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: 0.5px solid #000000;
    border-radius: 4px;
    padding: 15px 20px 15px 20px;
    gap: 30px;
    min-width: 180px;
    // add a cool shadow
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    p {
        margin: 0;
        padding: 0;
    }
    .bold {
        font-weight: 700;
    }
    .signOut {
        cursor: pointer;
    }
`; 

const AccountMenu: React.FC = () => {
	const { user, signOut } = useAuthenticator((context) => [context.user]);
	return (
		<Container>
			<div>
				<p className="bold">Signed in as</p>
				{/* @ts-ignore */}
				<p>{user.signInDetails.loginId}</p>
			</div>
			<p className="bold signOut" onClick={signOut}>Sign Out</p>
		</Container>
	);
};

export default AccountMenu;