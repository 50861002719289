import React from "react";
import styled from "styled-components";
import Page from "Components/Reusable/Page";
import { useGrid } from "Components/Reusable/Grid";
// @ts-ignore
import Markdown from "./Markdown";

const MarkdownPageWrapper = styled.div<{$marginX?: number}>`
	position: relative;
  	z-index: 1;
	background: ${props => props.theme.worldParticles.backgroundColor};
	background-attachment: fixed;
	display: flex;
	min-height: 100vh;
	justify-content: center;
	margin: 0;
	text-align: left;
	padding: 0;
	margin-top: 100px;
	margin: ${({ $marginX }) => `50px ${$marginX}px`};
`;

const MarkdownContent = styled.div`
	margin-top: 50px;
	margin-bottom: 50px;
	color: ${props => props.theme.textHighlightColor || "white"} !important;
	img {
      width: 100%;
    }

	font-family: 'Manrope', sans-serif;

	// links a no styling after visiting 
	a {
		color: white;
		text-decoration: none;
		color: ${props => props.theme.accentColor};
		&:visited {
			color: ${props => props.theme.accentColor};
		}
		&:hover {
			color: ${props => props.theme.accentColor};
		}
	}
`;

const MarkdownPage = ({
	name,
	content
} : {content: string, name?: string}) => {
	const { marginX } = useGrid();
	return (
		<Page 
			hideNavBarAfter={Infinity} 
			sectionName={ name }
			navBarItems={[]}
			limitHeight={false}
		>
			<MarkdownPageWrapper $marginX={marginX as number}>
				<MarkdownContent>
					<Markdown content={content} />
				</MarkdownContent>
			</MarkdownPageWrapper>
		</Page>
	);
};

export default MarkdownPage;