import React from "react";
import { FormFields, Utils } from "tema-ai-components";
import { useGrid } from "Components/Reusable/Grid";
import constants from "./constants";
import styled from "styled-components";
import { EyeBrowseCSS } from "Styles";
import { useTheme } from "Context";

const EmailInputAndConsent = styled(FormFields.EmailInputAndConsent)`
	input {
		${ EyeBrowseCSS }
		color: ${({ theme }) => theme.textColor};
		&::placeholder {
			${ EyeBrowseCSS }
			color: ${({ theme }) => theme.textColorLight};
		}
	}
	button {
		${ EyeBrowseCSS }
	}
	.consent__checkbox {
		div {
			p {
				${ EyeBrowseCSS }
			}
		}
	}
`;

const Register = ({ mobile=false } : { mobile?: boolean }) => {
	const { theme } = useTheme();
	const email = Utils.submitEmailWithRecaptcha({
		endpoint: constants.REGISTER_INTEREST_URL,
		recaptchaAction: constants.reCAPTCHA_ACTION,
		invalidEmailMessage: "Please enter a valid email",
		submitErrorMessage: "Something went wrong, please try again later",
	});
	const consent = Utils.submitEmailWithRecaptcha({
		endpoint: constants.SUBSCRIBE_NEWSLETTER_URL,
		recaptchaAction: constants.reCAPTCHA_ACTION,
		invalidEmailMessage: "Please enter a valid email",
		submitErrorMessage: "Something went wrong, please try again later",
	});
	const { cellWidth, columns } = useGrid();
	const initialWidth = cellWidth < 75 || columns < 5 ? 45 : 30;
	return (<EmailInputAndConsent
		//@ts-ignore
		buttonText="Register"
		placeholderText="Enter email to join waitlist"
		loadingText="Loading..."
		successText="You are all set"
		// @ts-ignore  -- Makes no sense as they are the same types
		email={email}
		//@ts-ignore
		consent={consent}
		color={theme.accentColor}
		placeHolderColor="rgba(255, 255, 255, 0.6)"		
		tickColor={theme?.accentColor}
		tickBgColor="transparent"
		tickBorder={`1px solid ${theme.accentColor}`}
		errorColor="#E52C5A"	
		alignButtonContent="center"
		initialWidth={initialWidth}
		mobile={mobile}
	/>);
};

export default Register;