import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { Parallax } from "tema-ai-components";
import { useGrid } from "Components/Reusable/Grid";
import Grid, { device } from "Components/Reusable/Grid";
import { FEATURES } from "Components/Pages/Main/copy";
import { TitleCSS } from "Styles";
import { MobileSection } from "../Utils";
import { CardTitle, ContentCSS } from "Styles";
import { Line } from "./Technology";

const Title = styled.div`
    grid-column: 1 / span end;
    grid-row: 2 / span 4;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	p {
		margin: 0;
		padding: 0;
	}
    ${ TitleCSS }

	@media ${device.mobileL} {
		grid-column: 1 / span 6;
	}
`;

const CardCSS = css`
	background-color: ${({ theme }) => theme.cardColor};
	border-radius: 4px;
	border: 1px solid rgba(255, 255, 255, 0.06);
`;

const Text = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 40px;
`;

const Container = styled.div`
	${ CardCSS }
	position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 10px;
	.container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;

		.content-block {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			//gap: 20px;
			.title-block {
				${ Text }
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				.title {
					${ CardTitle }
					color: ${({ theme }) => theme.textColor};
				}
				.number {
					font-size: 0.8em;
					color: ${({ theme }) => theme.textColorSuperLight};
				}
			}
			.content {
				margin-top: 10px;
				color: ${({ theme }) => theme.textColorLight};
				${ ContentCSS }
			}
		}	
	}
`;

const Features = ({
	start, 
	end
} : {
    start: number, 
    end: number
}) => {
	const { marginX, marginY, cellHeight, gap, gridWidth } = useGrid();

	const items = useMemo(() => {		
		return(
			FEATURES.cards.map((card, i, all) => 
				<Container key={i}>
					<div className="container">
						<div className="content-block">
							<div className="title-block">
								<span className="title">{card.title}</span>
								<span className="number">{i + 1}/{all.length}</span>
							</div>
							<Line/>
							<div className="content">{card.content}</div>
						</div>
					</div>
				</Container>
			)
		);
	}, [FEATURES.cards]);
	const cardSizes = useMemo(() => FEATURES.cards.map(() => `${gridWidth}px`), [FEATURES.cards]);
	const cardLefts = useMemo(() => FEATURES.cards.map(() => `${marginX}px`), [FEATURES.cards]);
	
	const layer = useMemo(() => {
		return (
			<>
				<Parallax.Layer className="features__layer" sticky={{ start, end}}>
					<MobileSection text="what you get"/>
					<Grid>
						<Title>
							<p>{FEATURES.title}</p>
						</Title>
					</Grid>
				</Parallax.Layer>
				<Parallax.Deck 
					cards={items}
					cardSizes={cardSizes}
					cardLefts={cardLefts}
					distance={`${marginY + cellHeight}px`}
					stickTo="bottom"
					height={`${cellHeight * 6 + 5 * gap}px`}
					start={start + (end - start) * 0.15}
					end={end}
				/>
			</>
		);
	}, [items, cardSizes, cardLefts, marginY, cellHeight, gap, start, end]);
	return layer;
};

export default Features;
export {
	CardCSS
};