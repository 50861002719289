import React, { useEffect } from "react";
import Page from "Components/Reusable/Page";
import styled from "styled-components";
import * as portals from "react-reverse-portal";
import { useParticles } from "Context";
import { Link } from "react-router-dom";
import { SubTitleCSS } from "Styles";
import Grid from "Components/Reusable/Grid";

import constants from "../Main/constants";

const FixedContainer = styled.div`
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0;
    padding: 0;
    z-index: 0;
`;

const Text = styled.div`
    pointer-events: auto;
    grid-column: 1/ end;
    grid-row: 2/ end;
    height: 100%;
    width: 100%;
    p {
        margin: 0;
        padding: 0;
        text-align: center;
    }
    .code {
        font-size: 240px;
        line-height: 220px;
        color: ${({ theme }) => theme.accentColor};
        font-family: Inter;
        font-weight: 200;
        @media screen and (max-width: 768px) {
            font-size: 180px;
            line-height: 160px;
        }
    }
    ${ SubTitleCSS }
    gap: 20px;
    .text {
        font-size: 1.2em;
    }
    .subtext {
        font-size: 0.8em;
    }
    a {
        color: ${({ theme }) => theme.accentColor};
        text-decoration: none;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const NotFoundText = () => {
	return (
		<Text>
			<p className="code">404</p>
			<p className="text">We couldn&apos;t find the page you were looking for</p>
			<p className="subtext">
                You can go back to <Link to="">tema.ai</Link> or you can <Link to="/?section=audience&nature=general">contact</Link> us about what you were looking for
			</p>
		</Text>
	);
};

const NotFound = () => {
    
	const { particlesPortal, scrollTo } = useParticles();
	useEffect(() => scrollTo(0), [scrollTo]);

	return (
		<Page 
			hideNavBarAfter={Infinity} 
			sectionName="404"
			navBarItems={[]}
			homeLink={`/?${constants.SEARCH_PARAM_SECTION}=home`}
			ctaLink={`/?${constants.SEARCH_PARAM_SECTION}=audience&${constants.SEARCH_PARAM_NATURE}=general`}
		>
			<FixedContainer>
				<portals.OutPortal node={particlesPortal} />
			</FixedContainer>
			<Grid>
				<NotFoundText/>
			</Grid>

		</Page>
	);
};

export default NotFound;