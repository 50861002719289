import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Charts } from "tema-ai-components";
import styled from "styled-components";

import { Html } from "@react-three/drei";
import { useFrameLimiter } from "./AdvancedFiberParticles"; // Import the custom hook

import { useGrid } from "Components/Reusable/Grid";
import { EyeBrowseCSS } from "Styles";
import { useStory } from "Context";
import { CommodityInfo } from "Context/Story/stories";
import { LabelType, ChartHtmlProps, ChartProps } from "./types";
import { getCurrentStageIndex, sortWithSubset } from "./utils";

type NumberKeyedObject = {
    [key: number]: unknown;
};

const WorldChart = styled(Charts.WorldDots)<{$fontSize: number}>`
	.commodity__halo {
		.highlight,
		.name {
			${ EyeBrowseCSS }
			font-size: ${({ $fontSize }) => $fontSize}px;
		}
		.highlight {
			fill: ${({ theme }) => theme.accentColor};
		}
	}
	.commodity__metric {
		.main-text,
		.secondary-text,
		.metric {
			${ EyeBrowseCSS }
			font-size: ${({ $fontSize }) => $fontSize}px;
		}
		.metric {
			font-size: ${({ $fontSize }) => $fontSize * 1.2}px;
		}
		.main-text {
			fill: ${({ theme }) => theme.textColor};
		}
		.secondary-text {
			fill: ${({ theme }) => theme.textColorLight};
		}
	}
	.commodity__label {
		.main-text,
		.secondary-text {
			${ EyeBrowseCSS }
			font-size: ${({ $fontSize }) => $fontSize}px;
		}
		.main-text {
			fill: ${({ theme }) => theme.textColor};
		}
		.secondary-text {
			fill: ${({ theme }) => theme.textColorLight};
		}
	}
	.weather__label {
		.main-text,
		.secondary-text {
			${ EyeBrowseCSS }
			font-size: ${({ $fontSize }) => $fontSize}px;
		}
	}
`;

const Chart = ({ 
	stage, 
	story,
	targetCommodity,
	theme
} : ChartProps) => {
	const [startAngle, setStartAngle] = React.useState<number>(0);
	const [endAngle, setEndAngle] = React.useState<number>(0);
	const [otherCommodities, setOtherCommodities] = React.useState<CommodityInfo[]>(undefined);
	const [ diameter, setDiameter ] = React.useState<number>(0);
	const [ label, setLabel ] = React.useState<LabelType>({
		fontSize: 12,
		labelSize: 44,
	});
	const { cellHeight, gap, isPhone, cellWidth, gridWidth } = useGrid();

	// sort commodities to make sure they are visible on phone 
	const sortedCommodities = useMemo(() => {
		// otherwise we need to make sure that the relevant ones fall under 
		const baseHighlighted = story.commodities.filter(s => s.highlight).map(s => s.name);
		const commoditiesToShow = [...new Set([...baseHighlighted, ...story.globalCommodities])];
		const lastCommodityIndex = isPhone ? commoditiesToShow.length * 2 : story.commodities.length - 14;
		const sortedItems = sortWithSubset(
			[...story.commodities.map(s => s.name)],
			commoditiesToShow,
			1, 
			lastCommodityIndex
		);

		return sortedItems.map(c =>({name: c as string, highlight: baseHighlighted.includes(c as string)}));
	}, [isPhone, story.commodities, story.globalCommodities]);

	useLayoutEffect(() => {
		if (targetCommodity === undefined) {
			setOtherCommodities(undefined);
			return;
		}
		const commodities = sortedCommodities.map(c =>({
			name: c.name,
			highlight: c.name.toLowerCase() === targetCommodity.toLowerCase()
		}));
		setOtherCommodities(commodities);
	}, [sortedCommodities, targetCommodity]);

	const chooseBreak = (breaks: NumberKeyedObject, reference: number) => {
		const keys = Object.keys(breaks).map(Number).sort((a, b) => a - b);
		const key = keys.find(k => reference <= k) || keys.slice(-1)[0];
		return breaks[key];
	};

	useEffect(() => {
		if (diameter === 0) return;
		const breaks = {
			300: { fontSize: 10, labelSize: 44 },
			1000: { fontSize: 12, labelSize: 44 },
			1200: { fontSize: 12, labelSize: 44 },
			1500: { fontSize: 12, labelSize: 44 },
		} as Record<number, LabelType>;
		const breakV = chooseBreak(breaks, gridWidth);
		setLabel(breakV as LabelType);
	}, [gridWidth, diameter]);
	
	useEffect(() => {
		const breaks = {
			0: 3.25,
			100: 3.25,
			155: 3,
		} as Record<number, number>;
		const phoneBreak = {
			40: 8,
			50: 8,
			75: 8,
			155: 8,
		} as Record<number, number>;
		const m = chooseBreak(isPhone ? phoneBreak : breaks, cellHeight) as number;
		const diameter = m * (cellHeight + gap) + cellHeight ;
		setDiameter(diameter);
	}, [cellWidth, gap, cellHeight, isPhone]);

	useEffect(() => {
		if (!stage || stage === undefined) return;
		if (isPhone) {
			// For desktop 
			setStartAngle(78);
			setEndAngle({
				4: 200,
				5: 200,
			}[stage] || 220);
		} else {
			// For desktop 
			setStartAngle({
				3: 40,
				4: 40,
			}[stage] || 0);
			setEndAngle({
				3: 220,
				4: 220,
			}[stage] || 220);
		}
	}, [stage, isPhone]);

	const chart = useMemo(() => {
		return <WorldChart
			startAngle={startAngle}
			endAngle={endAngle}
			diameter={diameter * 0.9} 
			stage={stage || 0}
			commodities={otherCommodities || sortedCommodities}
			locationName={story?.name}
			commodityName={"Factor exposure"}
			locationSubtitle={stage === 4 ? "Domestic x Global": "Output"}
			commodityValue={story?.commodityValue}
			highlightColor={theme.accentColor}
			weatherIconHighlightColor={theme.textColorLight}
			fadedColor={theme.textColorSuperLight}
			iconBackgroundColor={"transparent"}
			labelHeight={label.labelSize}
			$fontSize={label.fontSize}
			textColor={theme.textColorSuperLight}
			states={{
				0: {},
				1: {halo: "enter"},
				2: {halo: "enter", label: {icon: "arrow", expand: true, title: true, subtitle: true}},
				3: {halo: "exit", bars: "enter"},
				4: {bars: "exit", metric: "enter"},
				5: {metric: "exit"},
			}}
		/>;
	}, [stage, diameter, story, label, theme, otherCommodities, startAngle, endAngle]);
	return (stage === undefined) ? null : chart;
};

const  ChartHtml = ({
	offset, 
	stages, 
	offsetTransform,
	interval,
	theme
} : ChartHtmlProps) => {
	const [chartStageIndex, setChartStageIndex] = useState<number>(undefined);
	const [chartStage, setChartStage] = useState<number>(undefined);
	const [commodity, setCommodity] = useState<string>(undefined);
	const { isPhone } = useGrid();
	const { story } = useStory();

	useFrameLimiter(({state}) => {
		const { clock } = state;
		const i = getCurrentStageIndex(stages, offset.current, offsetTransform);
		if (i === -1) return;
		setChartStageIndex(i);

		const stage = stages[i];
		if ((stage.numColorStages || 1) > 1) {
			const toHighlight = Math.ceil((1 / interval) * clock.elapsedTime % (stage.numColorStages || 1));
			setCommodity(story.globalCommodities[toHighlight - 1]);
		} else {
			setCommodity(undefined);
		}
	});

	useEffect(() => {
		if (chartStageIndex === -1 || chartStageIndex === undefined) return;
		const stage = stages[chartStageIndex];
		setChartStage(stage.chartStage);
	}, [chartStageIndex]);
	
	const chart = useMemo(() => {
		if (chartStage === undefined) return null;
		return <Chart stage={chartStage} story={story} targetCommodity={commodity} theme={theme}/>;
	}, [chartStage, story, commodity, theme]);
	return (
		<Html 
			position={isPhone ? [-0.4, -0.4, 0] : [0, 0, 0]} 
			occlude={false} 
			style={{ transform: "translate(-50%, -50%)" }}
		>
			{ chart }
		</Html>
	);
};

export default ChartHtml;