const COMMODITIES = [
	"Aluminum",
	"Bio-diesel",
	"Butane",
	// "Butter",
	"Cattle",
	// "Cheese",
	"Cocoa",
	"Coal",
	"Cobalt",
	"Coffee",
	"Copper",
	"Corn",
	"Cotton",
	"Crude Oil",
	"Diesel",
	"Ethanol",
	// "Gasoline",
	"Gold",
	"Iron Ore",
	"Lead",
	"Lean Hogs",
	"Lithium",
	"Live Cattle",
	"Lumber",
	"Manganese",
	"Maize",
	"Methanol",
	"Milk",
	"Molybdenum",
	"Natural Gas",
	"Nickel",
	"Oat",
	"Olives", 
	"Oranges",
	"Palladium",
	"Palm Oil",
	"Platinum",
	"Propane",
	"Rice",
	"Semiconductors",
	"Silver",
	"Soybean",
	"Soybean Oil",
	"Steel",
	"Sugar",
	"Sunflower Oil",
	"Tea",
	"Tin",
	"Uranium",
	"Urea",
	"Wheat",
	"Zinc"
];

interface CommodityStoryItem {
	name: string,
	globalCommodities: string[],
	commodity: string,
	commodityValue: number,
	commodities: string[],
	coordinates: [number, number],
	finalCountry: string,
	countryLocation: [number, number]
}
interface _CommodityStory {
    [key: string]: CommodityStoryItem;
}
const GLOBAL_COM = [
	"Aluminum", 
	"Lithium", 
	"Nickel", 
	"Maize", 
	"Cocoa", 
	"Coffee", 
	"Rice",
	"Cotton",
	"Wheat"
];
const _COMMODITY_STORY: _CommodityStory = {
	cotedivoire: {
		name: "Cote d'Ivoire",
		globalCommodities: GLOBAL_COM,
		commodity: "Cocoa",
		commodityValue: 50,
		commodities: [
			"Cocoa",
			"Gold",
			"Petroleum",
			"Rubber",
			"Palm oil",
			"Cotton"
		],
		coordinates: [7.53, -5.5471],
		finalCountry: "CIV",
		countryLocation: [7.53, -5.5471],
	},
	australia: {
		name: "Australia",
		globalCommodities: GLOBAL_COM,
		commodity: "Lithium",
		commodityValue: 34,
		commodities: [
			"Iron ore",
			"Uranium",
			"Coal",
			"Zinc",
			"Wheat",
			"Cotton",
		],
		coordinates: [-25, 133],
		finalCountry: "AUS",
		countryLocation: [-25, 133],
	},
	brasil: {
		name: "Brazil",
		globalCommodities: GLOBAL_COM,
		commodity: "Coffee",
		commodityValue: 25,
		commodities: [
			"Coffee",
			"Soybean",
			"Soybean oil",
			"Soybean meal",
			"Oranges",
			"Corn",
			"Sugar",
			"Iron ore",
		],
		coordinates: [-17.9302, -43.7908],
		finalCountry: "BRA",
		countryLocation: [-17.9302, -43.7908],
	},
	china: {
		name: "China",
		globalCommodities: GLOBAL_COM,
		commodity: "Aluminum",
		commodityValue: 42,
		commodities: [
			"Aluminum",
			"Tea",
			"Coffee",
			"Tin",
			"Copper",
			"Lead",
			"Tobacco",
			"Lithium",
			"Rice",
			"Wheat",
			"Lean hogs"
		],
		coordinates: [35, 107],
		finalCountry: "CHN",
		countryLocation: [35, 107],
	},
	taiwan: {
		name: "Taiwan",
		globalCommodities: GLOBAL_COM,
		commodity: "Semiconductors",
		commodityValue: 30,
		commodities: [
			"Semiconductors",
			"Steel",
			"Aluminum",
		],
		coordinates: [23.6978, 120.9605],
		finalCountry: "TWN",
		countryLocation: [23.6978, 120.9605],
	}
};

interface CommodityInfo {
    name: string;
    highlight: boolean;
}

interface CommodityStoryItemExpanded extends Omit<CommodityStoryItem, "commodities"> {
	commodities: CommodityInfo[];
}

interface CommodityStory {
    [key: string]: CommodityStoryItemExpanded;
}

const COMMODITY_STORY: CommodityStory = Object.keys(_COMMODITY_STORY).reduce((acc, key) => {
	const storyCommodities = _COMMODITY_STORY[key];
	const mappedCommodities = COMMODITIES.map((commodity) => ({
		name: commodity,
		highlight: storyCommodities.commodities.map(v => v.toLowerCase()).includes(commodity.toLowerCase())
	}));
	// we need to change the order so all the highlighted are in the first half
	// but still random.
	const total = mappedCommodities.length;
	const highlighted = mappedCommodities.filter(v => v.highlight);
	const totalHighlighted = highlighted.length;
	const notHighlighted = mappedCommodities.filter(v => !v.highlight).sort(() => Math.random() - 0.5);
	const firstHalf = [...highlighted, ...notHighlighted.slice(0, (total / 2) - totalHighlighted)].sort(() => Math.random() - 0.5);
	const secondHalf = notHighlighted.slice((total / 2) - totalHighlighted);
	const commodities = [...firstHalf, ...secondHalf];
	acc[key] = {
		...storyCommodities,
		commodities
	};
	return acc;
}, {} as CommodityStory);

export {
	COMMODITIES,
	COMMODITY_STORY
};

export type {
	CommodityStory,
	CommodityStoryItemExpanded,
	CommodityInfo
};