import React from "react";
import styled from "styled-components";
import { useGrid } from "Components/Reusable/Grid";
import { Parallax } from "tema-ai-components";
import { SectionTextCSS } from "Styles";

const SectionCSS = styled.div`
	${ SectionTextCSS }
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
`;

const GridSection = styled(SectionCSS)`
    grid-column: 9 / span 5;
	grid-row: 2 / span 1;
`;

const useSection = () => {
	const { getColStart, getColEnd, getRowStart, cellHeight } = useGrid();
	const colStart = getColStart(7);
	const width = getColEnd(11) - colStart;
	return {
		left: `${colStart}px`,
		width: `${width}px`,
		height: `${cellHeight}px`,
		top: `${getRowStart(1)}px`,
	};
};

const GridSectionAbsolute = ({ children } : { children: React.ReactNode }) => {
	const { width, left, height, top } = useSection();
	return <SectionCSS style={{
		width,
		height,
		left,
		top,
		position: "absolute",
	}}>
		<p>{children}</p>
	</SectionCSS>;
};   

type ParallaxLayerSectionNameProps = {
	start: number;
	end: number;
	children: React.ReactNode;
};

const ParallaxLayerSectionName = ({ 
	start, 
	end, 
	children, 
}: ParallaxLayerSectionNameProps) => {
	const { width, left, top } = useSection();
	return (
		<Parallax.Layer
			sticky={{ start, end, top }}
			// @ts-ignore
			style={{ width, left }}
		>
			<SectionCSS>
				<p>{children}</p>
			</SectionCSS>
		</Parallax.Layer>
	
	);
};

export { 
	GridSection, 
	GridSectionAbsolute,
	ParallaxLayerSectionName 
};