import { useContext } from "react";
import { AxiosContext } from "Components/Reusable/api";
import { useAppDispatch } from "store/hooks";
import { useTokens, buildConfig } from "./AuthenticatedRequest";

const useAuthAppDispatch = () => {
	const dispatch = useAppDispatch();
	const contextInstance = useContext(AxiosContext);
	const { accessToken, refresh } = useTokens();
	const headers = buildConfig(accessToken).headers;
	return {
		dispatch,
		contextInstance,
		headers,
		headerReady: accessToken !== undefined,
		refresh
	};
};

export default useAuthAppDispatch;