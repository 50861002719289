import ReactGA from "react-ga4";

type EventProps = {
	category: string;
	action: string;
	label: string;
};

type SetProps = {
	page: string;
	userId?: string;
};


const googleAnalytics = () => {
	/*
  Helper class to agregate all Google Analytics methods
  */
	// Initialise the GA traking event
	const initialize = (googleAnalyticsId: string, params = {}) => {
		ReactGA.initialize(googleAnalyticsId, params);
	};

	// Send a tracking event to GA
	const event = ({ category, action, label } : EventProps) => {
		ReactGA.event({ category, action, label });
	};

	// Set user tracking
	const set = ({ page, userId }: SetProps) => ReactGA.set({ page, userId });

	// Record page view
	const pageview = (page: string) => {
		ReactGA.send({ hitType: "pageview", page });
	};

	return {
		initialize,
		event,
		set,
		pageview,
	};
};

export default googleAnalytics();
