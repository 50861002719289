import { ShaderMaterial } from "three";

import simulationVertexShader from "./simulationVertexShader";
import { prepareTextures, buildShader } from "./utils";

class ColorMaterial extends ShaderMaterial {
	constructor(size: number, particleColors:  { particles: Float32Array; id: string; }[], transitions: number[]) {
		const colorUniforms = prepareTextures(particleColors, size);
		const uniforms = {
			...colorUniforms,
			uScroll: { value: 0 },
			uTime: { value: 0 },
		};
		
		const stages = particleColors.map((state) => state.id);
		super({
			uniforms: uniforms,
			vertexShader: simulationVertexShader,
			fragmentShader: buildShader("uScroll", stages, transitions),
		});
	}
}
export default ColorMaterial;
