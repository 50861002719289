import React, { useLayoutEffect, useMemo } from "react";
import styled from "styled-components";
import { SideMenu } from "Components/Reusable/UserMenu";
import { DeltaShare } from "tema-ai-components";
import ApiKeyConnections from "Components/Reusable/ApiKeyConnections";
import { ScatterBoxLoader } from "Components/Reusable/Loader";
import { useAppSelector } from "store/hooks"; // Assuming you have a typed useSelector hook
import { device } from "Components/Reusable/Grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo as helpIcon } from "@fortawesome/free-solid-svg-icons";

import DOCUMENTATION from "./documentation";


const Sidebar = styled(SideMenu)`
	position: absolute;
	height: 100vh;
	width: 225px;
	left: 0;
	top: 0;
	z-index: 10;
`;

const TitleBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	margin-bottom: 20px;
`;

const LoaderBlock = styled.div`
	width: 100%;
	height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.container {
		width: 200px;
		height: 200px;
	}
`;

const PageContent = styled.div`
	position: relative;
	background-color: #F6F5F7;
	margin-left: 225px;
	padding: 20px;
	width: calc(100% - 225px);
	min-height: 100vh;
	.pageTitle,
	.card,
	.pageSubtitle {
		cursor: default;
		-webkit-user-select: none; /* Safari and Chrome */
		-moz-user-select: none;    /* Firefox */
		-ms-user-select: none;     /* Internet Explorer and Edge */
		user-select: none; 
	}
	.pageTitle {
		font-size: 52px;
		font-weight: 200;
		color: ${({ theme }) => theme.background};
		border-bottom: 2px solid ${({ theme }) => theme.background};
		margin: 0;
		margin-bottom: 20px;
	}
	.pageSubtitle {
		margin: 0;
		margin-top: 20px;
		margin-bottom: 10px;
		font-size: 20px;
		font-weight: 400;
		color: ${({ theme }) => theme.background};
		margin: 0;
		padding: 0;
	}
	.help {
		cursor: pointer;
		font-size: 16px;
		color: ${({ theme }) => theme.background};
	}
	.card {
		min-width: 150px;
		.title {
			font-size: 16px;
		}
		.text {
			font-size: 12px;
		}
	}
`;

const Documentation = styled(DeltaShare.Documentation)`
	// height should be set to auto to allow the content to expand
	height: auto;
	font-size: 14px;
	font-family: "Manrope";
	background-color: rgba(248, 248, 248, 1);
	.codeBlock{
		background-color: rgba(37, 37, 37, 1);
	}
	.navigation {
		color: ${({ theme }) => theme.background};
		.section.selected,
		.section:hover {
			
		}
	}
	.content{
		color: ${({ theme }) => theme.background};
		.sectionName {
			margin-top: 50px;
			&:first-of-type {
				margin-top: 0;
			}
		}
		.title {
		}
		.subtitle {
		}
		.text {
			margin-bottom: 20px;
		}
	}
`;

const Block = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 10px;
	@media ${device.laptopM} {
		flex-direction: column;
	}
	min-height: 400px;
`;

const Help = ({ onClick } : { onClick: () => void }) => {
	return <span className="help" onClick={onClick}>
		<FontAwesomeIcon className="icon" icon={helpIcon}/>
	</span>;
};

const Section = ({ 
	title, 
	children, 
	style,
	help,
} : { 
	title:  string, 
	children: React.ReactNode,
	style?: React.CSSProperties,
	help?: () => void
}) => {
	return (
		<div style={style}>
			<TitleBlock>
				<h1 className="pageSubtitle">{title}</h1>{help && <Help onClick={help}/>}
			</TitleBlock>
			{children}
		</div>
	);
};

const DeltaSharePage = () => {
	const { deltaShare } = useAppSelector((state) => state.user);
	const [block , setBlock] = React.useState(true);
	const docRef = React.useRef<DeltaShare.DocumentationRef>(null);
	
	useLayoutEffect(() => {
		setBlock(!deltaShare.allowed);
	}, [deltaShare.allowed]);

	const summaryShares = useMemo(() => {
		// For each share count the tables, extract the schemas and count them 
		return deltaShare.shares.map(share => {
			const schemas = new Set(
				share.objects.map(ob => ob.name.split(".")[0])
			);
			return {
				name: share.name,
				schemas: schemas.size,
				tables: share.objects.length
			};
		});
	}, [deltaShare.shares]);
	
	const deltaBlock = useMemo(() => (<>
		<Block>
			<Section 
				title="Shares" 
				help={() => docRef.current.goTo("shares")}
				style={{ flexGrow: "1", minWidth: "300px" }}
			>
				{(summaryShares.length == 0 && !deltaShare.loading) && <p>
					There are no shares available for this user. Please contact us at info@tema.ai if you think this is a mistake.
				</p>}
				<DeltaShare.ShareDeck shares={summaryShares} loading={deltaShare.loading}/>
			</Section>
			<Section 
				title="Connections" 
				style={{ flexGrow: "3" }}
				help={() => docRef.current.goTo("connections")}
			>
				<ApiKeyConnections loading={deltaShare.loading}/>
			</Section>
		</Block>
		<Section title="Documentation" style={{position: "relative"}}>
			{/* @ts-ignore */}
			<Documentation ref={docRef} documentation={DOCUMENTATION}/>
		</Section>
	</>), [summaryShares, deltaShare.loading, DOCUMENTATION]);

	const notAllowed = useMemo(() => (
		<p>
			You are not allowed to access this page. Please if you think that this is a mistake contact us directly at info@tema.ai
		</p>
	), []); 
	const loader = useMemo(() => <LoaderBlock><div className="container"><ScatterBoxLoader/></div></LoaderBlock>, []);
	return (
		<>
			<PageContent>
				<p className="pageTitle">Delta Share</p>
				{
					(deltaShare.loading && !deltaShare.allowed) ? loader: (block ? notAllowed : deltaBlock)
				}
			</PageContent>
			<Sidebar activeItem="deltaShare"/>
		</>
	);
};

export default DeltaSharePage;