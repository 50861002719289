import { RefObject, useEffect, useState } from "react";
import { Parallax } from "tema-ai-components";

const useGetSection = ({
	offset, 
	sections
} : {
	offset: RefObject<number>, 
    sections: { start: number, end: number, section: string, name: string, social?: boolean, glassPanel?: boolean }[]
}) => {
	const [sectionName, setSectionName] = useState<string>("home");
	const [selectedItem, setSelectedItem] = useState<string>(null);
	const [showSocial, setShowSocial] = useState<boolean>(false);
	const [showGlassPanel, setShowGlassPanel] = useState<boolean>(false);

	const callback = ({ offset } : { offset: number }) => {
		for (const item of sections) {
			if (offset >= item.start && offset < item.end) {
				setSectionName(item.section);
				setSelectedItem(item.name);
				setShowSocial(item.social);
				setShowGlassPanel(item.glassPanel);
				break;
			}
		}
	};
	const deps = [sections, offset, setSectionName, setSelectedItem, setShowSocial, setShowGlassPanel];
	useEffect(() => {
		callback({offset: offset.current});
	}, deps);

	Parallax.useParallaxListener({
		offset, 
		callback,
		deps
	});
	return {
		sectionName,
		selectedItem,
		showSocial,
		showGlassPanel
	};
};

export default useGetSection;