import React, { useMemo, useRef } from "react";
import { Parallax } from "tema-ai-components";
import styled from "styled-components";
import { useGrid } from "Components/Reusable/Grid";
import { TECHNOLOGY } from "Components/Pages/Main/copy";
import { CardTitle, ContentCSS } from "Styles";
import { MobileSection } from "../Utils";
import { CardCSS } from "./Features";

const PADDING = 10;
const TitleContainer = styled.div<{ $width: number, $left: number }>`
	${CardCSS}
    display: flex;
	padding: ${ PADDING }px;
    flex-direction: column;
    justify-content: flex-start;
	gap: 10px;
	width: ${({  $width }) => $width}px;
	margin-left: ${({ $left }) => $left}px;
`;
const Line = styled.div`
	height: 1.5px;
	background-color: ${({ theme }) => theme.textColorSuperLight};
`;
const Title = styled.div`
	${ CardTitle }
	color: ${({ theme }) => theme.textColor};
    margin: 0;
    padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;
const Content = styled(Title)`
	${ ContentCSS }
`;
const SubNumber = styled.span`
	font-size: 0.8em;
	color: ${({ theme }) => theme.textColorSuperLight};
`;

const Technology = ({
	start,
	end,
	scrollTo
} : {
    start: number, 
    end: number,
	scrollTo: (pos: number) => void
}) => {
	const layerRef = useRef<HTMLDivElement>(null);
	const { marginX, marginY, cellHeight, gap, gridWidth } = useGrid();
	const cardStart = useMemo(() => start + (end - start) * 0.02, [start, end]);
	const step = useMemo(() => (end - cardStart) / TECHNOLOGY.items.length, [end, cardStart]);

	const onClick = (i: number) => {
		scrollTo && scrollTo(cardStart + step * i);
	};

	const items = useMemo(() => TECHNOLOGY.items.map((item, i, all) => {
		return (
			<Parallax.Layer key={item.title} className={item.title} sticky={{
				start: cardStart + step * i,
				end: cardStart + step * (i + 0.5), 
				height: "250px", 
				top: `${marginY + gap + cellHeight}px`,
			}} >
				<TitleContainer $width={gridWidth} $left={marginX} onClick={() => onClick(i)}>
					<Title>
						<span>{item.title}</span>
						<SubNumber>{i + 1}/{ all.length }</SubNumber>
					</Title>
					<Line/>
					<Content>{item.content}</Content>
				</TitleContainer>
			</Parallax.Layer>
		);
	}), [TECHNOLOGY.items, onClick, scrollTo, cardStart, step, cellHeight]);

	return (
		<>
			<Parallax.Layer className="technology__layer" sticky={{
				start,
				end, 
				height: "160px", 
			}}>
				<div ref={layerRef}>
					<MobileSection text="how it works"/>
				</div>
			</Parallax.Layer>
			{
				items
			}
		</>
	);
};

export default Technology;
export { Line };