import React from "react";
import { 
	Main,
	LogIn,
	NotFound,
	DeltaShare
} from "Components/Pages";
import {
	PrivacyPolicyPage,
// @ts-ignore
} from "Components/Pages/MarkDownPages";

//nested routes in home module
type RouteType = {
	name: string,
	path: string,
	exact?: boolean
	component: React.Component | JSX.Element,
	private?: boolean
};
const homeRoutes = [

	/*Route for Labeler Screen */
	{
		name: "Home",
		path:"/",
		exact: true,
		component: <Main/>,
	},
	{
		name: "Login",
		path:"/login",
		exact:true,
		component: <LogIn/>,
	},
	{
		name: "Privacy",
		path: "/privacy",
		exact: true,
		component: PrivacyPolicyPage,
	},
	/*Route for Dashboard  Screen */
	{
		name: "Delta Share",
		path: "/account",
		exact: true,
		component: <DeltaShare/>,
		private: true
	},
	// 404 - Not found
	{
		name: "404",
		path: "*",
		component: <NotFound/>,
	}
];

export default homeRoutes;
export { RouteType };