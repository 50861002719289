import styled from "styled-components";


const Style = styled.div`
	font-family: 'Manrope', sans-serif;
	
	[data-amplify-authenticator][data-variation=modal] {
		background-color: transparent;
	}
	[data-amplify-authenticator] [data-amplify-router] {
		border: 0;
		background-color: transparent;
	}
	form {
		padding: 0;
		.amplify-flex {
			gap: 0;
		}
	}
	fieldset.amplify-flex {
		gap: 0;
	}
	.amplify-text,
	.amplify-button--link,
	.amplify-button--primary {
		border-radius: 10px;
		text-align: center;
		font-family: 'Manrope', sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 700 !important;
		letter-spacing: 1.2px;
		transition: transform 0.2s,
					box-shadow 0.2s; /* Transform and box-shadow transition on hover */
		&:active {
			box-shadow: 0 0px, 0 0px;
		}
	}
	
	.amplify-button--primary {
		background: rgb(255, 255, 255);
		color: rgba(0, 2, 32, 0.94);
		margin-top: 30px;
		
		&:hover {
			border: 1px solid rgb(128, 128, 128); /* Slightly darker border for depth */
			transition: 0.3s;
			background-color: ${(props) => props.theme.accentColor || "#CDFD50"};
		}
	}

	.amplify-text {
		text-align: left;
		background: transparent;
		color: ${(props) => props.theme.textColorLight || "white"};
		&--error {
			color: #FB0066;
			font-size: 12px;
		}
	}

	.amplify-button--link {
		margin-top: 30px;
		background: transparent;
		color: ${(props) => props.theme.textColorLight || "white"};
		&:hover {
			color: ${(props) => props.theme.accentColor || "#CDFD50"};
		}
	}
	.amplify-input {
		border-radius: 6px;
		background: rgba(255, 255, 255, 0.10);
		
		border: 0;
		
		color: rgba(255, 255, 255, 0.60);

		font-family: 'Manrope' sans-serif;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		letter-spacing: 2.64px;

		&:autofill,
		&:autofill:hover,
		&:autofill:focus,
		&:autofill:active
		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			// We haven't been able to prevent the autofill from changing the color
			// but we can prevent the transition from happening for 50K seconds
			transition: all 0s 50000s, opacity 0s 0s !important;
			border: 0;
		}
		&::placeholder {
			color: ${(props) => props.theme.form?.input?.color || "rgba(255, 255, 255, 0.60)"};
			font-family: 'Manrope', sans-serif;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			letter-spacing: 3.12px;
		}

	}
	.amplify-field {
		margin-top: 30px;
		position: relative;
		backdrop-filter: blur(20px) !important;
		-webkit-backdrop-filter: blur(20px); // For Webkit browsers
		-moz-backdrop-filter: blur(20px); // For Firefox (might not be necessary)
		z-index: 10;
		background-color: transparent;
		width: 100%;
		height: 100%;

		&-group--horizontal {
			gap: 0 !important;
		}
		&__show-password {
			color: ${(props) => props.theme.form?.input?.color || "rgba(255, 255, 255, 0.60)"};
			border: 0;
			background: rgba(255, 255, 255, 0.10);
			backdrop-filter: blur(41px);
			&:hover {
				color: #A2D4EF;
			}
		}
	}
	.amplify-heading {
		font-family: 'Manrope', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 115.5%;
		text-transform: uppercase;
		color: ${(props) => props.theme.textColorLight || "white"};;
	}
	.amplify-label {
		display: none;
	}
	.amplify-label, .amplify-input, .amplify-button--primary {
		text-transform: uppercase;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
	}
	.amplify-input[autocomplete="new-password"],
	.amplify-input[autocomplete="current-password"] {
		text-transform: none;
	}
	.amplify-alert {
		padding: 0;
		&--error {
			display: flex;
			column-gap: 0 !important;
			text-transform: uppercase;
			justify-content: flex-end;
			background-color: transparent;
			color: #FB0066;
			text-align: right;
			font-family: 'Manrope', sans-serif;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			letter-spacing: 1.2px;
		}
		&__icon {
			display: none;
		}
		&__dismiss {
			padding: 0 10px;
			&:hover {
				background: transparent;
				color: #FB0066;
				transform: scale(1.1);
				// immediate transition
				transition: all 0s;
			}
		}
	}
`;

export default Style;