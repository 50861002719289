const formFields = {
	signIn: {
		username: {
			label: "Email",
			placeholder: "Email",
		},
		password: {
			placeholder: "PASSWORD",
		},
	},
	forceNewPassword: {
		password: {
			placeholder: "Please create a new password",
		},
	},
	forgotPassword: {
		username: {
			placeholder: "Enter your email",
		},
	},
};

export default formFields;