type NavbarItem = {
    name: string,
    link: string,
    startOffset: number,
	social?: boolean,
    offset?: number,
	showOnNavBar?: boolean,
	glassPanel?: boolean
};
const SEARCH_PARAM_SECTION = "section";
const SEARCH_PARAM_NATURE = "nature";
// You need to manually sync the offsets with the parallax
const NAVBAR_ITEMS = [
	{
		name: "why",
		link: "mission",
		startOffset: 1,  // when it changes on scroll
		offset: 2,  // where it goes when clicked
		social: false,
		glassPanel: false
	},
	{
		name: "what",
		link: "features",
		startOffset: 2.8,
		offset: 4.5,
		social: false,
		glassPanel: false
	},
	{
		name: "how",
		link: "technology",
		startOffset: 5,
		offset: 6,
		social: false,
		glassPanel: true
	},
	{
		name: "who",
		link: "audience",
		startOffset: 10.75,
		offset: 11.5,
		social: true,
		glassPanel: false
	}
];
const PHONE_NAVBAR_ITEMS = [
	{
		name: "Home",
		link: "home",
		startOffset: 0,
		offset: 0,
	},
	{
		name: "Why Tema AI",
		link: "mission",
		startOffset: 1.15,
		offset: 1.25,
	},
	{
		name: "What you get",
		link: "features",
		startOffset: 2.75,
		offset: 3.9,
	},
	{
		name: "How it works",
		link: "technology",
		startOffset: 8.5,
		offset: 8.6,
	},
	{
		name: "Who is it for",
		link: "audience",
		startOffset: 16,
		offset: 18,
		showOnNavBar: false
	}
];


const convertNavbarItemsToObject = (
	navbarItems: NavbarItem[],
	targetKey: keyof NavbarItem
): Record<string, number> => {
	const result: Record<string, number> = {};
	navbarItems.forEach(item => {	
		const key = item.link;
		result[key] = item[targetKey] as number;
	});
	return result;
};


const convertToScrollTargetStart = (items : NavbarItem[]) => {
	return (
		items.sort(
			(a, b) => a.startOffset - b.startOffset
		).map((item, i, all) => ({
			...item,
			name: item.name,
			section: item.link,
			start: item.startOffset,
			end: all[i + 1]?.startOffset || 100,
		}))
	);
};

const mapLinksToSections = (items: NavbarItem[]) => items.map(item => ({...item, link: `/?${SEARCH_PARAM_SECTION}=${item.link}`}));

// Target Scroll position for Navigation Bar section name changes
// Target scroll positions for Navigation Bar items
const HOME = {
	name: "home",
	link: "home",
	startOffset: 0,
};
const SECTION_SCROLL_START = convertToScrollTargetStart([
	{
		...HOME,
		social: true,
		glassPanel: false
	}, 
	...NAVBAR_ITEMS]);
const PHONE_SECTION_SCROLL_START = convertToScrollTargetStart([
	HOME, 
	...PHONE_NAVBAR_ITEMS]);

export default {
	NAVBAR_ITEMS: mapLinksToSections(NAVBAR_ITEMS), 
	SCROLL_TARGET: convertNavbarItemsToObject(NAVBAR_ITEMS, "offset"),
	SECTION_SCROLL_START,
	SEARCH_PARAM_SECTION,
	SEARCH_PARAM_NATURE,
	PHONE_NAVBAR_ITEMS: mapLinksToSections(PHONE_NAVBAR_ITEMS),
	PHONE_SCROLL_TARGET: convertNavbarItemsToObject(PHONE_NAVBAR_ITEMS, "offset"),
	PHONE_SECTION_SCROLL_START
};