import React from "react";
import MarkdownPage from "Components/Reusable/Markdown";

import pressReleaseContent from "./content/pressRelease.txt";
import privacyPolicyContent from "./content/privacyPolicy.txt";

// Tema trade
import temaTradeDocsContent from "./content/temaTrade/docs.txt";
import temaTradeTermsContent from "./content/temaTrade/terms.txt";

// iXBRL 10Ks
import IXBRL10KDocsContent from "./content/ixbrl10ks/docs.txt";
import IXBRL10KTermsContent from "./content/ixbrl10ks/terms.txt";

// US Funds content
import USFundsDocsContent from "./content/usFunds/docs.txt";
import USFundsTermsContent from "./content/usFunds/terms.txt";


const PressReleasePage = <MarkdownPage content={pressReleaseContent} name="press"/>;
const PrivacyPolicyPage = <MarkdownPage content={privacyPolicyContent} name="privacy"/>;
const TemaTradeDocs = <MarkdownPage content={temaTradeDocsContent} name="docs"/>;
const TemaTradeTerms = <MarkdownPage content={temaTradeTermsContent} name="terms"/>;
const IXBRL10KDocs = <MarkdownPage content={IXBRL10KDocsContent} name="docs"/>;
const IXBRL10KTerms = <MarkdownPage content={IXBRL10KTermsContent} name="terms"/>;
const USFundsDocs = <MarkdownPage content={USFundsDocsContent} name="docs"/>;
const USFundsTerms = <MarkdownPage content={USFundsTermsContent} name="terms"/>;


export {
	PressReleasePage,
	PrivacyPolicyPage,
	TemaTradeDocs,
	TemaTradeTerms,
	IXBRL10KDocs,
	IXBRL10KTerms,
	USFundsDocs,
	USFundsTerms
};
