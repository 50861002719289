function truncateFloat(num: number, decimalPlaces: number) {
	const factor = Math.pow(10, decimalPlaces);
	return Math.trunc(num * factor) / factor;
}

class PIDController {
	private kp: number;
	private ki: number;
	private kd: number;
	private precision: number;
	private previousError: number;
	private integral: number;

	constructor(kp: number, ki: number, kd: number, precision = 5) {
		this.kp = kp;
		this.ki = ki;
		this.kd = kd;
		this.previousError = 0;
		this.integral = 0;
		this.precision = precision;
	}

	calculate(setpoint: number, measuredValue: number): number {
		const error = setpoint - measuredValue;
		this.integral += error;
		const derivative = error - this.previousError;
		this.previousError = error;
		const output = this.kp * error + this.ki * this.integral + this.kd * derivative;
		return truncateFloat(output, this.precision);
	}
}

export default PIDController;