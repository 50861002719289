import React from "react";
import { DeltaShare } from "tema-ai-components";
import styled from "styled-components";

import { useAppSelector } from "store/hooks"; // Assuming you have a typed useSelector hook
import { getDeltaShareSilent } from "store/thunks/userThunks"; // Thunk to fetch DeltaShare data
import { updateDeltaShare } from "store/slices/userSlice"; // Action to update DeltaShare data
import { useAuthAppDispatch } from "Components/Reusable/Cognito";

const Connections = styled(DeltaShare.Connections)`
	font-family: "Roboto", sans-serif;
	font-size: 18px;
`;

const Info = styled.p`
	font-size: 14px;
	font-family: "Manrope", sans-serif;
	margin: 0;
	padding: 0px;
`;

const ApiKeyConnections = ({
	loading = false,
} : {
	loading?: boolean;
}) => {
	// Read the connections from the store
	const { deltaShare: { connections, shares } } = useAppSelector((state) => state.user);
	const {
		dispatch,
		// We use the shareAPI instance to make API calls
		contextInstance: { shareAPI: instance },
		headers,
		headerReady,
		refresh
	} = useAuthAppDispatch();

	const reloadConnections = () => {
		if (!headerReady) return;
		dispatch(getDeltaShareSilent({
			instance,
			headers,
		}));
	};

	// Function to delete a connection
	const deltaBase = {loading: false, allowed: true, shares};
	const deleteConnection = async (connectionName: string) => {
		await refresh();
		await instance.request({
			method: "DELETE",
			url: `account/connections/${connectionName}`,
			headers,
		});
		// Manually delete it from the store until the next reload
		dispatch(updateDeltaShare({
			connections: [...connections].filter((c) => c.name !== connectionName),
			...deltaBase
		}));
		// Reload the connections
		reloadConnections();
	};

	// Function to delete a key
	const deleteKey = async (connectionName: string, clientId: string) => {
		/**
		 * Deletes a key from the user's account
		 * @param {string} connectionName - The name of the connection
		 * @param {string} clientId - The client ID of the key
		 */
		await refresh();
		await instance.request({
			method: "DELETE",
			url: `account/connections/${connectionName}/keys/${clientId}`,
			headers,
		});
		// Delete the key in the connection from the store until the next reload
		const newConnections = connections.map(connection => {
			if (connection.name === connectionName) {
				return {
					...connection,
					keys: connection.keys.filter(key => key.clientId !== clientId)
				};
			}
			return connection;
		});
		dispatch(updateDeltaShare({connections: newConnections, ...deltaBase}));
		// Reload the connections
		reloadConnections();
	};

	// Function to add a key
	const addKey = async (connectionName: string) => {
		/**
		 * Adds a new key to the user's account
		 * @param {string} connectionName - The name of the connection
		 */
		await refresh();
		const response = await instance.request({
			method: "POST",
			url: `account/connections/${connectionName}/keys`,
			headers,
		});
		const key = response.data.data.key;
		// Add the key to the connection in the store until the next reload
		const obscureKey = {
			...key, 
			client_secret: key.client_secret.slice(0, 5) + "..."
		};
		const newConnections = connections.map(connection => {
			if (connection.name === connectionName) {
				return {
					...connection,
					keys: [...connection.keys, obscureKey]
				};
			}
			return connection;
		});
		dispatch(updateDeltaShare({connections: newConnections, ...deltaBase}));
		// Reload the connections
		reloadConnections();
		return {
			clientId: key.client_id,
			clientSecret: key.client_secret,
			lastUsedDate: key.last_used_date,
			expirationDate: key.expiration_date,
			host: instance.defaults.baseURL
		};
	};

	const addConnection = async (connectionName: string) => {
		/**
		 * Adds a new connection to the user's account
		 * @param {string} connectionName - The name of the connection
		 */
		await refresh();
		await instance.request({
			method: "POST",
			url: `account/connections/${connectionName}`,
			headers,
		});
		// Add the connection to the store until the next reload
		const newConnections = [
			// @ts-ignore
			...connections,
			{
				name: connectionName.toLowerCase(),
				createdAt: new Date().toISOString(),
				keys: ([] as DeltaShare.KeyType[])
			}
		];
		dispatch(updateDeltaShare({connections: newConnections, ...deltaBase}));
		// Reload the connections
		reloadConnections();
	};
	return (<>
		{(connections.length == 0 && !loading) && <Info>
			You haven&apos;t created any connections yet. You can create a new connection by clicking the button below.
		</Info>}
		<Connections 
			connections={connections} 
			deleteConnection={deleteConnection}
			deleteKey={deleteKey}
			addConnection={addConnection}
			// @ts-ignore
			addKey={addKey}
			loading={loading}
			elementHeight={70}
		/>
	</>
	);
};

export default ApiKeyConnections;

