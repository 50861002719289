import React, { useCallback, useEffect, useMemo, RefObject, useState, useLayoutEffect } from "react";
import * as portals from "react-reverse-portal";
import { useSearchParams } from "react-router-dom";
import constants from "./constants";
import { Parallax } from "tema-ai-components";
import Page, { PageRef } from "Components/Reusable/Page";
import styled from "styled-components";
import { Config } from "Constants";
import { useParticles } from "Context";
import Technology from "./Blocks/Technology";
import Features from "./Blocks/Features";
import Mission from "./Blocks/Mission";
import Home from "./Blocks/Home";
import Audience from "./Blocks/Audience";
import { useGrid } from "Components/Reusable/Grid";
import { animated, useSpring } from "react-spring";
import MainPhone from "./MainPhone";
import OffsetTracker from "./Utils/OffsetTracker";
import useGetSection from "./Utils/useGetSection";


const GlassSidePanel = styled(animated.div)<{ $left: number }>`
	position: absolute;
	height: 100vh;
	transform: translateX(${({ $left }) => $left}px);
	background-color: transparent;
	border: 2px solid transparent;
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(10px); // For Webkit browsers
	-moz-backdrop-filter: blur(10px); // For Firefox (might not be necessary)
`;

const Layers = ({
	parallaxRef,
	reset,
	setSectionName,
	setSelectedItem,
	offset
} : {
	parallaxRef: RefObject<Parallax.IParallax>,
	reset: () => void,
	setSectionName: (section: string) => void,
	setSelectedItem: (item: string) => void,
	offset: RefObject<number>
}) => {
	const { particlesPortal, setProps } = useParticles();
	const { pages, scrollTo } = Parallax.getParallaxContext();
	const { cellWidth, gap, marginX } = useGrid();
	const glassWidth = 4 * (cellWidth + gap);
	const parallaxProps = { offsetRef: offset, parallaxRef };
	const { 
		sectionName, 
		selectedItem, 
		showSocial, 
		showGlassPanel 
	} = useGetSection({ offset, sections: constants.SECTION_SCROLL_START });

	useEffect(() => {
		setSectionName(sectionName);
		setSelectedItem(selectedItem);
	}, [sectionName, selectedItem, setSectionName, setSelectedItem]);

	const { glassPanelWidth } = useSpring({ 
		glassPanelWidth: showGlassPanel ? glassWidth + (marginX as number) : 0, 
		config: { mass: 1, tension: 150, friction: 20 }
	});

	useEffect(() => {
		setProps({
			offset: offset,
			offsetTransform: (o: number) => Math.min(1, o / (pages - 1))
		});
	}, [setProps, offset, pages]);

	const Particles = useMemo(() => {
		return (
			<Parallax.Layer sticky={{ start: 0, end: pages }}>
				<portals.OutPortal node={particlesPortal} />
			</Parallax.Layer>
		);
	}, [parallaxProps, particlesPortal]);
	
	const GlassPanelLayer = useMemo(() => {
		return (
			<Parallax.Layer 
				// @ts-ignore
				className="glass-layer" 
				sticky={{ start: 0, end: pages }} 
				style={{ 
					pointerEvents: "none"  
				}}	
			>
				{/** @ts-ignore */}
				<GlassSidePanel className="glass-side-panel" $left={0} style={{ width: glassPanelWidth }} />
			</Parallax.Layer>
		);
	}, [glassPanelWidth]);

	const content = <>
		{GlassPanelLayer}
		<Home 
			start={0} 
			end={1} 
			overflow={0.2} 
			showSocial={showSocial} 
			showCornerButton={showSocial}
			offset={offset}
		/>
		<Mission start={1} end={3} toContact={() => scrollTo(pages, 0)}/>
		<Features start={3} end={5.5}/>
		<Technology  start={5.75}  end={11} offsetRef={offset} parallaxRef={parallaxRef}/>
		<Audience 
			start={11} 
			end={pages} 
			showSocial={showSocial} 
			reset={reset}
		/>
	</>;
	const blocks = useMemo(() => {
		return (<>
			{Particles}
			{ content }
		</>);
	}, [showSocial, scrollTo, offset, parallaxRef, reset, Particles, GlassPanelLayer]);

	const showOffset = false;
	return (		
		<>
			{showOffset && <OffsetTracker pages={pages} offset={offset} />}
			{ blocks }
		</>
	);
};

const Main = () => {
	// check if we need to scroll to a specific section
	const [searchParams] = useSearchParams();
	const { parallaxRef, offset } = Parallax.useParallax();
	const [sectionName, setSectionName] = useState<string>("home");
	const [selectedItem, setSelectedItem] = useState<string>(null);
	const { config } = Config;
	const pages = 13;
	const pageRef = React.useRef<PageRef>(null);

	useLayoutEffect(() => {
		// check if there is a section to scroll to
		pageRef.current?.freezeNavBar();
		pageRef.current?.reBuildKey();
		const section = searchParams.get(constants.SEARCH_PARAM_SECTION);
		if (section && parallaxRef.current) {
			// scroll to the section
			const pos = constants.SCROLL_TARGET[section];
			parallaxRef.current?.scrollTo(pos, 0);
		}
		const time = setTimeout(() => {
			pageRef.current?.unfreezeNavBar();
		}, 1000);
		return () => clearTimeout(time);
	}, [parallaxRef, searchParams]);
	
	const reset = useCallback(() => {
		parallaxRef.current?.scrollTo(0, 0);
		pageRef.current?.freezeNavBar();
		const time = setTimeout(() => {
			pageRef.current?.unfreezeNavBar();
		}, 1000);
		return () => clearTimeout(time);
	}, [
		parallaxRef, 
		pageRef, 
		constants.PHONE_SECTION_SCROLL_START[0], 
	]);

	return (
		<Page 
			ref={pageRef} 
			navBarItems={constants.NAVBAR_ITEMS}
			selectedItem={selectedItem} 
			sectionName={sectionName}
			hideNavBarAfter={Infinity}
			homeLink={`/?${constants.SEARCH_PARAM_SECTION}=home`}
			ctaLink={`/?${constants.SEARCH_PARAM_SECTION}=audience&${constants.SEARCH_PARAM_NATURE}=general`}
		>
			<Parallax.Container 
				className="parallax_container_main" 
				ref={parallaxRef} 
				pages={pages}
				heightDebounceValue={config.screenDebouncer.debounceValue}
				heightDebounceTime={config.screenDebouncer.debounceTime}
				duration={config.screenDebouncer.duration}
			>
				<Layers 
					parallaxRef={parallaxRef} 
					reset={reset} 
					setSectionName={setSectionName}
					setSelectedItem={setSelectedItem}
					offset={offset}
				/>
			</Parallax.Container>
		</Page>
	);
};

const MainPage = () => {
	const { isPhone } = useGrid();
	const component = useMemo(() => {
		return isPhone ? <MainPhone /> : <Main />;
	}, [isPhone]);
	return component;
};

export default MainPage;