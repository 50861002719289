/* eslint-disable react/no-unknown-property */
// @ts-nocheck/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/react-in-jsx-scope */
import React, { RefObject, useState } from "react";
import { Html } from "@react-three/drei";
import * as THREE from "three";
import { motion, AnimatePresence } from "framer-motion";

import { useThree } from "@react-three/fiber";
import { Stage, useFrameLimiter } from "./AdvancedFiberParticles";
import { latLngToXYZ } from "./AdvancedFiberParticles/utils";

const AnimatedDiv = motion.div;

// @ts-ignore
function Annotation({ 
	p, 
	offset,
	stages, 
	offsetTransform,  
	...props 
}: { 
	p: [number, number], 
	offset: RefObject<number>,
	stages: Stage[],
	offsetTransform: (o: number) => number
}) {
	const { gl } = useThree();
	const [ show, setShow ] = useState(false); 
	const [x, y] = [(p[0]) * Math.PI / 180, p[1] * Math.PI / 180];
	const xyz = latLngToXYZ(x, y, 3.51);
	const position = new THREE.Vector3(...xyz);
	// remove n from the z position
	position.y = position.y - 0;
	const normalized = new THREE.Vector3(...xyz).normalize();
	
	// Default plane normal vector (facing z-axis)
	const normal = new THREE.Vector3(0, 0, 1);

	// Calculate the rotation axis (cross product)
	const rotationAxis = new THREE.Vector3().crossVectors(normal, normalized).normalize();

	// Calculate the rotation angle (dot product)
	const rotationAngle = Math.acos(normal.dot(normalized));

	// Create a quaternion to represent the rotation
	const quaternion = new THREE.Quaternion().setFromAxisAngle(rotationAxis, rotationAngle);

	// Convert quaternion to Euler angles
	const rotation = new THREE.Euler().setFromQuaternion(quaternion);

	useFrameLimiter(() => {
		// find the current stage 
		for (let i = 0 ; i < stages.length; i++) {
			const stage = stages[i];
			const startScroll = stage.endScroll;
			const endScroll = stages[i + 1]?.endScroll || 1;
			const co = offsetTransform(offset.current);
			if (co >= startScroll && co < endScroll) {
				setShow(stage.icons || false);
			}
		}		
	});
	return (
		<mesh position={position} rotation={rotation}>
			<Html
				{...props}
				distanceFactor={10}
				castShadow={false}
				//transform
				prepend={false}
				occlude//="blending"
				/*@ts-ignore*/
				portal={{ current: gl.domElement.parentNode }}
				scale={0.33}
				style={{
					transform: `translate(-50%, -50%)` 
				}}
			>
				<AnimatePresence>
					{show && <AnimatedDiv 
						initial={{ scale: 0 }}
						animate={{ scale: 1 }}
						exit={{ scale: 0 }}
						transition={{ duration: 1}}
						style={{
							width: "30px", 
							height: "30px", 
							borderRadius: "50%",
							backgroundColor: "transparent",
							border: "1px solid #CDFD50",
							backdropFilter: "blur(10px)",
							WebkitBackdropFilter: "blur(10px)",
							zIndex: 1000,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							transition: "all 0.5s ease",
							transform: "scale(3)"
						}}
					>
						<div style={{
							color: "#CDFD50", 
							fontSize: "1.5rem",
							margin: 0,
							padding: 0
						}}>!</div>
					</AnimatedDiv>}
				</AnimatePresence>
			</Html>
		</mesh>
		
	);
}

const RandomAnnotations = ({ 
	story, 
	offset, 
	offsetTransform, 
	length 
}: { 
	story: { stages: Stage[] }, 
	offset: RefObject<number>, 
	offsetTransform: (o: number) => number, 
	length: number 
}) => {
	return <>
		{Array.from({ length }).map((_, i) => {
			const p = [-70 + Math.random() * 140, -180 + Math.random() * 360] as [number, number];
			return (
				<Annotation stages={story.stages} key={i} p={p} offset={offset} offsetTransform={offsetTransform}/>
			);
		})}
	</>;
};

export default Annotation;
export { RandomAnnotations };