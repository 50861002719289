import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { Parallax } from "tema-ai-components";
import { AUDIENCE } from "Components/Pages/Main/copy";
import { ContactForm } from "Components/Reusable/ContactUs";
import Social from "./Social";
import Grid, { device, useGrid } from "Components/Reusable/Grid";
import CornerButton from "./CornerButton";
import { BasicFooter } from "Components/Reusable/Footer";
import constants from "../constants";
import { TitleCSS, SectionTextCSS } from "Styles";
import { MobileSection } from "../Utils";

const Text = styled.div`
	${ TitleCSS }
	grid-column: 1/ span 7;
	grid-row: 2 / span 2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
	letter-spacing: -1px;
    div {
        margin: 0;
	}

	@media ${device.laptopS} {
		grid-column: 1/ span 5;
	}

	@media ${device.tablet} {
		grid-column: 1 / span end;
		grid-row: 2 / span 3;
	}
`;

const LetsTalkContainer = styled(Text)`
	${ SectionTextCSS }
	justify-content: flex-end;
	grid-column: 1/ span 4;
	grid-row: 5 / span 1;	
    align-items: flex-start;
	@media ${device.tablet} {
		display: none;
	}
`;

type ContactOption = {
	value: string;
	label: string;
};
const DEFAULT_CONTACT_OPTION: ContactOption = { value: "general", label: "General enquiry"};
const CONTACT_OPTIONS: ContactOption[] = [
	//{ value: "demo", label: "Schedule demo" },
	{ value: "press", label: "Press enquiry"},
	DEFAULT_CONTACT_OPTION
];

const FormContainer = styled.div`
    grid-column: 9 / span end;
	grid-row: 2 / span 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

	@media ${device.laptopS} {
		grid-column: 7 / span end;
	}

	@media ${device.tablet} {
		justify-content: flex-end;
		grid-column: 1 / span end;
		grid-row: 5 / span end;
		padding-top: 40px;
	}
`;

const Links = ({ 
	showSocial, 
	reset,
	style
}: { 
	showSocial?: boolean, 
	reset?: (to: number) => void,
	style?: object
}) => {
	return (
		<>
			<Social show={showSocial} tight style={style}/>
			<CornerButton text="Go back" angle={360} onClick={() => reset(0)} style={style}/>
			<BasicFooter/>
		</>
	);
};

const Audience = ({
	start, 
	end, 
	showSocial,
	reset
} : {
	start: number, 
	end: number, 
	showSocial?: boolean,
	reset?: (to: number) => void
}) => {
	const [searchParams] = useSearchParams();
	const [nature, setNature] = useState<ContactOption>(null);
	const { isPhone } = useGrid();
	const links = useMemo(() => <Links showSocial={showSocial} reset={reset}/>, [showSocial, reset]);
	useEffect(() => {
		const paramNature = searchParams.get(constants.SEARCH_PARAM_NATURE);
		if (paramNature) {
			// find the nature from the options
			const found = CONTACT_OPTIONS.find(option => option.value === paramNature);
			setNature(found);
		}
	}, [searchParams]);
	const comp = useMemo(() => { 
		return (
			<><Text>
				<div>
					{AUDIENCE.title}
				</div>
			</Text><LetsTalkContainer>
				<div>
					<div>{AUDIENCE.subtitle}</div>
				</div>
			</LetsTalkContainer><FormContainer>
				<ContactForm
					nature={nature}
					dropDownOptions={CONTACT_OPTIONS}
					mobile={isPhone} />
			</FormContainer>
			{!isPhone && links}
			</>
		);
	}, [isPhone, nature, links]);
	return (
		<Parallax.Layer
			sticky={{ start, end}}
		>	
			{ isPhone && <MobileSection text={ AUDIENCE.subtitle }/>}
			<Grid>
				{ comp }
			</Grid>
		</Parallax.Layer>
	);
};

export default Audience;