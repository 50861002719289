import { css } from "styled-components";
import { device } from "Components/Reusable/Grid";

const SectionTextCSS = css`
    font-size: 72px;
	line-height: 72px;
    font-family: Inter;
    font-weight: 200;
    color: ${({ theme }) => theme.textColor};
    opacity: 0.24;
	// no wrapping
	white-space: nowrap;
    // no user select 
    user-select: none;
    p {
		margin: 0;
		padding: 0;
	}

    @media ${device.laptopM} {
		font-size: 62px;
		line-height: 62px;
	}
`;

const MainTitleCSS = css`
	color: ${({ theme }) => theme.textColor};
	font-family: Inter;
	font-size: 72px;
	font-style: normal;
	font-weight: 200;
	line-height: 82px; /* 123.457% */
    @media ${device.laptopL} {
		font-size: 64px;
		line-height: 68px;
	}
	@media ${device.laptopM} {
		font-size: 62px;
		line-height: 72px;
	}
	@media ${device.laptopS} {
		font-size: 58px;
		line-height: 62px;
	}
	@media ${device.tabletL} {
		font-size: 46px;
		line-height: 50px;
	}
	@media ${device.tabletM} {
		font-size: 44px;
		line-height: 54px;
	}
	@media ${device.tabletS} {
		font-size: 46px;
		line-height: 56px;
	}
	@media ${device.mobileL} {
		font-size: 46px;
		line-height: 52px;
	}
	@media ${device.mobileM} {
		font-size: 44px;
		line-height: 48px;
	}
`;

const SubTitleCSS = css`
	color: ${({ theme }) => theme.textColorLight};
    font-size: 18px;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    line-height: 24px;

	@media ${device.laptopL} {
		font-size: 18px;
		line-height: 22px;
	}
    @media ${device.laptopM} {
		font-size: 16px;
		line-height: 22px;
	}
	@media ${device.laptop} {
		font-size: 14px;
		line-height: 22px;
	}
	@media ${device.tabletL} {
		font-size: 14px;
		line-height: 18px;
	}
	@media ${device.tabletS} {
		font-size: 16px;
		line-height: 23px;
		font-family: 'Inter', sans-serif;
		font-weight: 300;
	}
	@media ${device.phoneL} {
		font-weight: 500;
	}
`;

const TitleCSS = css`
    font-size: 42px;
	line-height: 48px;
	font-family: 'Inter', sans-serif;
	font-weight: 200;
    letter-spacing: -1px;
    color: ${({ theme }) => theme.textColor};
    .faded {
        color: 	${({ theme }) => theme.textColorLight};
    }
    @media ${device.laptopM} {
		font-size: 34px;
		line-height: 40px;
	}
	@media ${device.laptop} {
		font-weight: 300;
		font-size: 30px;
		line-height: 34px;
	}
	@media ${device.mobileL} {
		font-size: 32px;
		line-height: 38px;
	}
	@media ${device.mobileM} {
		font-size: 32px;
		line-height: 38px;
	}
`;

const SupraTitleCSS = css`
	${TitleCSS}
	font-size: 48px;
	line-height: 52px;
	@media ${device.laptopL} {
		font-size: 44px;
		line-height: 48px;
	}
	@media ${device.laptop} {
		font-size: 30px;
		line-height: 34px;
	}
`;

const CardTitle = css`
	font-size: 20px;
	line-height: 24px;
	font-weight: 500;
	font-family: 'Manrope', sans-serif;
	letter-spacing: 0.02em;
	.highlighted {
		color: ${({ theme }) => theme.accentColor};
	}
	@media ${device.laptopL} {
		font-size: 16px;
		line-height: 22px;
	}
	@media ${device.laptopM} {
		font-size: 16px;
		line-height: 22px;
	}
	@media ${device.laptopS} {
		font-size: 14px;
		line-height: 20px;
	}
	@media ${device.mobileL} {
		font-size: 18px;
		line-height: 24px;
	}
`;

const ContentCSS = css`
    font-size: 16px;
	font-weight: 500;
	font-family: 'Manrope', sans-serif;
    line-height: 28px;
	letter-spacing: 2%;
	color: ${({ theme }) => theme.textColorLight};
    
	@media ${device.laptopM} {
		font-size: 13px;
		line-height: 24px;
	}
	@media ${device.mobileL} {
		font-size: 16px;
		line-height: 24px;
	}
	@media ${device.mobileM} {
		font-size: 16px;
		line-height: 28px;
	}

	@media screen and (max-height: 750px),
	screen and (max-width: 1000px) {
		font-size: 16px;
	}
	@media screen and (max-height: 768px),
		screen and (max-width: 1280px) {
		font-size: 14px;
		line-height: 20px;
	}
`;

const EyeBrowseCSS = css`
	font-size: 12px;
	font-family: 'Manrope', sans-serif;
	font-weight: 700;
	@media ${device.laptopL} {
		font-size: 11px;
	}
	@media ${device.laptopS} {
		font-size: 10px;
	}
`;

export { 
	MainTitleCSS,
	SubTitleCSS,
	TitleCSS,
	ContentCSS,
	SectionTextCSS,
	EyeBrowseCSS,
	CardTitle,
	SupraTitleCSS
};