import React, { RefObject } from "react";
import { Parallax } from "tema-ai-components";
import Grid, { size, useGrid } from "Components/Reusable/Grid";
import RegisterForm from "Components/Reusable/RegisterUser";
import { HOME } from "Components/Pages/Main/copy";
import Footer from "./Footer"; // Import the new component
import {
	TitleGridItem,
	StyledTitle,
	SubTitleGridItem,
	RegisterGridItem
} from "./styles";

// Title component
const Title = () => {
	return (
		<TitleGridItem>
			<StyledTitle>
				{HOME.title}
			</StyledTitle>
		</TitleGridItem>
	);
};

// Main Home component
const Home = ({
	start,
	end,
	overflow = 0,
	showSocial = true,
	offset,
	showCornerButton = true
}: {
    start: number,
    end: number,
    overflow?: number,
    showSocial?: boolean,
	offset?: RefObject<number>
    showCornerButton?: boolean
}) => {
	const { gap, width } = useGrid();
	const { scrollTo } = Parallax.getParallaxContext();

	const Scroll = () => {
		console.log("Scrolling to next section");
		scrollTo(2, 0);
	};

	return (
		<>
			<Parallax.Layer className="home__first__layer" sticky={{ start, end }}>
				<Grid>
					<Title />
				</Grid>
			</Parallax.Layer>
			<Parallax.Layer className="home__second__layer" sticky={{ start, end: end * (1 + overflow) }}>
				<Grid>
					<SubTitleGridItem style={{ gap }}>
						<div className="text">{HOME.subtitle}</div>
					</SubTitleGridItem>
					<RegisterGridItem $mobile={false}>
						<div style={{ height: width < size.tablet ? "85px" : "40px" }}>
							<RegisterForm mobile={width < size.tablet} />
						</div>
					</RegisterGridItem>
					<Footer 
						showSocial={showSocial} 
						showCornerButton={showCornerButton}
						onClickCornerButton={Scroll}
					/>
				</Grid>
			</Parallax.Layer>
		</>
	);
};

export default Home;