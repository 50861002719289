import React, { useMemo } from "react";
import styled from "styled-components";
import { Buttons } from "tema-ai-components";
import { AnimatePresence } from "framer-motion";
import { EyeBrowseCSS } from "Styles";
import { useTheme } from "Context";
import { device } from "Components/Reusable/Grid";

const BUTTON_HEIGHT = 40;

const GoBack = styled.div`
	grid-column: -3 / span 2;
	grid-row: 6 / span 1;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	.buttonSpace {
		height: ${BUTTON_HEIGHT}px;
	}
	@media ${device.tablet} {
		grid-row: 12 / span 1;
	}
`;

const StyledButton = styled(Buttons.ArrowButton)`
	p {
		${ EyeBrowseCSS }
	}
`;

const Button = ({
	text, 
	angle, 
	onClick,
	show=true,
	style
}: {
    text: string,
    angle: number,
    onClick?: () => void,
	show?: boolean,
	style?: object
}) => {
	const { theme } = useTheme();
	const Button = useMemo(() => {
		return (<GoBack style={style}>
			<div className="buttonSpace"> 
				<AnimatePresence>
					{show && <StyledButton
						text={text}
						highlightColor={theme.accentColor}
						duration={2}
						buttonWidth={140}
						height={BUTTON_HEIGHT}
						backgroundColor="rgba(255, 255, 255, 0.08)"
						textColor={theme.textColorLight}
						borderColor={theme.textColorSuperLight}
						angle={angle}
						// @ts-ignore
						onClick={onClick}
						mergeMotion={false}
						exit={{
							width: [100, 40, 40],
							scale: [1, 1, 0],
						}}
					/>}
				</AnimatePresence>
			</div>
		</GoBack>);
	}, [theme, onClick, show]);
	return Button;
};

export default Button;