import { Stage } from "./AdvancedFiberParticles"; // Import the custom hook

const getCurrentStageIndex = (stages: Stage[], offset: number, offsetTransform: (o: number) => number) => {
	for (let i = 0; i < stages.length; i++) {
		const stage = stages[i];
		const startScroll = stage.endScroll;
		const endScroll = stages[i + 1]?.endScroll || 1;
		const co = offsetTransform(offset);
		if (co >= startScroll && co < endScroll) {
			return i;
		}
	}
	return -1;
};


function shuffleArray(array: any[]): any[] {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}

function sortWithSubset(
	firstList: unknown[],
	secondList: unknown[],
	a: number,
	b: number
): unknown[] {
	// Create a copy of the first list
	const sortedList = [...firstList];

	// Shuffle the second list
	const shuffledSecondList = shuffleArray([...secondList]);

	// Extract elements from the first list that are not in the second list
	const remainingElements = sortedList.filter(
		(item) => !secondList.includes(item)
	);

	// Randomly insert shuffled elements of the second list between positions a and b
	shuffledSecondList.forEach((item) => {
		const position = Math.floor(Math.random() * (b - a + 1)) + a;
		remainingElements.splice(position, 0, item);
	});

	return remainingElements;
}


export {
	getCurrentStageIndex,
	sortWithSubset
};