import "immer";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "store/slices/userSlice";

// Explicitly define the shape of the state and the store
const rootReducer = {
	user: userReducer,
};
  
export const store = configureStore({
	reducer: rootReducer,
});
  
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;