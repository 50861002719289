import styled from "styled-components";
import Markdown from "react-markdown";

const MarkDownStyle = styled(Markdown)`
    section.footnotes {
      margin-top: 50px;
      border-top: 1px solid grey;
   
      h2 {
       display: none
      }
      ol {
        font-size: 12px;
        margin-left: 10px;
        padding-left: 10px;
      }
    }
`;

export { MarkDownStyle };