import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import Grid, { useGrid } from "Components/Reusable/Grid";
import  { Fixed, Content, PageContainer, TopBlock, BottomBlock } from "./styles";
import useNavbar, { NavBarItemType } from "./NavBar";

type PageProps = {
	fixedChildren?: React.ReactNode,
	children?: React.ReactNode,
	hideNavBarAfter?: number,
	opaqueNavBar?: boolean
	simple?: boolean,
	navBarItems?: NavBarItemType[],
	selectedItem?: string,
	sectionName?: string,
	homeLink?: string;
	ctaLink?: string;
	limitHeight?: boolean;
	navBarHeight?: number;
};

type PageRef = {
	displayNavBar: () => void;
	hideNavBar: () => void;
	freezeNavBar: () => void;
	unfreezeNavBar: () => void;
	reBuildKey: () => void;
};

const Page = forwardRef<PageRef, PageProps>(({ 
	fixedChildren, 
	children, 
	hideNavBarAfter=850,
	simple=false,
	navBarItems,
	selectedItem="",
	sectionName="",
	homeLink="/?",
	ctaLink,
	limitHeight=true,
	navBarHeight=80
}, ref) => {
	// This key is necessary to re-scroll inside each section
	const [key, setKey] = React.useState<string>("");
	const { marginX, paddingY, totalGridWidth } = useGrid();
	const items = useMemo(() => {
		return navBarItems.map((item) => {
			// if the item link starts with /?section=
			const sectionLink = item.link.startsWith("/?section=");
			// generate a random key of 20 characters
			return {...item, link: sectionLink ? `${item.link}&token=${key}`: item.link};
		});
	}, [key]);
	const {
		nav,
		displayNavBar,
		hideNavBar,
		freezeNavBar,
		unfreezeNavBar,
	} = useNavbar({
		hideNavBarAfter,
		items: simple ? [] : items,
		selectedItem,
		sectionName,
		homeLink: key == "" ? homeLink : `${homeLink}&token=${key}`,
		ctaLink: key == "" ? ctaLink : `${ctaLink}&token=${key}`,
		navBarHeight: navBarHeight
	});

	const reBuildKey = () => {
		const token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		setKey(token);
	};

	useImperativeHandle(ref, () => ({ 
		displayNavBar,
		hideNavBar,
		freezeNavBar,
		unfreezeNavBar,
		reBuildKey
	}));
	
	const $marginX = typeof marginX === "number" ? `${marginX}px` : marginX;
	const $marginY = typeof paddingY === "number" ? `${paddingY}px` : paddingY;

	const memoizedChildren = useMemo(() => children, [children]);
	const memoizedFixedChildren = useMemo(() => fixedChildren, [fixedChildren]);

	return (
		<PageContainer className="page">
			<Fixed 
				className="page__fixed" 
				$marginX={$marginX} 
				$marginY={limitHeight ? $marginY : "0px"} 
				$width={totalGridWidth}
			>
				{nav}
				<div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
					<Grid style={{ pointerEvents: "none"}}>
						{ memoizedFixedChildren }
					</Grid>
				</div>
			</Fixed>
			<Content className="page__content"> 
				{ memoizedChildren } 
			</Content>
			<TopBlock $height={limitHeight ? $marginY : "0px"}/>
			<BottomBlock $height={limitHeight ? $marginY : "0px"}/>
		</PageContainer>
	);
});
Page.displayName = "Page";

export default Page;
export type {
	PageRef,
	PageProps
};