import React, {
	useState,
	useRef,
	createContext,
	useContext,
	useMemo,
	useEffect
} from "react";
import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";

// Define the type of the context to help with TypeScript's type checking
type AxiosContextType = Record<string, AxiosInstance | undefined>;
const AxiosContext = createContext<AxiosContextType>(null);

type AxiosConfigInstance = { baseURL: string };
type AxiosConfig = Record<string, AxiosConfigInstance>;

const createAxiosInstances = (config: AxiosConfig) => {
	const instances: Record<string, AxiosInstance> = {} as Record<string, AxiosInstance>;

	(Object.keys(config) as Array<string>).forEach(key => {
		instances[key] = axios.create(config[key]);
	});

	return instances;
};

type AxiosInstanceProvideProps = {
	config: AxiosConfig;
    requestInterceptors: [];
	responseInterceptors: [];
	children: React.ReactNode;
  };

const AxiosInstanceProvider = ({
	config = {},
	requestInterceptors = [],
	responseInterceptors = [],
	children,
}: AxiosInstanceProvideProps) => {
	const instanceRef = useRef(createAxiosInstances(config));
	useEffect(() => {
		if (!instanceRef.current) return;
		const configs = Object.keys(instanceRef.current);
		requestInterceptors.forEach(interceptor => {
			configs.forEach(config => {
				instanceRef.current[config].interceptors.request.use(interceptor);
			});
		});
		responseInterceptors.forEach(interceptor => {
			configs.forEach(config => {
				instanceRef.current[config].interceptors.response.use(interceptor);
			});
		});
	}, [instanceRef]);
	return <AxiosContext.Provider value={instanceRef?.current}>{children}</AxiosContext.Provider>;
};

const useAxios = <T, P>(
	url: string, 
	method: string, 
	payload: P, 
	headers: AxiosRequestHeaders, 
	trigger=true,
	api="default"
) => {
	const [data, setData] = useState<T>();
	const [error, setError] = useState("");
	const [loaded, setLoaded] = useState(false);
	const contextInstance = useContext(AxiosContext);
	const instance = useMemo(() => {
		return contextInstance[api] || axios;
	}, [contextInstance]);
	const controllerRef = useRef(new AbortController());
	const cancel = () => controllerRef.current.abort();
	useEffect(() => {
		if (!trigger) return;
		(async () => {
			try {
				const response = await instance.request({
					data: payload,
					signal: controllerRef.current.signal,
					method,
					headers,
					url
				});
				setData(response.data);
			} catch (error) {
				setError(error.message);
			} finally {
				setLoaded(true);
			}
		})();
	}, [trigger]);

	return { cancel, data, error, loaded };
};

export { AxiosInstanceProvider, AxiosContext };
export default useAxios;