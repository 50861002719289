import React from "react";
import styled from "styled-components";
import { Authenticator, Heading, useTheme, View, Button, useAuthenticator, Text} from "@aws-amplify/ui-react";
import { Buttons } from "tema-ai-components";


const Beta = styled.div`
	width: 120px;
	height: 34px;
	backdrop-filter: blur(15px);
	cursor: pointer !important;
	button:disabled {
		cursor: pointer !important;
	}
`;

const Components = {
	Header() {
		const { tokens } = useTheme();
  
		return (
			<Heading
				padding={`${tokens.space.xl} 0 0 32px`}
				level={3} 
			>
				<Beta>
					<Buttons.BlurButton 
						text="Beta"
						disabled
					/>
				</Beta>
			</Heading>
		);
	},
	SignIn: {
		Footer() {
			// @ts-ignore
			const { toForgotPassword } = useAuthenticator();
			return (
				<View textAlign="center">
					<Button 
						fontWeight="normal" 
						onClick={toForgotPassword} 
						size="small"
						variation="link" 
					>
						Did you forget your password?
					</Button>
				</View>
			);
		},
	},
	ForceNewPassword: {
		Header() {
			return (
				<Text>
					We need you to change your temporary password
				</Text>
			);
		},
		FormFields() {
			return <Authenticator.ForceNewPassword.FormFields />;
		}
	},
	ForgotPassword: {
		Header() {
			return (
				<Text>
					Please provide your email and we will send you a one-use code to reset your password
				</Text>
			);
		}
	},
	ConfirmResetPassword: {
		Header() {
			return (
				<Text>
					We have sent you an email with a one-use code to reset your password
				</Text>
			);
		}
	},
	
};

export default Components;