import React, { useRef, useState, useEffect } from "react";
import { Forms, Utils } from "tema-ai-components";
import constants from "./constants";
import { StyledForm } from "./styles";
import { useTheme } from "Context";

const DEFAULT_ERROR_TEXT = "There was an error. Please try again";
const RESET_TIMEOUT = 5000; // 5 seconds

type ContactUsAPIType = {
	email: string,
	name: string,
	message: string,
	registerForUpdates: boolean,
	natureOfRequest: string
};

const ContactFormState = ({
	timeout = RESET_TIMEOUT,
	defaultErrorText = DEFAULT_ERROR_TEXT
}:{
	timeout?: number,
	defaultErrorText?: string
}) => {
	const [errorText, setErrorText] = useState(defaultErrorText);
	const formRef = useRef<Forms.ContactFormRefMethods>(null);
	const { submit, reqStatus, hasFailed, reset } = Utils.submitWithRecaptcha<ContactUsAPIType>({
		endpoint: constants.CONTACT_US_URL,
		recaptchaAction: constants.reCAPTCHA_ACTION,
	});
	const onSubmit = async (data: Forms.ContactFormData) => {
		submit({
			email: data.email,
			name: data.name,
			message: data.message,
			registerForUpdates: data.updates,
			natureOfRequest: data.nature.value
		});
	};

	useEffect(() => {
		// auto reset after 5 seconds if succeeded
		if (reqStatus.succeeded) {
			const wait = setTimeout(() => {
				formRef.current?.reset();
				reset();
			}, RESET_TIMEOUT);
			return () => {
				clearTimeout(wait);
			};
		}
	}, [reqStatus]);

	useEffect(() => {
		// auto reset after 5 seconds if failed
		if (hasFailed) {
			let i = timeout / 1000;
			setErrorText(`${defaultErrorText} in ${i}s`);
			const myInterval = setInterval(() => {
				i -= 1;
				setErrorText(`${defaultErrorText} in ${i}s`);
			}, 1000);
			const time = setTimeout(() => {
				reset();
				formRef.current?.resetSimple();
			}, timeout);
			return () => {
				clearInterval(myInterval);
				clearTimeout(time);
			};
		}
	}, [hasFailed]);
	return { formRef, onSubmit, reqStatus, hasFailed, errorText };
};

const ContactForm = ({
	nature,
	dropDownOptions,
	mobile=false
} : {
	nature?: Forms.ContactFormNatureOption,
	dropDownOptions: Forms.ContactFormNatureOption[],
	mobile?: boolean
}) => {
	const { theme } = useTheme();
	const { formRef, onSubmit, reqStatus, hasFailed, errorText } = ContactFormState({
		timeout: RESET_TIMEOUT,
		defaultErrorText: DEFAULT_ERROR_TEXT
	});
	useEffect(() => formRef.current?.reset(), [nature]);
	return (
		<StyledForm autoComplete="on" $mobile={mobile}>
			<Forms.ContactForm 
				ref={formRef}
				onSubmit={onSubmit} 
				defaultData={{updates: false, nature}}
				minMessageLen={25}
				namePlaceholder="Name"
				emailPlaceholder="Email"
				dropDownPlaceholder="Nature of request"
				messagePlaceholder="Add Message"
				submitText="Submit"
				consentText="Updates"
				consentSubtext={null}
				//consentSubtext="No marketing fluff. Just regular roundups of the news you need to navigate the climate economy. Read it first."
				submittingText="Submitting..."
				successText="Thank you!"
				errorText={errorText}
				nameErrorMessage="Please provide a name"
				emailErrorMessage="Please provide a valid email"
				dropDownOptions={dropDownOptions}
				hasFailed={hasFailed}
				loading={reqStatus.loading}
				succeeded={reqStatus.succeeded}	
				skeumorph={false}
				errorColor="#fb0066"
				accentColor={theme.accentColor}
			/>
		</StyledForm>
	);
};


export { ContactForm };