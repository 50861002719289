/* eslint-disable react/no-unknown-property */
import React, { useRef, useMemo, RefObject } from "react";
import useParticlesTexture from "./useParticlesTexture";
import { extend, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { geometry } from "maath";
//@ts-ignore
import Spark from "./spark1.png";
import { useFrameLimiter } from "../FrameLimiter"; // Import the custom hook

import { useFBO } from "@react-three/drei";

import * as THREE from "three";

import fragmentShader from "./fragmentShader";
import vertexShader from "./vertexShader";
import PIDController from "./pid";

import {
	MethodType,
	RotateMethod,
	PointMethod,
	CoordinatesType,
	rotateType,
	pointType,
} from "./types";

extend(geometry);

const TWO_PI = 2 * Math.PI;

const ParticlesTexture = ({
	size,
	stateTransitions,
	particleStates,
	colorTransitions,
	particleColors,
	fadeToColor,
	offset,
	baseColor,
	highlightFactor = 5,
	baseHighlightFactor = 5,
	particleSize = 15,
	offsetTransform = (o: number) => o,
	focus,
	interval=3,
	groupRef
}: {
	size: number;
	stateTransitions: number[];
	particleStates:  { particles: Float32Array; id: string; }[];
	colorTransitions: number[];
	particleColors:  { particles: Float32Array; id: string; }[];
	fadeToColor: string | number;
	offset: RefObject<number>;
	baseColor: number | string;
	highlightFactor: number;
	baseHighlightFactor?: number,
	particleSize: number;
	offsetTransform?: (o: number) => number;
	focus: CoordinatesType[];
	interval?: number;
	groupRef?: RefObject<THREE.Mesh>;
}) => {
	const points = useRef<THREE.Points>();
	const numRotationsRef = useRef<number>(undefined);
	const { ParticleTexture, simulationMaterialRef, colorMaterialRef, colorScene, materialScene } = useParticlesTexture(
		size,
		stateTransitions,
		particleStates,
		colorTransitions,
		particleColors,
	);

	const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1);

	const renderTargetPosition = useFBO(size, size, {
		minFilter: THREE.NearestFilter,
		magFilter: THREE.NearestFilter,
		format: THREE.RGBAFormat,
		stencilBuffer: false,
		type: THREE.FloatType,
	});

	const renderTargetColor = useFBO(size, size, {
		minFilter: THREE.NearestFilter,
		magFilter: THREE.NearestFilter,
		format: THREE.RGBAFormat,
		stencilBuffer: false,
		type: THREE.FloatType,
	});

	const particlesPosition = useMemo(() => {
		const length = size * size;
		const particles = new Float32Array(length * 3);
		for (let i = 0; i < length; i++) {
			const i3 = i * 3;
			particles[i3 + 0] = (i % size) / size;
			particles[i3 + 1] = i / size / size;
		}
		return particles;
	}, [size]);
	const pointTexture = useLoader(TextureLoader, Spark);

	const uniforms = useMemo(
		() => ({
			uPositions: { value: null },
			uColor: { value: null },
			uTime: { value: 0 },
			pointTexture: { value: pointTexture },
			highlightFactor: { value: baseHighlightFactor },
			toHighlight: { value: 2.0 },
		}),
		[],
	);

	const latToPolar = (lat: number) => (Math.PI/2) - (Math.PI * lat / 180);
	const lngToAzimuthal = (lng: number) =>  Math.PI * lng / 180;
	const getSpeedFactor = (az: number) => {
		/**
		 * Calculates a speed factor based on the azimuthal angle (az).
		 * The speed factor increases for angles corresponding to either the Pacific or the Atlantic region,
		 * following a sine wave pattern.
		 * 
		 * @param {number} az - Azimuthal angle in radians.
		 * @returns {number} The speed factor, which is 1 outside the Pacific and Atlantic regions,
		 *                   and varies between 1 and 21 within these regions.
		 */
		
		const actualAz = Math.abs(az) % (2 * Math.PI);
		// Define the start and end angles for the Pacific and Atlantic regions
		const regions = [
			{ start: 0.8 * Math.PI, end: 1.4 * Math.PI }, // Pacific
			{ start: 1.55 * Math.PI, end: 1.95 * Math.PI } // Atlantic
		];
	
		// Check each region to determine if the angle falls within its range
		for (const { start, end } of regions) {
			if (actualAz > start && actualAz < end) {
				const normalizedAz = (actualAz - start) / (end - start) * Math.PI; // Normalize to [0, π]
				return 1 + 20 * Math.sin(normalizedAz); // Sine wave between 1 and 21
			}
		}	
		// Return 1 if the angle does not fall within any region
		return 1;
	};

	const getCurrentFocusIndex = (pos: number) => {
		/**
		 * Returns the focus option that corresponds to the current scroll position.
		 * 
		 * @param {number} pos - The current scroll position.
		 * @returns {Object} The focus option that corresponds to the current scroll position.
		 */
		for (let i = 0; i < focus.length; i++) {
			const maxPos = focus[i + 1]?.scroll || 1;
			const { scroll: minPos } = focus[i];
			if (minPos <= pos && pos < maxPos) {
				return i;
			}
		}
		return null;
	};
	const xPID = new PIDController(0.09, 0, 0); // Adjust these constants for your needs

	const setControls = (i: number, forceLat?: number,) => {
		/**
		 * Sets the camera controls to the correct lat, long and rotation speed
		 * depending on the current scroll position.
		 */
		// Check for all the focus options which one corresponds to the current scroll position
		const { method } = focus[i];
						
		// Once found, determine if we are in a rotate or point method
		if (method.type == "rotate" as rotateType) {

			// Clear rotations 
			numRotationsRef.current = undefined;
			// Rotate method keeps the world spinning at a given speed and at a given latitude 
			const { 
				speed, 
				lat, 
				skipOcean=false
			} = method as RotateMethod;
		
			// if we have set skip Ocean, the speed will increase when we are flying over the 
			// atlantic and the pacific ocean to avoid having to wait for the globe to rotate
			const speedFactor = skipOcean ? getSpeedFactor(groupRef.current.rotation.y) : 1;
			// Rotate the globe at a given speed
			const targetXRotation = (Math.PI/2) - latToPolar(forceLat || lat);
			const currentXRotation = groupRef.current ? groupRef.current.rotation.x : 0;
			const xAdjustment = xPID.calculate(targetXRotation, currentXRotation);
			if (groupRef.current) {
				groupRef.current.rotation.y -= speed * speedFactor;
				groupRef.current.rotation.x += xAdjustment;
			}
		} else if (method.type == "point" as pointType) {
			// this option just positions the globe at a given fixed lat and long
			const { lat, lng } = method as PointMethod;
			
			// Calculate the azimuthal angle of the current longitude
			// and rotate the globe to that angle
			// account for multiple rotations to prevent the globe from 
			// rotating more than necessary
			const azimuthal = groupRef.current.rotation.y;
			let numRotations = Math.floor((-azimuthal) / TWO_PI);
			numRotations = Math.max(numRotations, 0);

			if (numRotationsRef.current === undefined) {
				// First of all record the rotation if we don't have a current one
				numRotationsRef.current = numRotations;
			}

			const targetAzimuthal = (lngToAzimuthal(lng) + numRotationsRef.current * TWO_PI);
			
			if (groupRef.current) {
				const e = 0.0000001;
				const currentYRotation = groupRef.current ? groupRef.current.rotation.y : 0;
				const yAdjustment = xPID.calculate(-targetAzimuthal, currentYRotation);
				const errorY = Math.abs(-targetAzimuthal - currentYRotation);
				errorY > e && (groupRef.current.rotation.y += yAdjustment);

				const targetXRotation = (Math.PI/1.85) - latToPolar(lat);
				const currentXRotation = groupRef.current ? groupRef.current.rotation.x : 0;
				const xAdjustment = xPID.calculate(targetXRotation, currentXRotation);
				const errorX = Math.abs(targetXRotation - currentXRotation);
				errorX > e && (groupRef.current.rotation.x += xAdjustment);
			
			}

		}
	};
	
	useFrameLimiter(({ state: {gl, clock} }) => {
		/**
		 * This function is called every frame. It updates the position of the camera, the position of the particles
		 * and their color. It updates any value necessary for the uniforms to be passed to the shaders.
		 */
		const pos = offsetTransform(offset.current ? offset.current : 0);
		// Render positions
		gl.setRenderTarget(renderTargetPosition);
		gl.clear();
		gl.render(materialScene, camera);

		// get current index of focus
		const i = getCurrentFocusIndex(pos);
		const toHighlight = Math.ceil((1 / interval) * clock.elapsedTime % (focus[i]?.numColorStages || 1));
		const forceLat = (focus[i]?.colorStagesLatitudes || [])[toHighlight - 1];
		setControls(i, forceLat);
		//@ts-ignore
		points.current.material.uniforms.uPositions.value = renderTargetPosition.texture;

		// Render colors
		gl.setRenderTarget(renderTargetColor);
		gl.clear();
		gl.render(colorScene, camera);
		gl.setRenderTarget(null);

		//@ts-ignore
		points.current.material.uniforms.toHighlight.value = toHighlight;
		//@ts-ignore
		points.current.material.uniforms.uColor.value = renderTargetColor.texture;
		//@ts-ignore
		points.current.material.uniforms.uTime.value = clock.elapsedTime;
		//@ts-ignore
		simulationMaterialRef.current.uniforms.uScroll.value = pos;
		//@ts-ignore
		colorMaterialRef.current.uniforms.uScroll.value = pos;
		//@ts-ignore
		simulationMaterialRef.current.uniforms.uTime.value = clock.elapsedTime;
		//@ts-ignore
		colorMaterialRef.current.uniforms.uTime.value = clock.elapsedTime;
		
	});
	const Scene = useMemo(() => {
		return (
			<>
				{ParticleTexture}
				<points ref={points} renderOrder={2} castShadow={true}>
					<bufferGeometry>
						<bufferAttribute
							attach="attributes-position"
							count={particlesPosition.length / 3}
							array={particlesPosition}
							itemSize={3}
						/>
					</bufferGeometry>
					<shaderMaterial
						blending={THREE.AdditiveBlending}
						depthWrite={false}
						depthTest={true}
						fragmentShader={fragmentShader()}
						vertexShader={vertexShader(baseColor, highlightFactor, particleSize)}
						uniforms={uniforms}
						toneMapped={false}
						transparent={true}
						vertexColors={true}
					/>
				</points>
			</>
		);
	}, [ParticleTexture, particlesPosition, fadeToColor, baseColor, highlightFactor, particleSize, uniforms]);
	return Scene;
};

export default ParticlesTexture;
export type { MethodType, PointMethod, RotateMethod };