import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { Parallax, Buttons } from "tema-ai-components";
import Grid, { useGrid } from "Components/Reusable/Grid";
import { GridSectionAbsolute } from "./Section";
import { MISSION } from "Components/Pages/Main/copy";
import { ContentCSS, SupraTitleCSS, EyeBrowseCSS } from "Styles";
import { useTheme } from "Context";

const StyledButton = styled(Buttons.ArrowButton)`
	p {
		${ EyeBrowseCSS }
	}
	padding: 0;
	
`;

// Text block for the mission section
const Text = styled.div`
	${ SupraTitleCSS }
	grid-column: 1 / span 7;
	grid-row: 2 / span 2;
`;

const LineContainer = styled.div`
	grid-column: 1 / span 12;
	grid-row: 4 / span 1;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
`;
const Line = styled.div`
	width: 100%;
	height: 2px;
	background-color: ${({ theme }) => theme.textColorSuperLight};
`;

const Common = css`
	${ ContentCSS }
	line-height: 24px;
	p {
		margin: 0;
		padding: 0;
	}
`;

const SubText = styled.div`
	grid-column: 1 / span 4;
	grid-row: 5 / span 1;
	${ Common }

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	color: ${({ theme }) => theme.textColorLight};
	.highlight {
		margin-top: 10px;
		color: ${({ theme }) => theme.textColor};
	}

`;
const Card1 = styled(SubText)`
	grid-column: 5 / span 4;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;
const Card2 = styled(Card1)`
	grid-column: 9 / span 4;
`;
const Number = styled.div<{ $width: number, $gap: number }>`
	${ Common }
	color: ${({ theme }) => theme.accentColor};
	min-width: ${({ $width }) => $width}px;
	max-width: ${({ $width }) => $width}px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-right: ${({ $gap }) => $gap}px;
`;
const TextBlock = styled.div`
	${ Common }
	
	.title {
		color: ${({ theme }) => theme.accentColor};
	}
	.subtitle {
		color: ${({ theme }) => theme.textColor};
	}
	color: ${({ theme }) => theme.textColorLight};
`;

const ButtonBlock = styled.div`
	grid-column: 1 / span 2;
	grid-row: 4 / span 1;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
`;

const Mission = ({
	start, 
	end, 
	toContact
} : {
	start: number, 
	end: number, 
	toContact?: () => void
}) => {
	const { cellWidth, gap: cellGap } = useGrid();
	const { theme } = useTheme();

	const gap = (end - start);
	const Button = useMemo(() => <StyledButton
		text="Book a demo"
		highlightColor={theme.accentColor}
		duration={2}
		buttonWidth={Math.max(2 * cellWidth + cellGap, 160)}
		height={40}
		backgroundColor={theme.accentColor}
		textColor={theme.contrastColor}
		borderColor={theme.accentColor}
		angle={45}
		arrowBackgroundColor={theme.contrastColor}
		arrowColor={theme.accentColor}
		// @ts-ignore
		hoverBackgroundColor={theme.contrastColor}
		onClick={toContact}
	/>, [theme, toContact]);
	return (
		<>
			<Parallax.Layer sticky={{ start: start + gap * 0.1, end }} >
				{/* @ts-ignore */}
				<Grid>
					<SubText>
						{ MISSION.subtitle }
					</SubText>
				</Grid>
			</Parallax.Layer>
			<Parallax.Layer sticky={{ start: start + gap * 0.2, end }}>
				{/* @ts-ignore */}
				<Grid style={{ pointerEvents: "none" }}>
					<Card1>
						<Number $width={cellWidth} $gap={cellGap}>01</Number>
						<TextBlock>
							{ MISSION.card1 }
						</TextBlock>
					</Card1>
				</Grid>
			</Parallax.Layer>
			<Parallax.Layer sticky={{ start: start + gap * 0.3, end }}>
				{/* @ts-ignore */}
				<Grid style={{ pointerEvents: "none" }}>
					<Card2>
						<Number $width={cellWidth} $gap={cellGap}>02</Number>
						<TextBlock>
							{ MISSION.card2 }
						</TextBlock>
					</Card2>
				</Grid>
			</Parallax.Layer>
			<Parallax.Layer sticky={{ start, end }} style={{
				pointerEvents: "none"
			}}>
				{/* @ts-ignore */}
				<GridSectionAbsolute>{ MISSION.sectionName }</GridSectionAbsolute>
				<Grid style={{ pointerEvents: "none" }}>
					<Text style={{pointerEvents: "auto"}}>{ MISSION.title }</Text>
					<ButtonBlock style={{pointerEvents: "auto"}}>
						<div style={{height: "40px"}}>
							{ Button }
						</div>
					</ButtonBlock>
					<LineContainer>
						<Line/>
					</LineContainer>
				</Grid>
			</Parallax.Layer>
		</>
	);
};

export default Mission;