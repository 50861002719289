import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Brand } from "tema-ai-components";

import AccountMenu from "../AccountMenu";

const UserMenuContainer = styled.div`
	height: 120px;
	position: absolute;
	top: 65px;
	left: 180px;
	user-select: none;
	z-index: 20;
`;

const UserMenuButton = () => {
	const [open, setOpen] = React.useState(false);
	const ref = React.useRef<HTMLDivElement>(null);

	// if click outside we should close the menu 
	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	return (
		<div ref={ref}>
			<FontAwesomeIcon className="icon" icon={faUser} onClick={() => setOpen(!open)}/>
			{open && <UserMenuContainer ><AccountMenu /></UserMenuContainer>}
		</div>
	);
};

const StyledTemaName = styled(Brand.TemaName)`
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-family: "Manrope", sans-serif;
	// no selection of text
	user-select: none; /* Prevent selection */
					-webkit-user-select: none; /* For Safari */
					-moz-user-select: none; /* For Firefox */
					-ms-user-select: none; /* For Internet Explorer/Edge */
	// no wrap 
	white-space: nowrap;
`;

const HeaderStyle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	margin-top: 30px;
	user-select: none; /* Prevent selection */
		-webkit-user-select: none; /* For Safari */
		-moz-user-select: none; /* For Firefox */
		-ms-user-select: none; /* For Internet Explorer/Edge */
`;

const Header = () => {
	return (
		<HeaderStyle>
			<div><StyledTemaName dot={false} link="/"/></div>
			<UserMenuButton />
		</HeaderStyle>
	);
};

export default Header;