import { useState, useEffect } from "react";
import axios from "axios";
//@ts-ignore
import { useAxios } from "Components/Reusable/api";
import { fetchAuthSession, AuthSession } from "aws-amplify/auth";

const buildConfig = (accessToken: string) => {
	return {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};
};

const useTokens = () => {
	const [session, setSession] = useState<AuthSession>();

	const refresh = async () => {
		const data = await fetchAuthSession();
		setSession(data);
	};

	useEffect(() =>{
		refresh();
	}, []);

	return {
		accessToken: session?.tokens?.accessToken.toString(),
		tokens: session,
		refresh
	};
};

const authenticatedRequest = async <P>(url: string, method: string, payload: P) => {
	const session = await fetchAuthSession();
	const tokens = session?.tokens?.accessToken.toString();
	const response = await axios.request({
		data: payload,
		method,
		headers: buildConfig(tokens).headers,
		url
	});
	return response.data;
}; 

type Status = {
	completed_at: string,
	progress: string,
	requested_at: string
};

type RequestDataType<T> = {
	data: T,
	status: Status
};

const useAuthenticatedRequest = <T, P>(
	url: string, 
	method: string, 
	payload: P, 
	trigger?: boolean,
	api="default"
) => {
	/*

    */
	const { accessToken } = useTokens();
	const headers = buildConfig(accessToken).headers;
	const tokensLoaded = accessToken !== undefined;
	const { cancel, data, error, loaded } = useAxios<RequestDataType<T>, P>(
		url, method, payload, headers, trigger && tokensLoaded, api
	);
	return { cancel, data, error, loaded };
};

export default useAuthenticatedRequest;
export {
	buildConfig,
	useTokens,
	authenticatedRequest
};