import styled from "styled-components";
import { EyeBrowseCSS } from "Styles";

const FIELD_HEIGHT = 46;	
const FIELD_HEIGHT_MOBILE = 35;
const GAP = 20;
const PHONE_GAP = 10;

const StyledForm = styled.form<{$mobile?: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${({ $mobile }) => $mobile ? PHONE_GAP : GAP}px;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	z-index: 30;
	.submit {
		height: ${({ $mobile }) => $mobile ? FIELD_HEIGHT_MOBILE : FIELD_HEIGHT}px;
		flex-grow: 0;
		width: 100%;
		button {
			border-radius: 6px;
			${ EyeBrowseCSS }
		}
	}
	.form-group {
		gap: ${({ $mobile }) => $mobile ? PHONE_GAP : GAP}px;
		flex-direction: ${({ $mobile }) => $mobile ? "column" : "row"};
	}
	div#updates {
		padding: 0;
		.main {
			padding: 0 10px 0 20px;
		}
	}
	.consent {
		border-radius: 6px;
	}

	textarea,
	input {
		${ EyeBrowseCSS }
		color: ${({ theme }) => theme.textColor};
		&::placeholder {
			${ EyeBrowseCSS }
			color: ${({ theme }) => theme.textColorLight};
		}
	}
	.dropdown {
		.react-select {
			&__placeholder,
			&__menu {
				${ EyeBrowseCSS }
				color: ${({ theme }) => theme.textColorLight};
			}
			&__option,
			&__single-value {
				${ EyeBrowseCSS }
				color: ${({ theme }) => theme.textColor};
			}
			&__control {
				height: ${({ $mobile }) => $mobile ? FIELD_HEIGHT_MOBILE : FIELD_HEIGHT}px;
				.react-select__value-container {
					height: ${({ $mobile }) => $mobile ? FIELD_HEIGHT_MOBILE : FIELD_HEIGHT}px;
				}
			}
		}
	}

	.field {
		&__consent {
			div {
				box-sizing: content-box;
			}
		}
		width: 100% !important;

		&__email,
		&__dropdown,
		&__name,
		&__message,
		&__consent {
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			-moz-backdrop-filter: blur(10px);
			p {
				${ EyeBrowseCSS }
				color: ${({ theme }) => theme.textColor};
			}
		}

		&__email,
		&__dropdown,
		&__consent,
		&__name {
			height: ${({ $mobile }) => $mobile ? FIELD_HEIGHT_MOBILE : FIELD_HEIGHT}px;
			box-sizing: content-box;
			flex-grow: 0;
		}
		&__message {
			flex-grow: 4;
			z-index: 5;
		}
		&__dropdown {
			z-index: 10;
		}
		
		input#name,
		input#email,
		textarea {
			box-sizing: content-box;
		}
	}

`;

export { StyledForm };