import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState, User, DeltaSharePermission } from "store/types/userTypes";
import { getDeltaShare, verifyDeltaShare } from "store/thunks/userThunks";

const initialState: UserState = {
	isAuthenticated: false,
	user: null,
	deltaShare: {
		loading: true,
		allowed: false,
		connections: [],
		shares: []
	},
	loading: false,
	error: null,
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser(state, action: PayloadAction<User>) {
			state.isAuthenticated = true;
			state.user = action.payload;
		},
		updateDeltaShare(state, action: PayloadAction<DeltaSharePermission>) {
			state.deltaShare = action.payload;
		},
		logout(state) {
			state.isAuthenticated = false;
			state.user = null;
			state.deltaShare = {
				allowed: false,
				loading: false,
				connections: [],
				shares: []
			};
		},
	},
	extraReducers: (builder) => {
		builder
			// Delta Share connection
			.addCase(getDeltaShare.pending, (state, action) => {
				let { silent } = action.meta.arg || {};
				silent = silent ?? false;
				if (!silent) {
					state.loading = true;
				}
				state.error = null;
				state.deltaShare = { ...state.deltaShare, loading: !silent };
			})
			.addCase(getDeltaShare.fulfilled, (state, action: PayloadAction<UserState>) => {
				state.isAuthenticated = true;
				state.user = action.payload.user;
				state.deltaShare = action.payload.deltaShare;
				state.loading = false;
			})
			.addCase(getDeltaShare.rejected, (state, action: PayloadAction<string>) => {
				state.loading = false;
				state.error = action?.payload || "An error occurred";
				state.deltaShare = {...state.deltaShare, allowed: false, loading: false};
			})
			// @ts-ignore
			.addCase(verifyDeltaShare.fulfilled, (state, action: PayloadAction<boolean>) => {
				//state.isAuthenticated = true;
				state.deltaShare.allowed = action.payload;
			}).addCase(verifyDeltaShare.rejected, (state,) => {
				//state.isAuthenticated = true;
				state.deltaShare.allowed = false;
			});
	},
});

export const { setUser, logout, updateDeltaShare } = userSlice.actions;

export default userSlice.reducer;
