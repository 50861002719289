import React, { useMemo } from "react";
import styled from "styled-components";
import { Navigation } from "tema-ai-components";
import { useGrid, device } from "Components/Reusable/Grid";
import { EyeBrowseCSS } from "Styles";
import { useTheme } from "Context";
import { clamp } from "lodash";

const SocialContainer = styled.div`
	grid-column: 1 / span 4;
	grid-row: 6 / span 1;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	transform: translateY(-20px);
	div {
		pointer-events: all;
	}
	@media ${device.tablet} {
		grid-row: 12 / span 1;
	}
`;

const SocialStyled = styled(Navigation.Social)`
	p {
		${ EyeBrowseCSS }
	}
`;

const Social = ({show=true, tight = false, style}: {show: boolean, tight?: boolean, style?: object}) => {
	const { cellWidth, gap } = useGrid();
	const { theme } = useTheme();
	const buttonWidth = useMemo(() => clamp(2 * cellWidth + gap, 200, 250), [cellWidth, gap]);
	return (
		<SocialContainer style={style}>
			<SocialStyled
				newsletterText="Join newsletter"
				hide={!show} 
				duration={2}
				backgroundColor="rgba(255, 255, 255, 0.08)"
				color={theme.textColorLight}
				highlightColor={theme.accentColor}
				size={40}
				gap={gap / (tight ? 2 : 1)}
				buttonWidth={buttonWidth}
				borderColor="rgba(255, 255, 255, 0.06)"
				socialLinks={[
					//"https://twitter.com/TemaAI",
					"https://linkedin.com/company/tema-ai",
					//"https://medium.tema.ai",
					//"https://github.com/tema-ai",
				]}
				newsletterLink="https://www.weekinclimate.com"
			/>
		</SocialContainer>
	);
};

export default Social;