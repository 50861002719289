import React, { useEffect, useState, ReactNode } from "react";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import { oneLight as style} from "react-syntax-highlighter/dist/esm/styles/prism";
import { MarkDownStyle } from "./styles";
import { useCookieConsent } from "Components/Reusable/CookieConsent";


type CodeProps = {
	inline: boolean,
	className: string,
	children: React.ReactNode
}

function code({inline, className, children, ...props} : CodeProps) {
	const match = /language-(\w+)/.exec(className || "");
	return !inline && match ? (
		<SyntaxHighlighter
			style={style}
			language={match[1]}
			wrapLongLines={true}
			PreTag="div"
			{...props}
		>{String(children).replace(/\n$/, "")}</SyntaxHighlighter>
	) : (
		<code className={className} {...props}>
			{children}
		</code>
	);
}

type MarkdownLoaderProps = {
	content: string,
	className?: string
}

type NodeType = {
	children: ReactNode,
	href: string
}

export default function MarkdownLoader({ content, className } : MarkdownLoaderProps) {
	const [markdownText, setMarkdownText] = useState(null);
	const { showPreferences } = useCookieConsent();
	// Read the content on load
	useEffect(() => {
		fetch(content)
			.then(response => response.text())
			.then(text => setMarkdownText(text));
	}, [content]);

	const components = {
		code,
		table: (node: NodeType) => <div className="table_wrapper"><table>{node.children}</table></div>,
		a: (node: NodeType) => {
			if (node.href == "manageCookies") {
				return (
					<a onClick={showPreferences} style={{ cursor: "pointer" }}>
						{node.children}
					</a>
				);
			}
			return <a href={node.href} target="_blank" rel="noreferrer">{node.children}</a>;
		}
	};

	return (
		<div>
			{/* @ts-ignore */}
			<MarkDownStyle className={className} components={components} remarkPlugins={[remarkGfm, remarkBreaks]}>
				{markdownText}
			</MarkDownStyle>
		</div>
	);
}