import React, { createContext, useState, useContext, useEffect } from "react";
import { NamedTheme, Theme, lightTheme, darkTheme } from "./theme";

interface ThemeContextProps {
    theme: Theme;
	themeName: string;
    toggleTheme: () => void;
  }

const defaultContextData: ThemeContextProps = {
	theme: lightTheme.theme,
	themeName: "light",
	toggleTheme: () => {
		//noop
	},
};

const ThemeContext = createContext<ThemeContextProps>(defaultContextData);

const useTheme = () => useContext(ThemeContext);


const ThemeProvider = ({ children } : { children: React.ReactNode }) => {

	const [theme, setTheme] = useState<NamedTheme>(darkTheme);

	const toggleTheme = () => {
		setTheme((prevTheme) => (prevTheme.name === "light" ? darkTheme : lightTheme));
	};

	useEffect(() => {
		const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
		// Fix this once the light mode is working properly
		setTheme(prefersDarkMode ? darkTheme : darkTheme);
	}, []);

	return (
		<ThemeContext.Provider value={{ theme: theme.theme, themeName: theme.name, toggleTheme }}>
			{children}
		</ThemeContext.Provider>
	);
};

export {
	ThemeProvider,
	useTheme,
};

export type { Theme };