import React, { useEffect } from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { useAppSelector } from "store/hooks"; // Assuming you have a typed useDispatch hook
import useAuthAppDispatch from "./useAuthAppDispatch";

import { setUser } from "store/slices/userSlice"; // Action to set the user in the Redux store
import { getDeltaShare, verifyDeltaShare } from "store/thunks/userThunks"; // Thunk to fetch DeltaShare data
import { User } from "store/types/userTypes";

import Components from "./Components";
import formFields from "./formFields";
import Theme from "./Theme";
import { useLocation, Navigate } from "react-router";

type Type = {
	children?: React.ReactNode
};
const WebAuthenticator = ({children}: Type) => {
	return (
		<Theme>
			<Authenticator 
				formFields={formFields} 
				components={Components} 
				variation="modal" 
				hideSignUp={true}
			>
				{() => (
					<>
						{children}
					</>
				)}
			</Authenticator>
		</Theme>
	);
};


type RequireAuthType = {
	children: React.ReactNode
};

const RequireAuth = ({ children }: RequireAuthType) => {
	const location = useLocation();
	const { user, route } = useAuthenticator((context) => [context.route, context.user]);
	const {
		dispatch,
		contextInstance,
		headers,
		headerReady
	} = useAuthAppDispatch();
	// Accessing deltaShare from the Redux store
	const { isAuthenticated } = useAppSelector((state) => state.user);
	useEffect(() => {
		if (route === "authenticated" && user) {
			// Dispatch an action to set the user in the Redux store
			dispatch(setUser(user as User));
		}
	}, [route, user, dispatch]);

	// Fetch DeltaShare permission
	useEffect(() => {
		if (!headerReady) return;
		if (isAuthenticated) {
			dispatch(verifyDeltaShare({
				instance: contextInstance.shareAPI,
				headers,
			}));
		}
	}, [isAuthenticated, headerReady,]);

	// Fetch DeltaShare data if necessary, after user is set
	useEffect(() => {
		if (!headerReady) return;
		if (isAuthenticated) {
			dispatch(getDeltaShare({
				instance: contextInstance.shareAPI,
				headers,
			}));
		}
	}, [isAuthenticated, headerReady,]);

	if (route !== "authenticated") {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}
	return children;
};

export { WebAuthenticator, RequireAuth };