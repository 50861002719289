import { Color } from "three";

/*
A vertex shader is a type of shader in GLSL that is responsible for 
transforming each vertex of a 3D model from its original position to 
its final position on the screen. It receives as input the attributes 
of each vertex, such as its position in 3D space, texture coordinates, 
and normal vector. The shader then applies transformations to the vertex 
position, such as translation, rotation, scaling, and projection, based 
on a combination of matrices, including a model matrix, view matrix, 
and projection matrix. The transformed vertex position is then output 
to the next stage of the graphics pipeline.

Built ins:
  position: 3d vertex position
  modelViewMatrix: represents the transformation of the model from its local 
    coordinate system to the camera coordinate system
  projectionMatrix: transformation from the camera coordinate system to the 
    final 2D screen space
  gl_Position: represents the position of the vertex in homogeneous clip space
  gl_PointSize: represents size of particles
*/

const vertexShader = (
	baseColor: string | number, // This color will be highlighted
	highlightFactor = 10,
	particleSize = 15,
) => {
	const { r, g, b } = new Color(baseColor);
	const rf = r.toFixed(2);
	const gf = g.toFixed(2);
	const bf = b.toFixed(2);
	return `
uniform sampler2D uPositions;
uniform sampler2D uColor;
uniform float uTime;
uniform float highlightFactor;
uniform float toHighlight;

varying vec3 v_position;
varying vec4 v_color;
varying vec4 v_view;
varying vec4 v_model;
varying float v_dist;
varying float vDistance;

void main() {
  // Time movement
  float time = (sin(2.0 * uTime) + 1.0) * 0.5;

  // POSITION
  vec3 pos = texture2D(uPositions, position.xy).xyz;
  v_position = pos;

  // OpenGL requires the 4th dimension even if not used 
  vec4 modelPosition = modelMatrix * vec4(pos, 1.0);
  v_model = modelPosition;
  vec4 viewPosition = viewMatrix * modelPosition;
  v_view = viewPosition;
  gl_Position = projectionMatrix * viewPosition;

  // Compute a distance factor for color in the z direction
  v_dist = -viewPosition.z;
  
  // COLOR
  vec4 color2d = texture2D(uColor, position.xy);
  // we use the 4th dimension as a flag to determine if the particle should be highlighted
  float highlightFlag = color2d.a;
  v_color = color2d;

  // SIZE
  // we check if the particle is a base particle based on color (color comparison by channel)
  bool isBaseColor = abs(color2d.x - ${rf}) < 0.01  && abs(color2d.y - ${gf}) < 0.01 && abs(color2d.z - ${bf}) < 0.01;
  
  // magnifier will be a pixel value to add to the particle size 
  // Default magnifier is highlightFactor unless it is a base particle
  float magnifier = isBaseColor ? 0.0 : highlightFactor;

  // We have another variable called high which determines if a particle should blink or not
  // if a particle is too far away from the camera or if we have decided not to highlight it
  // then we cancel the blink effect
  float blink = (highlightFlag != toHighlight && highlightFlag >= 0.0) ? 0.0 : 1.0;
  // Finally oscillate the blinking based on the sin time variable
  float finalBlink = ${highlightFactor.toFixed(2)} * (time + 0.5) * blink;

  // Size particles based on distance from center  -- NOT SURE ABOUT THIS, it should be based on the distance from the camera
  float distanceFactor = pow(length(pos), 1.5);
  float size = distanceFactor + ${particleSize.toFixed(2)};
  // Size attenuation;
  size *= (3.0 / abs(viewPosition.z));
  size += finalBlink + magnifier;
  gl_PointSize = size;  
}
`;
};

export default vertexShader;
