import { MethodType } from "./ParticlesTexture";

// WORLD H3 cells at resolution 3

const DEFAULT_ROTATION = {
	type: "rotate" as MethodType,
	speed: 0.0005,
	lat: 40,
	skipOcean: false
};

const HIDE = {
	type: "hide" as MethodType,
	lat: 0,
	smoothTime: 1,
	moveTo: {
		x: 0,
		y: 5,
		z: 15
	}
};

const DEFAULT_ROTATION_SKIP_OCEAN = {
	...DEFAULT_ROTATION,
	skipOcean: true
};


export { 
	DEFAULT_ROTATION, 
	DEFAULT_ROTATION_SKIP_OCEAN,
	HIDE
};
