import React, { useEffect, useMemo, RefObject, useState } from "react";
import * as portals from "react-reverse-portal";
import { useSearchParams } from "react-router-dom";
import constants from "./constants";
import { Parallax } from "tema-ai-components";
import Page, { PageRef } from "Components/Reusable/Page";
import { Config } from "Constants";
import { useParticles } from "Context";
import Home from "./PhoneBlocks/Home";
import Audience from "./Blocks/Audience";
import Mission from "./PhoneBlocks/Mission";
import Technology from "./PhoneBlocks/Technology";
import Features from "./PhoneBlocks/Features";
import OffsetTracker from "./Utils/OffsetTracker";


const Layers = ({
	parallaxRef,
	offset
} : {
	parallaxRef: RefObject<Parallax.IParallax>
	offset: RefObject<number>
}) => {
	const { particlesPortal, setProps } = useParticles();
	const { pages } = Parallax.getParallaxContext();
	
	useEffect(() => {
		setProps({
			offset: offset,
			offsetTransform: (o: number) => Math.min(1, o / (pages - 1))
		});
	}, [setProps, offset, pages]);

	const Particles = useMemo(() => {
		return (
			<Parallax.Layer sticky={{ start: 0, end: pages }}>
				<portals.OutPortal node={particlesPortal} />
			</Parallax.Layer>
		);
	}, [particlesPortal]);

	const scrollTo = parallaxRef.current?.scrollTo;
	// Page blocks 
	const blocks = useMemo(() => {
		return (<>
			<Home start={0} end={1} overflow={0}/>
			<Mission start={1.25} end={3.5}/>
			<Features start={3.75} end={8}/>
			<Technology start={8.5} end={16} scrollTo={scrollTo}/>
			<Audience start={16} end={18}/>
		</>);
	}, [scrollTo]);
	const showOffset = false;
	return (
		<>
			{showOffset && <OffsetTracker pages={pages} offset={offset}/>}
			{ Particles }
			{/* This layer is used to cover the particles as I'm not sure why in areas where the particles are not covered we can't scroll on touch screen */}
			<Parallax.Layer sticky={{ start: 0, end: pages }}>{" "}</Parallax.Layer>
			{ blocks }
		</>
	);
};
const Main = () => {
	// check if we need to scroll to a specific section
	const [searchParams] = useSearchParams();
	const { config } = Config;
	const { parallaxRef, offset } = Parallax.useParallax();
	const pageRef = React.useRef<PageRef>(null);
	const pages = 18;

	useEffect(() => {
		// check if there is a section to scroll to
		pageRef.current?.freezeNavBar();
		pageRef.current?.reBuildKey();
		const section = searchParams.get(constants.SEARCH_PARAM_SECTION);
		if (section && parallaxRef.current) {
			// scroll to the section
			const pos = constants.PHONE_SCROLL_TARGET[section];
			parallaxRef.current?.scrollTo(pos, 0);
		}
		const time = setTimeout(() => {
			pageRef.current?.unfreezeNavBar();
		}, 1000);
		return () => clearTimeout(time);
	}, [parallaxRef, searchParams]);

	const narBarItems = useMemo(() => constants.PHONE_NAVBAR_ITEMS.filter(({ showOnNavBar }) => showOnNavBar == true || showOnNavBar == undefined), []);

	return (
		<Page 
			ref={pageRef} 
			navBarItems={narBarItems}
			hideNavBarAfter={80}
			navBarHeight={50}
			homeLink={`/?${constants.SEARCH_PARAM_SECTION}=home`}
			ctaLink={`/?${constants.SEARCH_PARAM_SECTION}=audience&${constants.SEARCH_PARAM_NATURE}=general`}
		>
			<Parallax.Container 
				className="parallax_container_main" 
				ref={parallaxRef} 
				pages={pages}
				heightDebounceValue={config.mobileScreenDebouncer.debounceValue}
				heightDebounceTime={config.mobileScreenDebouncer.debounceTime}
				duration={config.mobileScreenDebouncer.duration}
				style={{pointerEvents: "auto"}}
			>
				<Layers 
					parallaxRef={parallaxRef} 
					offset={offset}
				/>
			</Parallax.Container>
		</Page>
	);
};

export default Main;