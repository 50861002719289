import simulationVertexShader from "./simulationVertexShader";
import { ShaderMaterial } from "three";

import { prepareTextures, buildShader } from "./utils";

class SimulationMaterial extends ShaderMaterial {
	/**
	 * This is a material shader that runs on the GPU and is used to simulate the movement of the
	 * particles. It exclusively deals with particles positions and not with particles colors
	 *
	 * @param size The size of the texture, must be a power of 2
	 */
	constructor(size: number, particleStates:  { particles: Float32Array; id: string; }[], transitions: number[]) {
		// Build the textures from the positions
		// Add uniforms, you need to pass all values in the positions array
		// we should try and come up with a better way to do this
		const positionUniforms = prepareTextures(particleStates, size);

		const simulationUniforms = {
			...positionUniforms,
			// Clock time
			uTime: { value: 0 },
			// Scroll position: determines the evolution of the simulation
			uScroll: { value: 0 },
		};
		const stages = particleStates.map((state) => state.id);

		// I need as as many transitions as I have states
		// Each transition must be an array of (start and end] 
		//transitions 
		const fragmentShader = buildShader("uScroll", stages, transitions);
		super({
			uniforms: simulationUniforms,
			vertexShader: simulationVertexShader,
			fragmentShader,
		});
	}
}

export default SimulationMaterial;
export { prepareTextures, buildShader };
