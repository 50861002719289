import { 
	getRandomDataSphere, 
	getHexWorld, 
	getColor, 
	getMultiColor, 
} from "./utils";
import { cellToLatLng } from "h3-js";
import { Stage, FocusType, Particles } from "./FBOParticles/types";
import { Color } from "./types";
import { DEFAULT_ROTATION, DEFAULT_ROTATION_SKIP_OCEAN } from "./constants";
import { MethodType } from "./ParticlesTexture";

type StoryType = Stage[];

function particlesId (particles: Float32Array, id: string): Particles { return {particles, id}; }

type BuildStoryProps = {
    size: number, 
    baseColor: Color, 
	baseColorFaded: Color,
	economicColor: Color,
    disruptedColor: Color,
	worldMask: string[],
	commodityMask: string[][],
	countryMask: string[],
	location: [number, number],
	countryLocation: [number, number];
	isPhone: boolean;
};
function BuildStory ({
	size, 
	baseColor, 
	baseColorFaded,
	economicColor,
	disruptedColor,
	worldMask, 
	commodityMask,
	countryMask,
	location,
	countryLocation,
	isPhone
} : BuildStoryProps): StoryType {

	const worldRadius = 3.5;
	const randomRadius = 4.5;
	const num_particles = size * size;
	// Default no color options, ONLY 1 version of particles
	// convert number to hex
	const noColor = { particles: getColor(worldMask, num_particles, [], baseColor, baseColor), id: "noColor" };
	const white = { particles: getColor(worldMask, num_particles, [], baseColorFaded, baseColorFaded), id: "white" };
	// Random world with, ONLY 1 version of each particles
	const randomData = particlesId(getRandomDataSphere(worldMask, num_particles, randomRadius), "randomData" );
	// World with 3.5 radius, ONLY 1 version of each particles
	const world = particlesId(getHexWorld(worldMask, num_particles, worldRadius), "world");
	const numCommodities = commodityMask.length;
	// Gets an array of latitudes for each commodity mask so we can pan the world to that latitude
	const colorStagesLatitudes = commodityMask.map(m => {
		const lat = m.map(c => cellToLatLng(c)[0]);
		// do the mean over the lat array
		return lat.reduce((a, b) => a + b, 0) / lat.length;
	});
	// Generates mask colors, does it for each commodity and adds a different highlight flag
	const crop = particlesId(getMultiColor({
		worldHexs: worldMask,
		num_particles, 
		multiColor: commodityMask.map((m, i) => ({
			color: disruptedColor,
			cells: m, 
			highlight: (i + 1)
		})),
		baseColor: baseColorFaded, 
	}), "crop");
	// Generates mask color for a country
	const country = particlesId(getMultiColor({
		worldHexs: worldMask,
		num_particles, 
		multiColor: [
			{
				color: disruptedColor,
				cells: countryMask.filter(() => Math.random() > 0.85), 
				highlight: -1.0
			},
			{
				color: economicColor,
				cells: countryMask, 
				highlight: 0.0
			}
		],
		baseColor: baseColorFaded, 
	}), "country");

	// MAXIMUM 3 DECIMAL POINTS ON THE SCROLL, ANYTHING ELSE WILL NOT BE READ BY THE SHADER
	let stages = [];
	const newLocate = (loc: number[], dol: number) => ({ type: "point" as MethodType, lat: loc[0], lng: loc[1], dollyTo: dol });
	const fast = DEFAULT_ROTATION.speed * 5;
	const move = { x: 0, y: 0, z: 0 };
	const dollyTo = 5;
	stages = [
		[0, {...DEFAULT_ROTATION_SKIP_OCEAN, dollyTo, speed: fast}, world, noColor, undefined, false, 1, undefined, true, undefined],
		[0.05, {...DEFAULT_ROTATION_SKIP_OCEAN, dollyTo, speed: fast}, world, noColor, undefined, false, 1, undefined, false, undefined],
		[0.1, {...DEFAULT_ROTATION, dollyTo: 3}, randomData, noColor, undefined, undefined, false, undefined, false, undefined],
		[0.43, {...DEFAULT_ROTATION, dollyTo: 1}, randomData, noColor, undefined, undefined, false, undefined, false, undefined],
		[0.46, {...DEFAULT_ROTATION, dollyTo}, randomData, noColor, undefined, undefined, false, undefined, false, undefined],
		[0.47, {...DEFAULT_ROTATION_SKIP_OCEAN, lat: 0, dollyTo: dollyTo * 2, speed: fast}, world, crop, 1, false, numCommodities, colorStagesLatitudes, true, move],
		[0.475, {...DEFAULT_ROTATION_SKIP_OCEAN, lat: 0, dollyTo: dollyTo * 2, speed: fast}, world, crop, 1, false, numCommodities, colorStagesLatitudes, true, move],
		[0.55, newLocate(countryLocation, dollyTo * 2), world, crop, 2, false, 1, undefined, true, move],
		[0.56, newLocate(countryLocation, dollyTo * 2), world, country, 2, false, 1, undefined, true, move],
		[0.63, newLocate(countryLocation, dollyTo * 2), world, country, 2, true, 1, undefined, true, move],
		[0.65, {...DEFAULT_ROTATION, lat: location[0], speed: fast, dollyTo: dollyTo * 2}, world, white, 3, true, 1, undefined, true, move],
		[0.74, {...DEFAULT_ROTATION, lat: location[0], fast, dollyTo: dollyTo * 2}, world, crop, 4, false, 1, undefined, true, move],
		[0.85, {...DEFAULT_ROTATION, dollyTo: dollyTo * 2}, world, white, 5, false, 1, undefined, true, undefined],
		[0.9, {...DEFAULT_ROTATION_SKIP_OCEAN, dollyTo, speed: fast}, world, noColor, 5, undefined, 1, undefined, true, undefined],
		[1.25, {...DEFAULT_ROTATION_SKIP_OCEAN, dollyTo, speed: fast}, world, noColor, 5, false, 1, undefined, true, undefined],
	];
	if (isPhone) {
		const move = { x: 2.5, y: 2, z: 0 };
		const dollyTo = 7.5;
		const phoneLocation = [countryLocation[0] - 10, countryLocation[1] - 25 ];
		stages = [
			[0, {...DEFAULT_ROTATION_SKIP_OCEAN, dollyTo}, world, white, undefined, false, 1, undefined, true, undefined],
			[0.05, {...DEFAULT_ROTATION_SKIP_OCEAN, dollyTo, lat: 0}, world, white, undefined, false, 1, undefined, false, undefined],
			[0.2, {...DEFAULT_ROTATION, dollyTo: 3, speed: fast, lat: 0}, randomData, white, undefined, undefined, false, undefined, false, undefined],
			[0.48, {...DEFAULT_ROTATION, dollyTo: 1 , speed: fast, lat: 0}, randomData, white, undefined, undefined, false, undefined, false, undefined],
			[0.49, {...DEFAULT_ROTATION, dollyTo, speed: fast, lat: 0}, randomData, white, undefined, undefined, false, undefined, false, undefined],
			[0.5, {...DEFAULT_ROTATION_SKIP_OCEAN, dollyTo, lat: 0, speed: 4 * fast}, world, crop, 1, false, numCommodities, colorStagesLatitudes, true, move],
			[0.55, {...DEFAULT_ROTATION_SKIP_OCEAN, dollyTo, lat: 0, speed: 4 * fast}, world, crop, 1, false, numCommodities, colorStagesLatitudes, true, move],
			[0.57, {...newLocate(phoneLocation, dollyTo)}, world, country, 2, false, 1, undefined, true, move],
			[0.67, {...newLocate(phoneLocation, dollyTo)}, world, country, 2, false, 1, undefined, true, move],
			[0.68, {...newLocate(phoneLocation, dollyTo)}, world, white, 3, true, 1, undefined, true, move],
			[0.78, {...newLocate(phoneLocation, dollyTo)}, world, crop, 4, false, 1, undefined, true, move],
			[0.79, {...newLocate(phoneLocation, dollyTo)}, world, crop, 4, false, 1, undefined, true, move],
			[0.87, {...DEFAULT_ROTATION_SKIP_OCEAN, dollyTo}, world, white, 5, undefined, 1, undefined, true, undefined],
			[1.25, {...DEFAULT_ROTATION_SKIP_OCEAN, dollyTo}, world, white, 5, false, 1, undefined, true, undefined],
		];
	}
	// @ts-ignore
	return stages.map(s => ({
		endScroll: s[0] as number,
		focus: s[1] as FocusType,
		particlesPosition: s[2] as Particles,
		particlesColor: s[3] as Particles,
		chartStage: s[4] as number,
		icons: s[5] as boolean,
		numColorStages: s[6] as number,
		colorStagesLatitudes: s[7] as (number[] | undefined),
		innerSphere: s[8] as boolean,
		position: s[9] as {x?: number, y?: number, z?: number} || undefined
	}));
}

export default BuildStory;