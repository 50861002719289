/* eslint-disable react/no-unknown-property */
import React, { useRef, useEffect } from "react";

import * as THREE from "three";
import { useFrameLimiter } from "./AdvancedFiberParticles"; // Import the custom hook

import { SphereProps } from "./types";
import { getCurrentStageIndex } from "./utils";
import { useSpring, a } from "@react-spring/three";

// Add a sphere centered at the origin with radius 3.5, we use this to help occlude the labels
const Sphere = ({
	offset, 
	offsetTransform, stages,
	...props
} : SphereProps) => {
	const [show, setShow] = React.useState(true);
	const meshRef = useRef<THREE.Mesh>(null);
	// spring to go from 0 to 3.5 based on show
	const { scale } = useSpring({
		scale: show ? 1 : 0,
		config: { tension: 170, friction: 26, clamp: true, mass: 5, duration: 100 },
	});

	useEffect(() => {
		if (!meshRef.current) return;
		meshRef.current.frustumCulled = false;
		meshRef.current.renderOrder = 1;
	}, [meshRef]);

	useFrameLimiter(() => {
		const i = getCurrentStageIndex(stages, offset.current, offsetTransform);
		if (i === -1) return;
		setShow(stages[i].innerSphere);
	}); // Call the custom hook

	return (
		<a.mesh ref={meshRef} scale={scale} visible={true} position={[0, 0, 0]} {...props}>
			<a.sphereGeometry args={[3.49, 32, 32]} />
			<meshPhongMaterial 
				color="#ffffff" 
				transparent 
				opacity={0} 
				depthTest={true} 
				depthWrite={true} 
				side={THREE.DoubleSide}
			/>
		</a.mesh>
	);
};

export default Sphere;