import styled from "styled-components";

const PageContainer = styled.div`
	margin: auto;
`;

const Fixed = styled.div<{$marginX?: string, $marginY?: string, $width: number}>`
	width: ${({ $width }) => $width}px;
	height: 100%; 
	position: fixed;
	z-index: 150;
	pointer-events: none;
	padding: ${({ $marginY, $marginX }) => ($marginY || 0) + " " + ($marginX || 0) + " " + ($marginY || 0) + " " + ($marginX || 0)};
`;

const Content = styled.div`
	background-color: ${({ theme }) => theme.background};
	margin: auto;
	position: absolute;
	width: 100%;
	height: 100%;
    overflow-x: clip; //hides the x axis
	-webkit-scrollbar-display: none !important;
	scrollbar-width: 0 !important;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}
`;

const TopBlock = styled.div<{$height: string}>`
	position: fixed;
	top: 0;
	z-index: 100;
	width: 100%;
	// read from theme
	background-color: ${({ theme }) => theme.background};
	height: ${({ $height }) => $height};
`;

const BottomBlock = styled(TopBlock)<{$height: string}>`
	bottom: 0;
	top: auto;
`;

export { Fixed, Content, PageContainer, TopBlock, BottomBlock };