
interface Theme{
	titleFont?: string;
	textFont?: string;
    background: string;
    color: string;
	textColorSuperLight?: string;
	textColorLight?: string;
	textColor?: string;
	textHighlightColor?: string;
    accentColor?: string;
	contrastColor?: string;
	cardColor?: string;
    // Define other theme properties
	// Colors and props for the world particles
	worldParticles: {
		baseColor: number,
		baseColorFaded?: number,
		economicColor: number,
		vulnerableColor: number,
		disruptedColor: number,
		backgroundColor: number,
	}
}

interface NamedTheme {
	name: string;
	theme: Theme;
}
const _lightTheme: Theme = {
	titleFont: "Manrope",
	textFont: "Manrope",
	background: "#add8e6",
	color: "#000",
	textColorSuperLight: "#000000",
	textColorLight: "#000000",
	textColor: "#000",
	accentColor: "#FF6600",
	contrastColor: "#000220",
	cardColor: "#F5F5F5",
	worldParticles: {
		baseColor: 0x000220,
		baseColorFaded: 0x000220,
		economicColor: 0x000220,
		vulnerableColor: 0x000220,
		disruptedColor: 0xFF6600,  //0xCDFD50 manually adjusted to be more yellow in the world and ver more similar
		//backgroundColor: 0xeaeaea,  // Background color (and color the particles fade to)
		backgroundColor: 0xadd8e6,  // Background color (and color the particles fade to)
	},
};
const lightTheme: NamedTheme = {theme: _lightTheme, name: "light"};
  
const _darkTheme: Theme = {
	titleFont: "Manrope",
	textFont: "Manrope",
	background: "#000220",
	color: "#000",
	textColorSuperLight: "#2A2B45",
	textColorLight: "#a0a3af",
	textColor: "#F6F5F7",
	accentColor: "#CDFD50",
	contrastColor: "#000220",
	cardColor: "#14142e",
	worldParticles: {
		baseColor: 0xB1B2C1,
		baseColorFaded: 0xCCCCCC,
		economicColor: 0xfffff2,
		vulnerableColor: 0xF5ABBD,
		disruptedColor: 0xE7FD50,  //0xCDFD50 manually adjusted to be more yellow in the world and ver more similar
		backgroundColor: 0x000220,  // Background color (and color the particles fade to)
	},
};
const darkTheme: NamedTheme = {theme: _darkTheme, name: "dark"};

export {
	lightTheme,
	darkTheme,
};
export type { Theme, NamedTheme };