/*
A Fragment Shader is the Shader stage that will process a Fragment 
generated by the Rasterization into a set of colors and a single 
depth value.

Built ins
  gl_FragColor: output color we want for each vertex
*/

const fragmentShader = () => {
	
	return `
  varying vec3 v_position;
  varying vec4 v_color;
  varying float v_dist;
  uniform sampler2D pointTexture;
  uniform float uTime;
  uniform float toHighlight;
  
  void main() {    

  vec3 finalColor = v_color.xyz;
    float highlightFlag = v_color.a;
    if (highlightFlag != toHighlight && highlightFlag != 0.0 && highlightFlag >= 0.0) {
      finalColor = vec3(0.6038273);;
    }

    // make any dot at (0, 0, 0) fully transparent - We do this because points not 
    // properly position seem to go to 0, 0, 0 and I don't fully know how to remove them
    // Make any dot at (0, 0, 0) fully transparent
    float alpha = step(0.0, length(v_position));
    
    // Fade and apply round texture
    gl_FragColor = vec4(finalColor, alpha) * texture2D( pointTexture, gl_PointCoord );
  }
  `;
};

export default fragmentShader;
