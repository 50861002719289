import * as THREE from "three";

// Functions which builds the OpenGL code dynamically based on the transitions
const buildShader = (
	time: string, // definition of time, can be uTime or uScroll or a function of those.
	stages: string[],
	//textureUniforms: { [Key: string]: { value: THREE.DataTexture } },
	transitions: number[],
) => {
	/**
	 * This is the shader that will be run on the GPU, is dynamically built based on the
	 * number of positions and transitions, so it can be used for any number of positions
	 * THe transitions will be from position[i] to position[i+1] for the scroll value of
	 * transitions[i]
	 * transitions must be of length 1 less than the number of positions
	 */
	// Create the uniforms for the positions as uniform Sampler2D
	const uniqueTextures = Array.from(new Set(stages));
	const uniformVariables = uniqueTextures.map(
		(p) => `uniform sampler2D ${p};`
	).join("\n\t");

	// function to mix arrays or not if they are the same 
	const mixer = (a: string, b: string, p: number, d: number) => {
		if (a === b) {
			return `${a}v3;`;
		}
		return `mix(${a}v3, ${b}v3, (time - ${p.toFixed(3)}) * ${d.toFixed(3)});`;
	};

	// Create the string that passes the texture to the vertex shader as a vec3 merged with the vUv
	const varsToV3 = uniqueTextures.map((p) => `vec3 ${p}v3 = texture2D(${p}, vUv).rgb;`).join("\n\t\t");
	const varsToV3H = uniqueTextures.map((p) => `float ${p}h = texture2D(${p}, vUv).a;`).join("\n\t\t");

	// Generate the if else blocks for the transitions, which covers all the positions between 1 and i - 1
	const elseIfBlock = transitions
		.slice(1, -1)
		.map((t, i) => {
			const prev = stages[i + 1];
			const next = stages[i + 2];
			const prevT = transitions[i];
			const div = (1 / (t - transitions[i]));
			return `else if ( time <= ${t.toFixed(3)} ) {
			vec3 pos = ${mixer(prev, next, prevT, div)}
			float h = ${prev}h;
			gl_FragColor = vec4(pos, h);
		}`;
		})
		.join("\n\t\t");

	// Generate the else block for the final position
	const finalPos = transitions.length;
	const lts = transitions.slice(-2);
	const finalT = lts[0];
	const diff = (1 / (lts[1] - lts[0]));
	const elseBlock = `else {
			vec3 pos = ${mixer(stages[finalPos - 1], stages[finalPos], finalT, diff)}
			float h = ${stages[finalPos - 1]}h;
			gl_FragColor = vec4(pos, h);
		}`;

	// Builds the OpenGL code
	return `
	// These first 3 are always needed
	uniform float uTime;
	uniform float uScroll;
	varying vec2 vUv;

	// These are the textures that hold the positions and are dynamic based on 
	// the positions array
	${uniformVariables}

	void main() {
		// defines what time is, could be based on scroll or actual time
		// It always has to be a value from 0 to 1
		float time = ${time};

		// defines the position of the vertex, add v3 to the variable name as we need different ones
		${varsToV3}
		${varsToV3H}
	
		// positions the vertexes based on the scroll value
		// do the first one manually as it is different
		if ( time <= ${transitions[0]} ) {
			vec3 pos = ${mixer(stages[0], stages[1], 0, 1 / transitions[0])}
			float h = ${stages[0]}h;
			gl_FragColor = vec4(pos, h);
		} ${elseIfBlock} ${elseBlock}
	}
	`;
};

const prepareTexture = (data: Float32Array, size: number) => {
	const texture = new THREE.DataTexture(data, size, size, THREE.RGBAFormat, THREE.FloatType);
	texture.needsUpdate = true;
	return texture;
};

const prepareTextures = (data:  { particles: Float32Array; id: string; }[], size: number) => {
	const uniforms: { [Key: string]: { value: THREE.DataTexture } } = {};
	// same if might appear multiple times, if the data does not match it will just overwrite it 
	// and keep the last one
	data.forEach(d => {
		uniforms[d.id] = { value: prepareTexture(d.particles, size) };
	});
	return uniforms;
};

export { buildShader, prepareTextures };
