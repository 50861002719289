import { Action } from "redux";
import { createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { UserState, Share } from "store/types/userTypes";
import { AxiosInstance, AxiosResponse } from "axios";
import { DeltaShare } from "tema-ai-components";
import { RootState } from "../store"; // Adjust the import path based on your project structure

type Headers = Record<string, string>;
type Key = {
	client_id: string,
	client_secret_start: string,
	connection: string,
	expiration_date: string,
	last_used_date: string,
};
type Connection = {
	name: string;
	created_at: string;
	keys: Key[]
};

type Response<N extends string, T> = {
	data: {
		data: {
			message: string;
		} & {
			[key in N]: T;
		};
		status: {
			completed_at: string;
			progress: string;
			requested_at: string;
		}
	}
}

const fetch = async <N extends string, T>(
	instance: AxiosInstance, 
	headers: Headers,
	method: "GET" | "POST" | "PUT" | "DELETE",
	url: string,
): Promise<AxiosResponse<Response<N, T>>> => {
	return instance.request({
		method,
		url,
		headers,
	});
};

const fetchDeltaConnections = async (instance: AxiosInstance, headers: Headers) => {
	return fetch<"connections", Record<string, Connection>>(instance, headers, "GET", "/account/connections");
};

const fetchDeltaShares = async (instance: AxiosInstance, headers: Headers) => {
	return fetch<"shares", Share[]>(instance, headers, "GET", "/account/shares");
};

const fetchVerification = async (instance: AxiosInstance, headers: Headers) => {
	return fetch<"shares", string>(instance, headers, "GET", "/account/verify");
};

const TransformDeltaShareConnections = (deltaShare: Connection[]): DeltaShare.ConnectionType[] => {
	/**	
	* Converts the Connection API response to a more usable format
	* - converts the connections into a list
	* - 
	*/
	return deltaShare.map(connection => {
		return {
			name: connection.name,
			createdAt: connection.created_at,
			keys: connection.keys.map((key) => {
				return {
					clientId: key.client_id,
					clientSecret: key.client_secret_start,
					lastUsedDate: key.last_used_date,
					expirationDate: key.expiration_date,
				};
			}),
		};
	});
};

type ThunkProps = { instance: AxiosInstance, headers: Headers, silent?: boolean };

export const getDeltaShare = createAsyncThunk<
	UserState, 
	ThunkProps,
	{ rejectValue: string}
>(
	"user/getDeltaShare",
	async ({ instance, headers }, thunkAPI) => {
		try {
			// Fetch connections and shares in parallel
			const responses = await Promise.all([
				fetchDeltaConnections(instance, headers),
				fetchDeltaShares(instance, headers)
			]);
			const [connectionsResponse, sharesResponse] = responses.map(resp => resp.data.data);

			// @ts-ignore
			const connections = TransformDeltaShareConnections(connectionsResponse.connections);
			// @ts-ignore
			const shares = sharesResponse.shares;
			return {
				deltaShare: {
					allowed: true,
					connections,
					shares,
				},
			} as UserState;
		} catch (error) {
			return thunkAPI.rejectWithValue("Access to  deltaShare failed");
		}
	}
);

type AppDispatch = ThunkDispatch<RootState, void, Action>;
export const getDeltaShareSilent = ({ instance, headers }: ThunkProps) => (
	dispatch: AppDispatch
) => {
	return dispatch(getDeltaShare({ instance, headers, silent: true }));
};

export const verifyDeltaShare = createAsyncThunk<
	boolean, 
	ThunkProps,
	{ rejectValue: string}
>(
	"user/verifyDeltaShare",
	// @ts-ignore
	async ({ instance, headers }, thunkAPI) => {
		try {
			// Fetch connections and shares in parallel
			// If it doesn't throw an error, the user has access to DeltaShare
			await fetchVerification(instance, headers);
			return true;
		} catch (error) {
			return thunkAPI.rejectWithValue("Access to  deltaShare failed");
		}
	}
);