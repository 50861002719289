import React from "react";
import { Parallax } from "tema-ai-components";
import Grid, { size, useGrid } from "Components/Reusable/Grid";
import RegisterForm from "Components/Reusable/RegisterUser";
import { HOME } from "Components/Pages/Main/copy";
import { LineBlock } from "Components/Pages/Main/Utils";
import {
	TitleGridItem,
	StyledTitle,
	SubTitleGridItem,
	RegisterGridItem
} from "../Blocks/Home/styles";

// Title component
const Title = () => {
	return (
		<TitleGridItem>
			<StyledTitle>
				{HOME.title}
			</StyledTitle>
		</TitleGridItem>
	);
};


// Main Home component
const Home = ({
	start,
	end,
	overflow = 0,
}: {
    start: number,
    end: number,
    overflow?: number,
}) => {
	const { gap, width } = useGrid();

	return (
		<>
			<Parallax.Layer className="home__first__layer" sticky={{ start, end }}>
				<Grid>
					<Title />
				</Grid>
			</Parallax.Layer>
			<Parallax.Layer className="home__second__layer" sticky={{ start, end: end * (1 + overflow) }}>
				<Grid>
					<SubTitleGridItem style={{ gap }}>
						<LineBlock text={HOME.subtitle} />
					</SubTitleGridItem>
					<RegisterGridItem $mobile={true}>
						<div style={{ height: width < size.tablet ? "85px" : "40px" }}>
							<RegisterForm mobile={width < size.tablet} />
						</div>
					</RegisterGridItem>
				</Grid>
			</Parallax.Layer>
		</>
	);
};

export default Home;