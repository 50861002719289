import React from "react";
import { useTheme } from "Context";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";

const Text = styled.text`
    font-family: "Inter", sans-serif;
    font-weight: 200;
    text-anchor: middle;
    fill: ${({ theme }) => theme.accentColor};
    font-size: 1em;
    text-transform: uppercase;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
    background-color: #000220;
    position: fixed;
    top: 0;
    left: 0;
`;
type BaseProps = {
	x: number;
    y: number;
    rotation?: number;
};

interface TextBlockProps extends BaseProps {
	device: Device;
}
interface IphoneProps extends BaseProps {
    width: number;
    color: string;
}
interface DeviceProps extends BaseProps {
	device: Device;
	width: number;
    color: string;
}

const Tablet = ({x, y, width, color, rotation = 0} : IphoneProps) => {
	const tabletRatio = 1.5;
	const height = width * tabletRatio;
	const margin = width * 0.035;
	const props = { x: 0, y: 0 };
	return (
		<g transform={`rotate(${rotation}, ${x}, ${y}) translate(${x - width / 2}, ${y - height/2})`}>
			<rect 
				{...props}
				width={width}
				height={height}
				stroke={color}
				strokeWidth={0.5}
				rx={width / 6}
				ry={width / 6}
				fill="transparent"
			/>
			<rect 
				{...props}
				width={width - 2 * margin}
				height={height - 2 * margin}
				transform={`translate(${margin}, ${margin})`}
				stroke={color}
				strokeWidth={0.5}
				rx={(width / 6) - margin}
				ry={(width / 6) - margin}
				fill="transparent"
			/>
		</g>
	);
};

const IPhone = ({x, y, width, color, rotation = 0} : IphoneProps) => {
	const phoneRatio = 2.062;
	const height = width * phoneRatio;
	const notchSize = width * 0.15;
	const props = { x: 0, y: 0 };
	const notchHeight = notchSize / 2.5;
	const notchProps = {
		...props, 
		height: notchHeight, 
		fill: color, 
		strokeWidth: 0,
		rx: notchHeight / 2,
		ry: notchHeight / 2
	};
	return (
		<g transform={`rotate(${rotation}, ${x}, ${y}) translate(${x - width / 2}, ${y - height/2})`}>
			<rect 
				{...props}
				width={width}
				height={height}
				stroke={color}
				strokeWidth={0.5}
				rx={width / 6}
				ry={width / 6}
				fill="transparent"
			/>
			<g transform={`translate(${width/2}, ${notchSize / 3})`}>
				<rect 
					{...notchProps}
					width={notchSize} 
					transform={`translate(${-notchSize/1.2}, ${0})`}
				/>
				<rect 
					{...notchProps}
					width={notchHeight} 
					transform={`translate(${+notchSize/2.5}, ${0})`}
				/>
			</g>
					
		</g>
	);
};

const Device = ({device, ...props} : DeviceProps) => {
	if (device === "phone") {
		return <IPhone {...props}/>;
	}
	return <Tablet {...props} width={props.width * 2}/>;

};

const AnimatedDevice = animated(Device);

const TextBlock = ({x, y, rotation=0, device} : TextBlockProps) => {
	const modRotation = device === "phone" ? rotation : -rotation + 90;
	const props = { x, y, dominantBaseline: "middle"};
	return (
		<g transform={`rotate(${modRotation}, ${x}, ${y})`}>
			<Text {...props} dy="-1.2em">Rotate</Text>
			<Text {...props} >your</Text>
			<Text {...props} dy="1.2em">{device == "phone" ? "phone" : "tablet"}</Text>
		</g>
	);
};

type Device = "phone" | "tablet";

const RotatePrompt = ({ angle, device } : { angle: number, device: Device }) => {
	const { theme } = useTheme();
	const isPhone = device === "phone";
	const finalRotation = isPhone ? 
		(angle > 180 ? -360 : 0): 
		(angle > 180 ? 360 : 0); 
	const { rotation } = useSpring({
		to: { rotation: finalRotation },
		from: { rotation: isPhone ? -angle : angle},
		config: { mass: 1, tension: 70, friction: 10},
		delay: 500,
	});
    
	return (
		<Container>
			<svg 
				xmlns="http://www.w3.org/2000/svg" 
				viewBox="0 0 200 100" 
				fill="white" 
				width="95%"
				style={{transform: `rotate(${isPhone ? 0 : -90}deg)`}}
			>
				<AnimatedDevice x={50} y={50} width={30} color={theme.accentColor} rotation={rotation.to(r => r)} device={device}/>
				<line x1="100" y1="0" x2="100" y2="100" stroke={theme.accentColor} strokeWidth={0.5}/>
				<TextBlock x={140} y={50} rotation={-angle} device={device}/>
			</svg>
		</Container>
	);
};

export default RotatePrompt;
export type {Device};