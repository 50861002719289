import { useState, useEffect } from "react";
import { useSpring } from "react-spring";

const useRetractileNavBar = ({
	showAfter = 10,
	hideAfter = 850,
	navBarHeight = 80,
	hideAfterScroll = 150 
} : {
	showAfter: number,
	hideAfter?: number,
	navBarHeight: number,
	hideAfterScroll?: number
}) => {
	const [show, setShow] = useState<boolean>(true);
	const [frozen, setFrozen] = useState<boolean>(false);
	const [couldObstruct, setCouldObstruct] = useState<boolean>(false);

	const height = navBarHeight;

	const styles = useSpring({
		top: show ? `0px` : `-${navBarHeight}px`,
		from: {
			bottom: show ? `-${navBarHeight}px` : `0px`,
		},
	});

	useEffect(() => {
		const threshold = showAfter;
		let lastScrollY = window.scrollY;
		let ticking = false;
		let accumulatedScroll = 0;  // Accumulate scroll distance

		const updateScrollDir = () => {
			if ( hideAfter === Infinity ) return;
			const scrollY = window.scrollY;
			const scrollDelta = scrollY - lastScrollY;

			// Accumulate the scroll distance
			accumulatedScroll += Math.abs(scrollDelta);

			if (scrollY < height + navBarHeight + hideAfter) {
				ticking = false;
				setShow(true);
				setCouldObstruct(false);
			} else if (Math.abs(scrollY - lastScrollY) < threshold) {
				ticking = false;
			} else {
				if (accumulatedScroll >= hideAfterScroll) {
					setShow(!(scrollY > lastScrollY));
					accumulatedScroll = 0;  // Reset accumulated scroll
				}
				setCouldObstruct(true);
				lastScrollY = scrollY > 0 ? scrollY : 0;
				ticking = false;
			}
		};

		const onScroll = () => {
			if (!ticking && !frozen && hideAfter !== Infinity) {
				window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [show, frozen, hideAfterScroll, navBarHeight, hideAfter, height]);

	return {
		styles,
		show,
		frozen,
		setFrozen,
		displayNavBar: () => setShow(true),
		hideNavBar: () => setShow(false),
		freezeNavBar: () => {
			setShow(true);
			setFrozen(true);
		},
		unfreezeNavBar: () => setFrozen(false),
		couldObstruct
	};
};

export default useRetractileNavBar;