type ScreenDebouncer = {
    debounceValue: number,
    debounceTime: number,
    duration: number
};

interface ConfigContextProps {
    config: {
        screenDebouncer: ScreenDebouncer,
        mobileScreenDebouncer: ScreenDebouncer,
        phoneBreak: number,
        maxScreenHeight: number

    }
}

const Config = {
	config: {
		screenDebouncer: {
			debounceValue: 0,
			debounceTime: 0,
			duration: 0
		},
		mobileScreenDebouncer: {
			debounceValue: 5000,
			debounceTime: 5000,
			duration: 5000
		},
		phoneBreak: 768,
		maxScreenHeight: 1200
	}
};

export {
	Config
};
export type {
	ConfigContextProps
};