import React from "react";
import { Utils } from "tema-ai-components";
import styled, { css } from "styled-components";
import { useGrid } from "Components/Reusable/Grid";
import { CardTitle, ContentCSS } from "Styles";
import { animated, useSpring, config } from "react-spring";

const CardCSS = css`
	background-color: rgba(255, 255, 255, 0.08);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 4px;
	border: 1px solid rgba(255, 255, 255, 0.06);
`;

const Text = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	p {
		margin: 0;
		padding: 0;
	}
	height: 40px;
`;

const StyledCard = styled.div<{ $gap: number, $cellWidth: number }>`
	${ CardCSS }
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 10px;
	.container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		gap: ${( {$gap} ) => $gap }px;

		.content-block {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 20px;
			padding-right: 25px;
			.title-block {
				.tags,
				.title {
					${ CardTitle }
					color: ${({ theme }) => theme.textColor};
					${ Text }
				}
				.tags {
					height: 20px;
					color: ${({ theme }) => theme.textColor} ;
				}
			}
			.content {
				color: ${({ theme }) => theme.textColorLight};
				${ ContentCSS }
			}
		}	
	}
`;

type BaseCardProps = {
	number?: number,
    title: string,
    tags: string[],
    content: string,
	pos?: number
};

const BaseCard = ({
	title,
	tags,
	content,
	pos
} : BaseCardProps) => {
	const { cellWidth, gap } = useGrid();
	return (
		<StyledCard style={{
			transform: `translateX(${pos}px)`,
		}} $gap={gap} $cellWidth={cellWidth}>
			<div className="container">
				<div className="content-block">
					<div className="title-block">
						<div className="title"><p>{title}</p></div>
						<div className="tags">{tags.map(tag => <p key={tag}>{tag}</p>)}</div>
					</div>
					<div className="content">{content}</div>
				</div>
			</div>
		</StyledCard>
	);
};

const AnimatedBaseCard = animated(BaseCard);

type Position = "left" | "right" | "show";
interface CardProps extends BaseCardProps {
	position?: Position
}
const Card = ({
	title,
	tags,
	content,
	position="show"
} :CardProps) => {
	const { width } = Utils.useWindowDimensions();
	const positioner = {
		show: 0,
		left: -width,
		right: width,
	}[position];

	// @ts-ignore
	const { pos } = useSpring({
		pos: positioner,
		config: config.default,
	});

	return (
		<AnimatedBaseCard 
			pos={pos} 
			title={title}
			tags={tags}
			content={content}
		/>
	);
};

export default Card;
export {
	CardCSS
};