import React, { createContext, useState, useContext, useEffect } from "react";
import { COMMODITY_STORY, CommodityStoryItemExpanded } from "./stories";
import data from "./data.json";


const unique = (arr: string[]) => {
	// remove duplicates
	const set = new Set(arr);
	// convert back to array
	return Array.from(set);
};

interface StoryContextProps {
    story: CommodityStoryItemExpanded;
	world: string[];
	commodityMask: string[][];
	countryMask: string[];
	countryLocation?: [number, number];
    refresh: () => void;
}

const defaultContextData: StoryContextProps = {
	story: undefined,
	world: undefined,
	commodityMask: undefined,
	countryMask: undefined,
	countryLocation: undefined,
	refresh: () => {
		//noop
	},
};

const Context = createContext<StoryContextProps>(defaultContextData);

const useStory = () => useContext(Context);

const ContextProvider = ({ children } : { children: React.ReactNode }) => {

	const [story, setStory] = useState<CommodityStoryItemExpanded>();
	const [commodityMask, setCommodityMask] = useState<string[][]>([]);
	const [countryMask, setCountryMask] = useState<string[]>([]);
	const [world, setWorld] = useState<string[]>([]);
	
	const refresh = () => {
		const keys = Object.keys(COMMODITY_STORY);
		const key = keys[Math.floor(Math.random() * keys.length)];
		setStory(COMMODITY_STORY[key]);
	};

	useEffect(() => refresh(), []);
	useEffect(() => setWorld(unique(data.map(d => d.hex_ids))), []);
	useEffect(() => {
		if (!story) return;
		const country = data.filter(d => d.country === story.finalCountry).map(d => d.hex_ids);
		setCountryMask(unique(country));
	}, [story]);

	useEffect(() => {
		if (story) {
			const mask: string[][] = [];
			story.globalCommodities.forEach(commodity => {
				const commodityKey = commodity as keyof typeof data[0];
				const hexs = unique(data.filter(d => d[commodityKey] === 1).map(d => d.hex_ids));
				mask.push(hexs);
			});
			setCommodityMask(mask);
		}
	}, [story]);

	return (
		<Context.Provider value={{ 
			story, 
			refresh,
			world, 
			commodityMask, 
			countryMask, 
			countryLocation: story?.countryLocation || [0, 0]
		}}>
			{children}
		</Context.Provider>
	);
};

export {
	ContextProvider as StoryContextProvider,
	useStory,
};