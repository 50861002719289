import React from "react";
import styled from "styled-components";
import { Parallax, Cards } from "tema-ai-components";
import { useGrid, device } from "Components/Reusable/Grid";
import { ParallaxLayerSectionName } from "./Section";
import { FEATURES } from "../copy";
import { TitleCSS, ContentCSS, CardTitle } from "Styles";


type DeckProps = {
    start: number,
    end: number
}

const CardContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Inter;
	height: 100%;
	width: 100%;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	`;


const CardsTitle = styled.div<{$left: number, $width: number, $height: number}>`
	margin-left: ${props => props.$left}px;
	width: ${({ $width }) => $width}px;
	height: ${({ $height }) => $height}px;
	${ TitleCSS }
`;

const StyledCard = styled(Cards.BasicCard)`
	justify-content: flex-start;
	gap: 10px;
	padding: 30px 15px 30px 15px;
	.title {
		p {
			${ CardTitle }
			margin: 0;
			padding: 0;
		}
	}
	.tags {
		.tag {
			${ CardTitle }
			color: ${({ theme }) => theme.accentColor };
		}
	}
	.content {
		color: ${({ theme }) => theme.textColorLight };
		p {
			${ ContentCSS }
			margin: 0;
			padding: 0;
		}
	}

	@media ${device.laptopM} {
		gap: 10px;
		padding: 20px 10px 20px 10px;
	}

`;


const Features = ({start, end}: DeckProps) => {
	const { getColStart, cellWidth, gap, cellHeight, marginY, getRowStart } = useGrid();
	const cardSizes = Array.from({length: 4}, () => `${cellWidth * 3 + gap * 2}px`);
	const colStarts = [0, 3, 6, 9];
	const cardLefts = colStarts.map((c: number) => `${getColStart(c)}px`);
	return (
		<>
			{/* @ts-ignore */}
			<Parallax.Layer sticky={{ start, end: end + 0.4, top: `${getRowStart(1)}px` }}>
				<CardsTitle 
					$left={getColStart(0)} 
					$width={6 * (cellWidth + gap) + cellWidth}
					$height={2 * cellHeight + gap}
				>{ FEATURES.title }</CardsTitle>
			</Parallax.Layer>
			{/* @ts-ignore */}
			<ParallaxLayerSectionName start={start} end={end + 0.4}>
				{ FEATURES.sectionName }
			</ParallaxLayerSectionName>
			<Parallax.Deck 
				cards={FEATURES.cards.map((card, i) => 
					<CardContainer key={i}>
						<StyledCard 
							title={card.title}
							tags={card.tags}
							content={card.content}
							chartHeight={0}
							gap={gap}
						/>
					</CardContainer>
				)}
				cardSizes={cardSizes}
				cardLefts={cardLefts}
				distance={`${marginY}px`}
				stickTo="bottom"
				height={`${cellHeight * 3 + 2 * gap}px`}
				start={start + (end - start) * 0.25}
				end={end}
			/>
		</>
	);
};

export default Features;