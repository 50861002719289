import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "store";


import { googleAnalytics } from "Components/Reusable/Utils";
import { AxiosInstanceProvider } from "Components/Reusable/api";
import { ThemeProvider } from "Context";

const {
	REACT_APP_GA_ID,
	REACT_APP_RECAPTCHA,
	REACT_APP_API,
	REACT_APP_SHARE_API
} = process.env;
googleAnalytics.initialize(REACT_APP_GA_ID);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
	<GoogleReCaptchaProvider
		reCaptchaKey={REACT_APP_RECAPTCHA}
		language="en"
		useRecaptchaNet={false}
		useEnterprise={false}
		scriptProps={{
			async: false, // optional, default to false,
			defer: false, // optional, default to false
			appendTo: "head", // optional, default to "head", can be "head" or "body",
			nonce: undefined // optional, default undefined
		}}
	>
		<React.StrictMode>
			<AxiosInstanceProvider config={{ 
				default: {baseURL: REACT_APP_API}, 
				shareAPI: {baseURL: REACT_APP_SHARE_API} 
			}}>
				<Provider store={store}>
					<ThemeProvider>
						<App />
					</ThemeProvider>
				</Provider>
			</AxiosInstanceProvider>
		</React.StrictMode>
	</GoogleReCaptchaProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
