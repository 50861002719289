import React, { useMemo, useState, useEffect, useLayoutEffect, useRef } from "react";
import styled from "styled-components";

const LoaderContainer = styled.div`
	width: 100%; /* Adjust the width according to your needs */
    height: 100%; 
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const getPositions = (cx: number, cy: number, side: number) => {
	const diag = Math.cos(Math.PI / 4) * (side * 1.1);
	return [
		[cx, cy],
		[cx - diag, cy + diag],
		[cx - 2 * diag, cy],
		[cx - diag, cy - diag],
		[cx, cy - 2 * diag],
		[cx + diag, cy - diag],
		[cx + 2 * diag, cy],
		[cx + diag, cy + diag],
	].map(([x, y], i) => [Math.round(x), Math.round(y), i]) as [number, number, number][];
};

const Square = ({x, y, size, i} : {x: number, y: number, size: number, i: number}) => {
	return (
		<g >
			<rect 
				//x={x- size/ 2} 
				//y={y - size / 2}
				width={size} 
				height={size}
				fill="white" 
				transform={`rotate(45, ${x}, ${y})`}
				rx={size / 10}
				style={{
					transform: `translate(${x - size/ 2}px, ${y - size/ 2}px) rotate(45deg)`,
					transformOrigin: `${size / 2}px ${size / 2}px`, // Center the rotation
					transition: "transform 0.7s ease",
				}}
			/>
		</g>
	);
};

const decrementModular = (value: number, min: number, max: number) => {
	const newValue = value - 1;
	if (newValue < min) return max;
	return newValue;
};

const Loader = ({
	principalColor="#14142e",
	secondaryColor="#14142e"//"#CDFD50",
}:{
  principalColor?: string,
  secondaryColor?: string,
}) => {
	const [currentPosition, setCurrentPosition] = useState<[number, number, number][]>([]);
	const currentRef = useRef(7);
	// unique id for mask 
	const maskId = useMemo(() => `mask-${Math.random().toString(36).slice(2, 9)}`, []);
	const gradientId = useMemo(() => `gradient-${Math.random().toString(36).slice(2, 9)}`, []);    
	const allPositions = useMemo(() => getPositions(50, 50, 20), []);
	useLayoutEffect(() => {
		currentRef.current = 7;
		setCurrentPosition(allPositions.slice(0, 7));
	}, []);
	
	useEffect(() => {
		const interval = setInterval(() => {
			// This is the only value that changes
			const next = decrementModular(currentRef.current, 0, 7);
			const newPos = currentPosition.map((p, i) => {
				if (i === next) {
					const newValue = allPositions.find((a) => a[2] === (p[2] + 1) % allPositions.length);
					return newValue;
				}
				return p;
			});

			setCurrentPosition(newPos);
			currentRef.current = next;
		}, 250);
		return () => clearInterval(interval);
	}, [currentPosition]);
  
	return (
		<LoaderContainer>
			<svg height="100%" width="100%" viewBox="0 0 100 100">
				<defs>
					<linearGradient id={gradientId} x1="20%" y1="80%" x2="80%" y2="20%">
						<stop
							offset="0%"
							style={{ stopColor: principalColor, stopOpacity: 1 }}
						>
							<animate
								attributeName="stop-color"
								values={`${principalColor}; ${secondaryColor}; ${principalColor}`}
								dur="5s"
								repeatCount="indefinite"
							/>
						</stop>
						<stop
							offset="100%"
							style={{ stopColor: principalColor, stopOpacity: 1 }}
						>
							<animate
								attributeName="stop-color"
								values={`${principalColor}; ${secondaryColor}; ${principalColor}`}
								dur="5s"
								repeatCount="indefinite"
							/>
						</stop>
					</linearGradient>
					<mask id={maskId}>
						{currentPosition.map((p, i) => <Square 
							key={i} 
							x={p[0]} 
							y={p[1]} 
							size={20}
							i={i}
						/>)}
					</mask>
				</defs>
				<rect width="100%" height="100%" fill={`url(#${gradientId})`} mask={`url(#${maskId})`} />
			</svg>
			<p>Verifying access</p>
		</LoaderContainer>
	);
};

export default Loader;