import React, { RefObject, useMemo } from "react";
import ParticlesTexture from "../ParticlesTexture";
import { Story } from "./types";
import * as THREE from "three";


const FBOParticles = ({
	offset,
	story,
	size=256,
	baseColor,
	backgroundColor,
	offsetTransform = (o: number) => o,
	highlightFactor = 15,
	baseHighlightFactor = 10,
	particleSize = 5,
	interval = 3,
	groupRef
}: {
	offset: RefObject<number>;
    story: Story;
	size?: number;
	baseColor: number | string;
	backgroundColor: number | string;
	offsetTransform?: (o: number) => number;
	highlightFactor?: number;
	baseHighlightFactor?: number;
	particleSize?: number;
	interval?: number;
	groupRef?: RefObject<THREE.Mesh>;
}) => {
	const data = useMemo(() => {
		const items = story.stages;
		// take all but hte last one
		const transitions = items.map(item => item.endScroll).slice(1);
		// keep all but the first element of transitions
		const positions = items.map(item => item.particlesPosition);
		const colors = items.map(item => item.particlesColor);
		const focus = items.map(item => ({ 
			scroll: item.endScroll, 
			method: item.focus,
			numColorStages: item.numColorStages,
			colorStagesLatitudes: item.colorStagesLatitudes
		}));
		
		return { transitions, positions, colors, focus };
	}, []);
	return (
		//@ts-ignore
		<group>
			<ParticlesTexture
				size={size}
				// focus the world on 
				focus={data.focus}
				// Transitions for positions
				stateTransitions={data.transitions}
				// Particle positions
				particleStates={data.positions}
				colorTransitions={data.transitions}
				particleColors={data.colors}
				baseColor={baseColor}
				fadeToColor={backgroundColor}
				offset={offset}
				offsetTransform={offsetTransform}
				highlightFactor={highlightFactor}
				baseHighlightFactor={baseHighlightFactor}
				particleSize={particleSize}
				interval={interval}
				groupRef={groupRef}
			/>
		</group>
	);
};

export default FBOParticles;