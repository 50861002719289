import React, { useMemo } from "react";
import Social from "../Social";
import CornerButton from "../CornerButton";

/**
 * OptionalComponents component.
 * This functional component conditionally renders the Social and CornerButton components based on the provided props.
 * 
 * @param {Object} props - Component properties
 * @param {boolean} props.showSocial - Flag to show/hide the social component
 * @param {boolean} props.showCornerButton - Flag to show/hide the corner button
 */
const Footer = ({
	showSocial,
	showCornerButton,
	style,
	onClickCornerButton
}: {
    showSocial: boolean,
    showCornerButton: boolean,
	style?: object,
	onClickCornerButton?: () => void
}) => {
	const social = useMemo(() => <Social show={showSocial} style={style}/>, [showSocial]);
	const scroll = useMemo(() => <CornerButton 
		text="Scroll" 
		angle={180} 
		show={showCornerButton} 
		style={style}
		onClick={onClickCornerButton && onClickCornerButton}
	/>, [showCornerButton]);
	return (
		<>
			{showSocial && social}
			{showCornerButton && scroll}
		</>
	);
};

export default Footer;