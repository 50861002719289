import React from "react";
import styled from "styled-components";
import { Parallax, Utils, Cards } from "tema-ai-components";
import { useGrid } from "Components/Reusable/Grid";
import { ParallaxLayerSectionName } from "./Section";
import { TECHNOLOGY } from "Components/Pages/Main/copy";
import { TitleCSS, ContentCSS } from "Styles";


const Title = styled.div<{$selected: boolean, $gap: number}>`
	height: 100%;
	${ TitleCSS }
	color: ${({ $selected, theme }) => $selected ? theme.textColor : theme.textColorSuperLight};
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	pointer-events: all;
	div {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		span.number {
			color: ${props => props.$selected ? props.theme.accentColor : props.theme.textColorSuperLight};
			font-size: 20px;
			font-weight: 500;
			width: ${props => props.$gap}px;
		}
	}
`;

type ContentTitleProps = {
	selected: boolean;
	item: {
		title: string;
		selectedTitle?: string;
	};
	i: number;
	onClick?: () => void;
};

const ContentTitle = ({
	selected, 
	item,
	i,
	onClick
}: ContentTitleProps) => {
	const { cellWidth, gap } = useGrid();
	return (
		<Title $selected={selected} $gap={cellWidth + gap} onClick={onClick}>
			<div>
				<span className="number">0{i + 1}/</span>
				<span>
					<Utils.TypewriterText 
						text={selected ? (item.selectedTitle || item.title) : item.title} 
						speed={10} 
					/>
				</span>
			</div>
		</Title>
	);
};

const ContentContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	pointer-events: none;
`;

const Content = styled.div<{$leftMargin: number, $width: number}>`
	width: ${props => props.$width}px;
	${ ContentCSS }
	color: ${({ theme }) => theme.textColorLight};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-left: ${props => props.$leftMargin}px;
`;

const LineCardContainer = styled.div<{$width: number, $marginLeft: number}>`
	margin-left: ${({$marginLeft}) => $marginLeft }px;
	width: ${props => props.$width}px;
	display: flex;
	justify-content: flex-end;
	.cardContainer {
		width: 100%;
		.title {
			${ ContentCSS }
			color: ${({ theme }) => theme.textColor};
			font-weight: 700;
		}
		.content {
			${ ContentCSS }
			color: ${({ theme }) => theme.textColorLight};
		}
	}
`;

const StyledCard = styled(Cards.LineCard)`
	${ ContentCSS }
	color: ${({ theme }) => theme.textColorLight};
`;

const Card = ({
	title, 
	content, 
	width, 
	marginLeft,
	gap
} : {
	title: string, 
	content: string, 
	width: number, 
	marginLeft?: number,
	gap?: number
}) => {
	return (
		<LineCardContainer $width={width} $marginLeft={marginLeft}>
			<div className="cardContainer">
				<StyledCard
					title={title} 
					content={content} 
					gap={gap}
					hideLine
				/>
			</div>
		</LineCardContainer>
	);
};


type ContentBlockProps = {
	text: string;
	lineTitle?: string;
	lineContent?: string;
};

const ContentBlock = ({
	text,
	lineTitle, 
	lineContent,
} : ContentBlockProps) => {
	const { cellWidth, gap } = useGrid();
	const leftContentLeftMargin = cellWidth + gap;
	// WIDTH IN CELLS
	const pos = 2;
	const leftContentWidth = pos * (gap + cellWidth) + cellWidth;
	return <ContentContainer>
		<Content $leftMargin={leftContentLeftMargin} $width={leftContentWidth}>
			<p>{text}</p>
		</Content>
		{lineTitle && <Card 
			width={2 * (cellWidth + gap) + cellWidth} 
			marginLeft={(pos + 1) * cellWidth + (pos + 2) * gap}
			title={lineTitle} 
			content={lineContent}
			gap={gap}
		/>}
	</ContentContainer>;
};

const Accordion = ({
	start, 
	end, 
	offsetRef,
	parallaxRef
} : {
	start: number, 
	end: number, 
	offsetRef: React.RefObject<number>,
	parallaxRef: React.RefObject<Parallax.IParallax>
}) => {
	const { getColStart, getRowStart, getRowEnd } = useGrid();
	return (
		<>
			<Parallax.Accordion 
				sticky={{
					start,
					end,
					height: `${getRowEnd(4) - getRowStart(1)}px`,
					top: `${getRowStart(1)}px`
				}}  
				contentRatio={50}
				offsetRef={offsetRef}
				parallaxRef={parallaxRef}
				style={{
					width: "calc(100% - 50px - 50px)",
					left: `${getColStart(0)}px`,
					zIndex: 10,
					pointerEvents: "none",
				}}
				items={(selected) => TECHNOLOGY.items.map((item, i) => ({
					title: <ContentTitle 
						selected={selected === i} 
						i={i} 
						item={item}
					/>,
					content: <ContentBlock 
						text={item.content} 
						lineTitle={item.lineTitle} 
						lineContent={item.lineContent}
					/>
				}))}/>
			{/**@ts-ignore */}
			<ParallaxLayerSectionName start={start} end={end * 1.1}>
				{ TECHNOLOGY.sectionName }
			</ParallaxLayerSectionName>
		</>
	);
};

export default Accordion;