/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from "react";

import { useFrameLimiter } from "./AdvancedFiberParticles"; // Import the custom hook

import { CameraOperatorProps } from "./types";
import { getCurrentStageIndex } from "./utils";

const CameraOperator = <C,>({
	controls, 
	stages,
	offset,
	offsetTransform,
	children
} : CameraOperatorProps<C>) => {
	/**
     * The CameraOperator component is a custom hook that controls the camera position and focus based on the current stage.
     * And or the current scroll position.
     */
	const [stageIndex, setStageIndex] = React.useState<number>(0);
	const [position, setPosition] = React.useState<{x?: number, y?: number, z?: number}>(undefined);

	// Initial move when load
	useEffect(() => {
		// Setup 
		if (controls.current === null) return;
		// @ts-ignore
		controls.current.smoothTime = 0.5;
		// @ts-ignore
		controls.current.moveTo(0, 0, 0, true);
		// @ts-ignore
		//controls.current.dollyTo(5, true);
	}, [controls]);

	// Animation loop - check the offset and extract the current stage index
	useFrameLimiter(() => {
		const i = getCurrentStageIndex(stages, offset.current, offsetTransform);
		setStageIndex(i);
		// We are going to do a interpolated dollyTo instead of the current stage dollyTo
		// we get the target dolly of the stage and the next stage and interpolate between them 
		// based on the offset
		const stage = stages[i];
		const nextStage = stages[i + 1] || stage;
		const dollyFrom = stage?.focus.dollyTo || 5;
		const dollyTo = nextStage?.focus.dollyTo || 5;
		// @ts-ignore
		const currentDolly = controls.current.distance;
		if (currentDolly === dollyTo) return;

		const offsetFrom = stage.endScroll || 0;
		const offsetTo = nextStage.endScroll || 1;
		const offsetRange = offsetTo - offsetFrom;
		const offsetValue = offsetTransform(offset.current) - offsetFrom;
		const offsetPercentage = offsetValue / offsetRange;
		const dolly = dollyFrom + (dollyTo - dollyFrom) * offsetPercentage;
		// @ts-ignore
		controls.current.dollyTo(dolly, false);
	});

	// Update position variables based o the current stage index
	useEffect(() => {
		if (stageIndex === -1) return;
		const stage = stages[stageIndex];
		setPosition(stage?.position || {});
		// setDollyTo(stage?.focus.dollyTo || 5);
	}, [stageIndex, stages]);

	// Update the camera position and focus
	useEffect(() => {
		const { x = 0, y = 0, z = 0 } = position || {};
		// @ts-ignore
		controls.current.moveTo(x, y, z, true);
	}, [position]);

	return <>{children}</>;
};

export default CameraOperator;