import React from "react";
import styled from "styled-components";

const Bold = styled.span`
	font-weight: bold;
`;

const DOCUMENTATION = [
	{
		name: "Introduction",
		content: [
			{type: "title" as const, content: "Delta Sharing"},
			{type: "text" as const, content: <>
Delta Sharing is an open-source protocol that enables secure, scalable, and real-time data sharing across organizations. 
It allows for seamless data exchange between different platforms and tools, eliminating the need for complex 
integrations or data duplication. By using Delta Sharing, users can access real-time data directly from the source, 
ensuring consistency, security, and compliance in a collaborative environment. You can learn more about Delta Sharing
by visiting their <a href="https://delta.io/sharing/" target="_blank" rel="noreferrer">website</a>.
			</>},
			{type: "text" as const, content: <>
At Tema AI, we leverage the power of Delta Sharing to provide some users with direct access to our datasets. 
Our data can be accessed securely and efficiently through Delta Sharing. This approach ensures that 
our users can integrate and utilize our data within their preferred analytics and data science tools, 
without compromising on performance or security.
			</>},
		]
	},
	{
		name: "Shares",
		content: [
			{type: "title" as const, content: "What is a Share?"},
			{type: "text" as const, content: <>
			A <Bold>Share</Bold> is similar to a database. It&apos;s a logical grouping of data that contains tables and schemas, 
			which are made available to users or organizations. Authorized users can access and query these tables and schemas within the Share.
			</>},
			{type: "text" as const, content: <>
				When you connect to Tema AI&apos;s Delta Share server, you&apos;ll be granted access to one or more Shares, depending on your permissions. 
				These Shares allow you to securely access the data within them, which can be used for analysis, machine learning, 
				reporting, or any other data-driven task you need to perform. You can see the Shares you have access to in the <Bold>Shares</Bold> section,
				at the top of this page.
			</>},
			{type: "text" as const, content: <>
				If you don&apos;t see any Shares listed, please contact us at info@tema.ai as you may not have the necessary permissions to access the data.
			</>},
			{type: "text" as const, content: <>
				Shares are case insensitive, so you can access them using any case. Their default representation is in lowercase, but any case will work.
			</>}
		]
	},
	{
		name: "Connections",
		content: [
			{type: "title" as const, content: "What is a Connection?"},
			{type: "text" as const, content: <>
			A <Bold>Connection</Bold> is a secured channel between you and the Share. Each connection is tied to a set of 
			short-lived credentials, which are generated using your API Keys. 
			These credentials are essential for authenticating and accessing the data.
			</>},
			{type: "text" as const, content: <>
				One important aspect to note is that each time a Connection refreshes its credentials, all previously 
				issued credentials for that same Connection become invalid. This means that using the same Connection 
				across multiple applications simultaneously could lead to unauthorized access issues.
			</>},
			{type: "text" as const, content: <>
				To avoid such problems, it is recommended to use a separate Connection for each application. 
				This ensures that concurrent runs of the same Connection by different applications do not interfere with 
				each other, maintaining a secure and reliable data access environment.
			</>},
			{type: "text" as const, content: <>
				Connections are case insensitive, so you can access them using any case. Their default representation is in lowercase, but any case will work.
			</>}
		]
	},
	{
		name: "Installation",
		content: [
			{type: "title" as const, content: "Install tema-ai python package"},
			{type: "text" as const, content: <>
The tema-ai library is a lightweight solution that simplifies secure access to a Delta Share server by 
automating the collection of short-lived credentials. It helps developers easily authenticate and maintain 
secure connections, reducing the complexity and risks of manual credential management, all while being easy to 
install and use.	
			</>},
			{type: "text", content: <>
Currently, the tema-ai library is available exclusively for Python, reflecting our focus on supporting a wide 
range of Python-based data science and analytics workflows. However, we are committed to meeting the needs of our users. 
If you require support for other programming languages, please let us know, and we will consider expanding our 
library offerings to accommodate your needs.
			</>},
			{type: "text", content: <>
				The library is available for installation via {""}
				<a href="https://pypi.org/project/tema-ai/" target="_blank" rel="noreferrer">pip</a>. 
				To install the latest version of the library, run the following command:
			</>},
			{type: "code" as const, content: `pip install tema-ai`, language: "bash"},
		]
	},
	{
		name: "Setup",  
		content: [
			{type: "title" as const, content: "Create connection and keys"},
			{
				type: "text" as const, content: <>
			Before you can start accessing Tema AI data through Delta Sharing, you will need to set up a secure connection. 
			This process involves creating a connection and generating a pair of Client ID and Secret Keys. 
			These credentials are essential for authenticating your access to the Delta Share server.
				</>
			},
			{
				type: "text" as const, content: <>
				At the top of this page you can find the <Bold>Connections</Bold> section. Here you can create a new connection by clicking on 
				the <Bold>+ Add connection</Bold> button. Name your connection and click on <Bold>Create</Bold>. Once your connection is created, you can then 
				generate your Client ID and Secret Keys by clicking on the <Bold>Add new key</Bold> button under the connection section. Make sure to 
				copy and store these keys securely, as they will be required for authenticating your access to the Delta Share server and we will
				not be able to retrieve the client secret for you.
				</>
			},
			{type: "title" as const, content: "Set up environment variables"},
			{
				type: "text" as const, content: <>
				You can configure your environment variables to store your Client ID and Secret Keys securely. The library will automatically
				look for these environment variables when you run your code. This approach ensures that your credentials are not hardcoded
				in your code, reducing the risk of exposing sensitive information.
				</>
			},
			{
				type: "code" as const, language: "bash",  content: `export TEMA_AI_CONNECTION=<CONNECTION NAME>
export TEMA_AI_HOST=<HOST>
export TEMA_AI_CLIENT_ID=<CLIENT ID>
export TEMA_AI_CLIENT_SECRET=<CLIENT SECRET>`,
			},
			{
				type: "text" as const, content: <>
				Alternatively you can create an environment file <Bold>.env</Bold> and store your credentials there.
				</>
			},
			{
				type: "code" as const, language: "file:.env", content: `TEMA_AI_CONNECTION=<CONNECTION NAME>
TEMA_AI_HOST=<HOST>
TEMA_AI_CLIENT_ID=<CLIENT ID>
TEMA_AI_CLIENT_SECRET=<CLIENT SECRET>`,
			},
			{type: "title" as const, content: "Use keys in your code"},
			{
				type: "text" as const, content: <>
				Alternatively you can pass your keys directly to the library when loading it. We don;t recommend this
				approach unless you are retrieving your keys from a secure location on runtime. 
				</>
			},
			{type: "code" as const, language: "python", content: `from tema_ai.connect import TemaAIShareAPI

connection = TemaAIShareAPI(
	connection_name=<CONNECTION NAME>,
	host=<HOST>,
	client_id=<CLIENT ID>,
	client_secret=<CLIENT SECRET>
)`
			},
			{
				type: "text" as const, content: <>
				For all the following examples we will assume that you have set up your environment variables correctly through the
				.env file or directly as environment variables.
				</>
			},
		]
	},
	{
		name: "List Shares",
		content: [
			{type: "text" as const, content: <>You can list all the shares you have access to.</>},
			{type: "code" as const, language: "python", content: `from tema_ai.connect import TemaAIShareAPI

connection = TemaAIShareAPI()
connection.shares`},
			{type: "code" as const, language: "python", content: `>>> [Share(name='share1'), Share(name='share2')]`},
			{type: "text" as const, content: <>All your connections have access to the same Shares.</>},
		]
	},
	{
		name: "List Schemas",
		content: [
			{type: "text" as const, content: <>Each Share has one or more Schemas. You can list the schemas in a Share with</>},
			{type: "code" as const, language: "python", content: `from tema_ai.connect import TemaAIShareAPI, Share

connection = TemaAIShareAPI()
# Specify the share you want to list the schemas for
share = Share(name="share1")
connection.schemas(share)
`},
			{
				type: "code",
				language: "python",
				content: `>>> [
	Schema(name='schema1', share='share1'), 
	Schema(name='schema1', share='share1')
]`
			}
		]
	},
	{
		name: "List Tables",
		content: [
			{type: "text" as const, content: <>Each Schema has one or more Tables. You can list the Tables in a Schema with</>},
			{type: "code" as const, language: "python", content: `from tema_ai.connect import TemaAIShareAPI, Share, Schema

connection = TemaAIShareAPI()
# Specify the share and the schema you want to list the tables for
schema = Schema("schema1", share="share1")
connection.tables(schema)
`},	
			{
				type: "code",
				language: "python",
				content: `>>> [
	Table(name='table1', schema='schema1', share='share1'), 
	Table(name='table2', schema='schema1', share='share1')
]`
			},
		]
	},
	{
		name: "Query a Table",
		content: [
			{type: "text" as const, content: <>
		Once you have identified the table you want to query within a Share and Schema, 
		you can easily retrieve all the data directly into a Pandas DataFrame. 
		This allows you to work with the data in a familiar and efficient manner, 
		enabling seamless integration with your existing data analysis workflows.
			</>},
			{type: "code" as const, language: "python", content: `from tema_ai.connect import TemaAIShareAPI, Table

table = Table(name="table1", schema="schema1", share="share1")
df = connection.table_to_pandas(table)`},
			{type: "text" as const, content: <>
			We recommend using partitions when querying the data to improve performance. 
			Check the <Bold>Table partitions</Bold> section for more information.
			</>},

		]
	},
	{
		name: "Table Partitions",
		content: [
			{type: "text" as const, content: <>
		Table partitions are a way to divide a large dataset into smaller,
		more manageable segments based on specific criteria, such as date ranges, 
		regions, or other key attributes. By organizing data into partitions, 
		we can significantly improve query performance and reduce the 
		amount of data that you need to transfer by focusing on delivering only the data you need.</>},
			{type: "text" as const, content: <>
			When querying a table, you can specify which partitions you want to retrieve. 
			This allows you to access only the relevant subset of data, rather 
			than the entire table, which can be particularly useful for large datasets. 
			By narrowing down your queries to the specific partitions you need, 
			you can save time, reduce computational load, and improve overall 
			efficiency in your data processing tasks.
			</>},
			{type: "text" as const, content: `Get the partitions for a table with`},
			{type: "code" as const, language: "python", content: `from tema_ai.connect import TemaAIShareAPI, Table
			
table = Table(name="table1", schema="schema1", share="share1")
connection.available_partitions(table)
`},
			{type: "code" as const, language: "python", content: `>>> {'partition1': ['value1', 'value2'], 'partition2': ['value3', 'value4']}`},
			{type: "text" as const, content: <>
			Once you have identified the partitions you want to query, you can specify them in your query to retrieve only the relevant data.
			</>},
			{type: "code" as const, language: "python", content: `from tema_ai.connect import TemaAIShareAPI, Table 

table = Table(name="table1", schema="schema1", share="share1")
partition_values = {'partition1': ['value1', 'value2']}
df = connection.table_to_pandas(table, partition_values=partition_values)`},
		]
	},
	{
		name: "Table Schema",
		content: [
			{type: "text" as const, content: <>
			You can retrieve the schema of a table, which includes the column names, their data types, and descriptions of each column.
			</>
			},
			{type: "code" as const, language: "python", content: `from tema_ai.connect import TemaAIShareAPI, Table 

connection = TemaAIShareAPI()
table = Table(name="weather_resource_metrics", schema="environmental", share="foundation_sample")
connection.table_schema(table)`},
			{type: "code" as const, language: "python", content: `>>> [{
	'name': 'column1', 
	'type': 'type1',
	'nullable': False,
	'metadata': {'comment': 'Column1 description'}
},
{
	'name': 'column1',
	'type': 'type1',
	'nullable': False,
	'metadata': {'comment': 'Column1 description'}
}]`}
		]
	}
];

export default DOCUMENTATION;


