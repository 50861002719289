import React from "react";
import styled from "styled-components";
import { useGrid }  from "Components/Reusable/Grid";
import { EyeBrowseCSS } from "Styles";

const Section = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    div {
        ${ EyeBrowseCSS }
        text-transform: uppercase;
        color: ${({ theme }) => theme.textColorLight};
        line-height: 30px;
    }
    
`;


const MobileSection = ({ text } : { text: string }) => {
	const { getRowStart, gridWidth, marginX, cellHeight } = useGrid();
	return <Section style={{ 
		top: `${getRowStart(0)}px`,
		width: `${gridWidth}px`,
		left: `${marginX}px`,
		height: `${cellHeight}px`
	}}>
		<div>
			{text}
		</div>
	</Section>;
};

export default MobileSection;