import React, { useState, useLayoutEffect } from "react";
import RotatePrompt, { Device } from "./RotatePrompt";

type FlipYourPhoneProps = {
    children: React.ReactNode;
};

const useCanBeRotated = () => {
	const [supportsOrientationChange, setSupportsOrientationChange] = useState(false);

	useLayoutEffect(() => {
		// Check if the window has orientationchange event listener
		setSupportsOrientationChange("onorientationchange" in window);
	}, []);
	
	return {
		canBeRotated: supportsOrientationChange
	};
	
};

/**
 * FlipYourPhone component
 *
 * This component renders its children unless the device is in landscape mode
 * and has an aspect ratio greater than a specified threshold, indicating that the user 
 * should rotate their phone for a better experience.
 *
 * @param {React.ReactNode} children - The content to display when the phone is in the correct orientation.
 */
const FlipYourPhone = ({ children }: FlipYourPhoneProps) => {
	const [childrenToRender, setChildrenToRender] = useState<React.ReactNode>(children);
	const [askToRotate, setAskToRotate] = useState<boolean>(false);
	const [angle, setAngle] = useState<number>(0);
	const [aspectRatio, setAspectRatio] = useState<number>(undefined);
	const [deviceType, setDeviceType] = useState<Device>(undefined);
	const { canBeRotated } = useCanBeRotated();
	
	useLayoutEffect(() => {
		setChildrenToRender(children);
	}, [children]);
	
	useLayoutEffect(() => {
		const handleOrientationChange = () => {
			const { width, height } = window.screen;
			const aspectRatio = height / width;
			// Default to 0 if the angle is not available
			setAngle(window.screen?.orientation?.angle || 0);
			setAspectRatio(aspectRatio);
		};

		handleOrientationChange(); // Initial check on component mount
		window.screen.orientation.addEventListener("change", handleOrientationChange);

		// Cleanup the event listener on component unmount
		return () => {
			window.screen.orientation.removeEventListener("change", handleOrientationChange);
		};
	}, []);
	
	useLayoutEffect(() => {
		const isPhone = screen.width < 760;
		setDeviceType(isPhone ? "phone" : "tablet");
	}, []);
	const isPhone = () => deviceType === "phone";
	const isTable = () => deviceType === "tablet";

	useLayoutEffect(() => {
		const isPanoPhone = (angle === 90 || angle === 270) && aspectRatio > 1.5;
		const isPortraitTable = (angle === 90 || angle === 270) && aspectRatio > 1;
		if ((isPhone() && isPanoPhone) || (isTable() && isPortraitTable)) {
			setAskToRotate(true);
		} else {
			setAskToRotate(false);
		}
	}, [angle, aspectRatio, deviceType]);

	if (canBeRotated && askToRotate) return <RotatePrompt angle={angle} device={deviceType}/>;
	return <>{childrenToRender}</>;
};

export default FlipYourPhone;