import React from "react";
/**
 * HOME PAGE COPY
 */
const HOME = {
	title: (
		<>
        In a world where climate <br/> disruption is inevitable,
			<p style={{margin: 0}}>
				<span className="bolded gradient">
					{" "}Tema AI{" "}
				</span>
                makes it investable
			</p>
		</>
	),
	subtitle: <>The weather app for macro investors, Tema AI translates global environmental disruption into trading signals before it reaches the market or media.</>,
};

/** 
 * MISSION PAGE COPY
 */
const MISSION = {
	sectionName: "Why Tema AI",
	title: <>
		The weather is the most mis-priced global macro factor today.{" "}
		<span className="faded">
		Tema AI prices it, universally and in realtime.
		</span> 
	</>,
	subtitle: <>
		<p>
			<span className="highlight">This isn&apos;t climate data.</span>{" "}
			Tema AI doesn&apos;t forecast the weather. Nor can it tell 
			you how to prevent climate change or where to avoid physical 
			and transition risks in future scenarios. This is climate alpha.
		</p>
	</>,
	card1: <>
		<p className="title">Global macro factor</p>
		<p className="subtitle">True asset pricing</p>
		<p>
			Uncover the global climate factor exposure of every country and industry
		</p>
	</>,
	card2: <>
		<p className="title">Nowcasting indicator</p>
		<p className="subtitle">Early trading signals</p>
		<p>
			Capitalise on a growing yet hidden source of macro and market volatility
		</p>
	</>,
};

/** 
 * TECHNOLOGY PAGE COPY
 */
const TECHNOLOGY = {
	sectionName: "How it works",
	items : [
		{
			title: "Global trade", 
			content: "Tema AI is built around a proprietary model of the world economy, through which it tracks the geographic distribution and global trade of hundreds of traded goods.",
			lineTitle: "",
			lineContent: ""
		},
		{
			title: "Local production", 
			content: "Industry and manufacturing and agricultural output is monitored in 3+ million regions worldwide, so the economic impact of any local disruption can be quantified instantly and accurately.",
			lineTitle: "",
			lineContent: ""
		},
		{
			title: "Live disruptions ", 
			content: "Trained on vast geospatial and meteorological data in all 3+ million regions, Tema AI captures impending weather-related production shocks before they register in national statistics.",
			lineTitle: "",
			lineContent: ""
		},
		{
			selectedTitle: "Multi-asset risk", 
			title: "Multi-asset risk", 
			content: "Production risks are aggregated and mapped onto global value chains, giving users visibility of the global climate macro risk facing every industry and country.",
			lineTitle: "",
			lineContent: ""
		},
	]
};

/** 
 * FEATURES PAGE COPY
 */
const FEATURES = {
	sectionName: "What you get",
	title: (<>
		Elevate your commodity and build new climate trading strategies{" "}
		<span className="faded">with our flagship data suite, coming in 2024.</span>
	</>),
	subtitle: "Our commodity-focused flagship product is accessible via datafeed and API",
	cards: [
		{
			title: "Daily commodity indicators", 
			tags: [""],
			content: "Get ahead of a growing source of global market volatility. Expressed as a percentage, indicators reflect the current volume of global production and physical trade at risk due to natural disasters and anomalous weather worldwide."
		},
		{
			title: "189 country snapshots", 
			tags: [],
			content: "Demystify a hidden macroeconomic driver, with insight into the global climate-related risk to which every country is exposed via its commodity imports and exports. Includes access to domestic economic and environmental data."
		},
		{
			title: "Live trading signals", 
			tags: [],
			content: "Trade the weather across asset classes and investment strategies. Global climate-induced commodity shocks trigger trading signals for every regional industry exposed — positively and negatively, directly and indirectly — to the event."
		},
		{
			title: "5+ years of historic data", 
			tags: [],
			content: "Backtest 5+ years of data to discover proprietary trends and develop repeatable quantitative strategies. Data includes daily commodity indicators, in addition to underlying national/regional and environmental sources of risk."
		}
	]
};

/**
 * CONTACT FORM
 */
const CONTACT_FORM = {
	options: [
		// { value: "demo", label: "Schedule demo" },
		{ value: "press", label: "Press enquiry"},
		{ value: "general", label: "General enquiry"},
	]
};

const AUDIENCE = {
	title: (<>
		Systematic investors and commodity traders{" "}
		<span className="faded">are invited to register for pre-release access and beta testing.</span>
	</>),
	mobileTitle: "Systematic investors and commodity traders are invited to register for pre-release access and beta testing.",
	subtitle: "Let's talk."
};

export {
	HOME,
	MISSION,
	TECHNOLOGY,
	FEATURES,
	CONTACT_FORM,
	AUDIENCE
};