import React, { useRef, useCallback, useMemo } from "react";
import { Navigation } from "tema-ai-components";
import { useGrid } from "Components/Reusable/Grid";
import { animated } from "react-spring";
import useRetractileNavBar from "./useRetractileNavBar";
import { useTheme } from "Context/Theme/ThemeContext";
import { EyeBrowseCSS } from "Styles";
import styled from "styled-components";

const NavBar = styled(Navigation.NavBar)`
	button,
	.section,
	.navbar__item,
	.tema__logo {
		${EyeBrowseCSS}
	}
`;

const PhoneNavBar = styled(Navigation.PhoneNavBar)`
	div {
		pointer-events: auto;
	}
	pointer-events: none;
    .menu {
        background-color: ${({ theme }) => theme.background};
    }
    .menu__item {
        height: 90px;
    }
	.icon {
		background-color: rgba(255, 255, 255, 0.08);
	}
	.menu__container {
		background-color: ${({ theme }) => theme.background};
		backdrop-filter: blur(40px);
		-webkit-backdrop-filter: blur(40px);
        margin-bottom: 180px;
		margin-top: 80px;
    }
	.line {
		background-color: ${({ theme }) => theme.textColorLight};
	}
`;

type NavBarItemType = {
	link: string,
	name: string,
};

type NavBarRef = {
    open: boolean,
    setOpen: (open: boolean) => void,
}

const useNavbar = ({
	hideNavBarAfter=850,
	navBarHeight=80,
	showAfter=10,
	hideAfterScroll=150,
	items=[],
	selectedItem,
	sectionName,
	homeLink="/",
	ctaLink,
}: {
	hideNavBarAfter?: number,
	navBarHeight?: number,
	showAfter?: number,
	hideAfterScroll?: number,
	items: NavBarItemType[],
	selectedItem?: string,
	sectionName?: string,
	homeLink?: string;
	ctaLink?: string;
}) => {
	const { theme } = useTheme();
	const { gap, cellWidth, width } = useGrid();

	const isPhone = width < 800;
	const phoneNavBarRef = useRef<NavBarRef>(null);
	const {
		styles: navBarStyles,
		show,
		displayNavBar,
		hideNavBar,
		freezeNavBar,
		unfreezeNavBar,
	} = useRetractileNavBar({
		showAfter,
		hideAfter: hideNavBarAfter,
		navBarHeight,
		hideAfterScroll
	});
	const props = {
		height: navBarHeight,
		cellWidth,
		items,
		selectedItem,
		color: theme.textColorLight,
		highlightColor: theme.accentColor,
		mainButtonColor: theme.textColor,
		homeLink,
		logInText: "Log In",
		logInLink: "/account",
		ctaText: "Contact",
		ctaLink,
	};
	const nav = useMemo(() => (
		<animated.div style={{ 
			...navBarStyles, 
			position: "relative", 
			height: `${navBarHeight}px`,
			margin: 0, 
			padding: 0, 
			pointerEvents: "auto",
			backgroundColor: "transparent"
		}}>
			<NavBar	
				{...props}
				height={navBarHeight}  
				// @ts-ignore
				buttonHeight={navBarHeight / 2}
				gap={gap}
				itemWidth={cellWidth}
				// @ts-ignore
				sectionName={sectionName}
				secondaryButtonColor={theme.contrastColor}
			/>
		</animated.div>
	), [navBarStyles, navBarHeight, gap, cellWidth, theme, props, sectionName]);

	const phoneNav = useMemo(() => (
		<animated.div style={{ 
			...navBarStyles, 
			position: "relative", 
			height: `${navBarHeight}px`,
			margin: 0, 
			padding: 0, 
			pointerEvents: "auto",
			backgroundColor: "transparent"
		}}>
			<PhoneNavBar
				ref={phoneNavBarRef}
				{...props}
				buttonHeight={48}
				gap={10}
			/>
		</animated.div>
	), [navBarStyles, phoneNavBarRef, navBarHeight, props]);

	const finalNav = useMemo(() => isPhone ? phoneNav : nav, [isPhone, phoneNav, nav]);

	const closeNavBar = useCallback(() => {
		if (!isPhone) return;
		phoneNavBarRef.current.open && phoneNavBarRef.current.setOpen(false);
	}, [isPhone, phoneNavBarRef]);

	const openNavBar = useCallback(() => {
		if (!isPhone) return;
		phoneNavBarRef.current.open || phoneNavBarRef.current.setOpen(true);
	}, [isPhone, phoneNavBarRef]);

	return {
		show,
		nav: finalNav,
		displayNavBar,
		hideNavBar,
		freezeNavBar,
		unfreezeNavBar,
		closeNavBar,
		openNavBar
	};
};

export default useNavbar;
export type {
	NavBarItemType
};