import React, { createContext, useContext, useRef, ReactNode, MutableRefObject } from "react";
import { useFrame, RootState } from "@react-three/fiber";

interface FrameLimiterContextProps<C> {
    interval: number;
    controls?: MutableRefObject<C>;
}

const FrameLimiterContext = createContext<FrameLimiterContextProps<any> | null>(null);

interface FrameLimiterProviderProps<C> {
    children: ReactNode;
    limit?: number;
    controls?: MutableRefObject<C>;
}

export const FrameLimiterProvider = <C,>({ 
	children, 
	limit = 20,
	controls 
}: FrameLimiterProviderProps<C>): JSX.Element => {
	const interval = 1 / limit;

	return (
		<FrameLimiterContext.Provider value={{ interval, controls }}>
			{children}
		</FrameLimiterContext.Provider>
	);
};

type FrameLimiterCallback<C> = ({state, fps, controls}: {state: RootState, fps?: number, controls?: C}) => void;

export const useFrameLimiter = <C,>(callback: FrameLimiterCallback<C>) => {
	const context = useContext(FrameLimiterContext) as FrameLimiterContextProps<C> | null;
	const prevTime = useRef(0);

	if (!context) {
		throw new Error("useFrameLimiter must be used within a FrameLimiterProvider");
	}

	const { interval, controls } = context;
    
	useFrame((state) => {
		const { clock: { elapsedTime } } = state;
		const delta = elapsedTime - prevTime.current;
		if (delta >= interval) {
			callback({state, fps: 1 / delta, controls: controls.current});
			prevTime.current = elapsedTime;
		}
	});
};