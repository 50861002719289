import React from "react";
import { useGrid } from "Components/Reusable/Grid";
import styled from "styled-components";
import { SubTitleCSS } from "Styles";

const Container = styled.div<{$gap: number}>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${props => props.$gap}px;
    justify-content: flex-start;
`;
const Text = styled.div`
    ${ SubTitleCSS }
`;

const LineBlock = ({ text } : { text: string | React.ReactNode }) => {
	const { gap } = useGrid();
	return (
		<Container $gap={gap}>
			<Text>{text}</Text>
		</Container>
	);
};

export default LineBlock;