import React, { createContext, Suspense, useState, useMemo, useCallback, useContext, useRef } from "react";
import { WorldCanvasProps } from "Components/Reusable/TemaParticles";
import * as portals from "react-reverse-portal";
import TemaParticles from "Components/Reusable/TemaParticles";

interface ContextProps {
    //Particles: React.ReactNode;
    setProps: (props: WorldCanvasProps) => void;
	scrollTo: (index: number) => void;
	particlesPortal: portals.HtmlPortalNode | null;
  }

const defaultContextData: ContextProps = {
	//Particles: null,
	setProps: () => {
		//noop
	},
	scrollTo: () => {
		//noop
	},
	particlesPortal: null
};

interface ParticlesContextOffsetProps extends Omit<WorldCanvasProps, "offset"> {
	offset: React.MutableRefObject<number>;
}

// Create the context
const ParticlesContext = createContext(defaultContextData);
const useParticles = () => useContext(ParticlesContext);
const TemaParticlesProvider = ({ children } : { children: React.ReactNode }) => {
	// Create a portal node: this holds your rendered content
	const particlesPortal = React.useMemo(() => portals.createHtmlPortalNode({
		attributes: {
			id: "particles", 
			style: "position: absolute; left: 50%; width: 100%; height: 100%; z-index: -100; transform: translateX(-50%);"}
	}), []);
	// reference offset as default, it can be override by props
	const offset = useRef<number>(0);
	const  [particlesProps, setParticlesProps] = useState<ParticlesContextOffsetProps>({offset: {current: 0}});
	
	const Particles = useMemo(() => {
		return (
			<portals.InPortal node={particlesPortal}>
				<TemaParticles {...particlesProps} />
			</portals.InPortal>
		);
	}, [particlesPortal, particlesProps]);

	const callbackSetProps = useCallback((propsI: WorldCanvasProps) => {
		setParticlesProps(propsI);
	}, [setParticlesProps]);
	
	const scrollTo = useCallback((index: number) => {
		if ("offset" in particlesProps) {
			particlesProps.offset.current = index;
			return;
		}
		offset.current = Math.min(0.9, index);
	// eslint-disable-next-line react/prop-types
	}, [offset, particlesProps]);

	return (
		<ParticlesContext.Provider value={{setProps: callbackSetProps, scrollTo, particlesPortal}}>
			<Suspense>
				{ Particles }
			</Suspense>
			{children}
		</ParticlesContext.Provider>
	);
};

export {
	TemaParticlesProvider,
	useParticles
};