import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useGrid, device } from "Components/Reusable/Grid";
import { EyeBrowseCSS } from "Styles";


const CopyRight = styled.div<{ $right: boolean }>`
    grid-column: ${ ({ $right }) => $right ? "10 / span 3" : "7 / span 3"};
	grid-row: 6 / span 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    
	div {
		height: 40px;
		display: flex;
		align-items: center;
	}
	a, p {
		margin : 0;
		padding: 0;
        text-transform: uppercase;
        color: ${({ theme }) => theme.textColorLight};
        text-align: right;
        text-decoration: none;
        z-index: 10;
        white-space: nowrap;
        ${ EyeBrowseCSS }
    }
	
	@media ${device.tablet} {
		grid-row: 12 / span 1;
	}
	@media ${device.tabletS} {
		grid-column: 5 / span 2;
	}

`;
const PrivacyPolicy = styled(CopyRight)`
	grid-column: ${ ({ $right }) => $right ? "7 / span 3" : "4 / span 2"};
	pointer-events: none;
    z-index: 10;
    
	a {
		pointer-events: auto;
		&:hover {
			color: ${({ theme }) => theme.accentColor};
		}
	}
    @media ${device.laptopL} {
		grid-column: ${ ({ $right }) => $right ? "10 / span 3" : "7 / span 3"};
        transform: translateY(-20px);
	}
	@media ${device.tabletS} {
		grid-column: 5 / span 2;
	}
	
`;

const BasicFooter = ({ right = false } : { right?: boolean }) => {
	const year = useMemo(() => (new Date()).getFullYear(), []);
	const { width } = useGrid();
	const condense = width < 1050;
	return (
		<>
			<CopyRight $right={right}>
				<div>
					<p>© {year} Tema AI LTD {!condense && "ALL RIGHTS RESERVED"}</p>
				</div>
			</CopyRight>
			<PrivacyPolicy $right={right}>
				<div>
					<Link to="/privacy" target="_blank">
						Privacy policy
					</Link>
				</div>
			</PrivacyPolicy>
		</>
	);
};

export default BasicFooter;