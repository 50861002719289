import React, {useEffect} from "react";
import {
	BrowserRouter,
	Route,
	Routes,
	useLocation
} from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { RequireAuth } from "Components/Reusable/Cognito";


// They are not in TypesCript yet
import homeRoutes, { RouteType } from "./homeRoutes";
// @ts-ignore
import { googleAnalytics } from "Components/Reusable/Utils";


const usePageTracking = () => {
	const location = useLocation();
	useEffect(() => {
		const page = location.pathname + location.search;
		googleAnalytics.set({ page }); // Update the user's current page
		googleAnalytics.pageview(page); // Record a pageview for the given page
	}, [location]);
};

type LocatorType = {
	children: React.Component | JSX.Element,
	privateRoute?: boolean
}

const Locator = ({ children, privateRoute }: LocatorType): JSX.Element => {
	usePageTracking();
	if (!privateRoute) return <>{ children }</>;
	//@ts-ignore
	return <RequireAuth><>{ children }</></RequireAuth>;
};

export default function AppRouter() {
	const routes = homeRoutes.map((route: RouteType) => {
		return <Route
			key={route.name}
			path={route.path}
			element={
				<Locator privateRoute={route.private}>{route.component}</Locator>
			}
		/>;
	});
	return (
		<BrowserRouter>
			<Authenticator.Provider>	
				<Routes>{routes}</Routes>
			</Authenticator.Provider>
		</BrowserRouter>
	);
}
