/* eslint-disable react/react-in-jsx-scope */
import React, { RefObject, useRef, useMemo, useEffect, useState } from "react";
import { CameraControls } from "@react-three/drei";
import { EffectComposer, DepthOfField } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";

import * as THREE from "three";
import { useTheme } from "styled-components";
import { useStory } from "Context";
import { Config } from "Constants";
import { useTheme as useContextTheme } from "Context/Theme/ThemeContext";
import { StyledCanvas }	from "./styles";
import { FBOParticles, BuildStory, FrameLimiterProvider } from "./AdvancedFiberParticles";
import { useGrid } from "Components/Reusable/Grid";

import { RandomAnnotations } from "./Annotation";
import ChartHtml from "./Chart";

import { WorldCanvasProps } from "./types";
import Sphere from "./Sphere";
import CameraOperator from "./CameraOperator";
import { useFrameLimiter } from "./AdvancedFiberParticles"; // Import the custom hook

const WorldCanvas = ({ 
	offset, 
	offsetTransform = (o: number) => o
}: WorldCanvasProps) => {
	const size = 128;
	const interval = 2;
	const theme = useTheme();
	const { isPhone } = useGrid();
	const { config: { maxScreenHeight } } = Config;
	const { themeName } = useContextTheme();
	const controls = useRef<RefObject<CameraControls>>(null);
	const { world, story: localStory, commodityMask, countryMask, countryLocation } = useStory();
	// Convert background color to hex string
	const backgroundColor = new THREE.Color(theme.worldParticles.backgroundColor).getHex();
	const hexBackgroundColor = `#${backgroundColor.toString(16).padStart(6, "0")}`;

	const groupRef = useRef<THREE.Mesh>(null);

	const story = useMemo(() => {
		return {
			labels: [], //LABELS, 
			stages:  BuildStory({
				size, 
				baseColor: theme.worldParticles.baseColor, 
				baseColorFaded: theme.worldParticles.baseColorFaded, 
				economicColor: theme.worldParticles.economicColor, 
				disruptedColor: theme.worldParticles.disruptedColor,
				worldMask: world,
				commodityMask,
				countryMask,
				location: localStory?.coordinates || [0, 0],
				countryLocation,
				isPhone
			})
		};
	}, [themeName, window.devicePixelRatio, size, localStory, commodityMask, countryLocation]);

	useEffect(() => {
		if (controls.current) {
			// @ts-ignore
			controls.current.truck(0, -3, true);
		}
	}, [controls]);

	return (
		<StyledCanvas 
			backgroundcolor={hexBackgroundColor} 
			gl={{ antialias: true }} 
			dpr={window.devicePixelRatio}
			key={themeName}
			camera={{ position: [0, 0, 2.45], fov: 75, near: 0.1, far: 1000 }}
			$maxScreenHeight={maxScreenHeight}
		>
			<FrameLimiterProvider<CameraControls> limit={30} controls={controls as unknown as RefObject<CameraControls>}>
				<CameraOperator<CameraControls> 
					controls={controls as unknown as RefObject<CameraControls>}
					stages={story.stages}
					offset={offset}
					offsetTransform={offsetTransform}
				>
					{ /* @ts-ignore */ }
					<Sphere offset={offset} offsetTransform={offsetTransform} stages={story.stages} rotation={[0,0,0]}/>
					<ChartHtml 
						stages={story.stages} 
						offset={offset} 
						offsetTransform={offsetTransform} 
						interval={interval}
						theme={theme}
					/>
					{ /* @ts-ignore */ }
					<group ref={groupRef}>
						<FBOParticles
							story={story}
							size={size}
							baseColor={theme.worldParticles.baseColor}
							backgroundColor={theme.worldParticles.backgroundColor}
							offset={offset}
							offsetTransform={offsetTransform}
							highlightFactor={10}
							baseHighlightFactor={2}
							particleSize={5}
							interval={interval}
							groupRef={groupRef}
						/>
						<RandomAnnotations story={story} offset={offset} offsetTransform={offsetTransform} length={18}/>
					</group>
					<CameraControls
						ref={controls as unknown as RefObject<CameraControls>}
						smoothTime={0.3}
						makeDefault={true}
						// Prevents user interaction
						interactiveArea={{x: 0, y: 0, width: 0, height: 0}}
					/>
				</CameraOperator>
			</FrameLimiterProvider>
		</StyledCanvas>
	);
};

function clamp(value: number, min: number, max: number) {
	return Math.max(min, Math.min(max, value));
}

const oscillator = (x: number, X: number, start: number, end: number) => {
	const normalizedX = (x - start) / (end - start);
	const v = X * (1 - Math.cos(2 * Math.PI * normalizedX));
	return (x < start || x > end) ? 0 : clamp(v, 0, X);
};
const Bokeh = ({ offset, offsetTransform } : { offset: RefObject<number>, offsetTransform: (o: number) => number }) => {
	//const [focusDistance, setFocusDistance] = useState(0);
	//const [focalLength, setFocalLength] = useState(0);
	const [bokehScale, setBokehScale] = useState(0);
	//const [height, setHeight] = useState(480);

	useFrameLimiter(() => {
		const pos = offsetTransform(offset.current);
		// linear function from 0 to 1 when pos goes from 0.2 to 0.45
		const start = 0.05;
		const end = 0.46;
		const bokehScale = oscillator(pos, 5, start, end);
		setBokehScale(bokehScale);
	});

	return (
		<EffectComposer>
			<DepthOfField 
				focusDistance={1} 
				focalLength={1} 
				bokehScale={bokehScale} 
				blendFunction={BlendFunction.ADD} // Change this to try different blending modes
			/>
		</EffectComposer>
	);
};


export default WorldCanvas;
export type { WorldCanvasProps };